import React from 'react';

import {
  FormGroup,
  Label,
  TextInput,
  Flex,
  Checkbox,
  Button
} from '@ubisend/pulse-components';

import useComposer from '../../../hooks/useComposer';

const FuzzyInput = () => {
  const composer = useComposer();

  const handleNewRow = () => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: {
        items: composer.content.items.concat({ label: '', value: '' })
      }
    });
  };

  const handleEditLabel = index => event => {
    const label = event.target.value;

    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: {
        items: composer.content.items.map((option, key) => {
          if (key === index) {
            return { value: option.value, label };
          }
          return option;
        })
      }
    });
  };

  const handleEditValue = index => event => {
    const value = event.target.value;

    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: {
        items: composer.content.items.map((option, key) => {
          if (key === index) {
            return { label: option.label, value };
          }
          return option;
        })
      }
    });
  };

  const handleDeleteRow = index => () => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: {
        items: composer.content.items.filter((_, key) => key !== index)
      }
    });
  };

  const handlePlaceholderChange = event => {
    const placeholder = event.target.value;

    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { placeholder }
    });
  };

  const handleDisabledChange = () => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { disabled: !composer.content.disabled }
    });
  };

  const handleMultiChange = () => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { multi: !composer.content.multi }
    });
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor="placeholder">Placeholder</Label>
        <TextInput
          id="placeholder"
          placeholder="Send a message"
          value={composer.content.placeholder}
          onChange={handlePlaceholderChange}
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="options">Options</Label>
        {composer.content.items.map(({ label, value }, index) => (
          <Flex key={index} mb xSpace>
            <Flex col fat>
              <TextInput
                value={label}
                onChange={handleEditLabel(index)}
                placeholder="Dropdown label"
                aria-label={`fuzzy-label-${index}`}
              />
            </Flex>
            <Flex col fat>
              <TextInput
                value={value}
                onChange={handleEditValue(index)}
                placeholder="Message to send"
                aria-label={`fuzzy-value-${index}`}
              />
            </Flex>
            <Flex bottom>
              <Button
                variant="secondary"
                colour="danger"
                icon="trash"
                onClick={handleDeleteRow(index)}>
                Delete
              </Button>
            </Flex>
          </Flex>
        ))}
        <Button variant="secondary" onClick={handleNewRow}>
          + Add option
        </Button>
      </FormGroup>
      <Flex center mb>
        <Checkbox
          checked={composer.content.disabled}
          onChange={handleDisabledChange}
          label="Deactivate composer"
        />
      </Flex>
      <Flex center>
        <Checkbox
          checked={composer.content.multi}
          onChange={handleMultiChange}
          label="Allow selection of multiple options"
        />
      </Flex>
    </>
  );
};

export default FuzzyInput;
