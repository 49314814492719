import dayjs from 'dayjs';

const convertToLocalTime = time => {
  if (!time) {
    return null;
  }

  return dayjs(`1996-01-01T${time}.000Z`)
    .subtract(new Date().getTimezoneOffset(), 'minutes')
    .format('HH:mm:ss');
};

const convertToUtcTime = time => {
  if (!time) {
    return null;
  }

  return dayjs(`1996-01-01T${time}.000Z`)
    .add(new Date().getTimezoneOffset(), 'minutes')
    .format('HH:mm:ss');
};

const reduceToLocalTime = (days, [day, { open, close }]) => {
  days[day] = {
    open: convertToLocalTime(open),
    close: convertToLocalTime(close)
  };
  return days;
};

const reduceToUtcTime = (days, [day, { open, close }]) => {
  days[day] = {
    open: convertToUtcTime(open),
    close: convertToUtcTime(close)
  };
  return days;
};

export {
  convertToLocalTime,
  convertToUtcTime,
  reduceToLocalTime,
  reduceToUtcTime
};
