import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from 'react';
import PropTypes from 'prop-types';

import {
  Label,
  Flex,
  TextInput,
  TextArea,
  FormGroup,
  Button
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';

import Permissions from './Permissions';

const RoleDetails = ({
  autoSave,
  initalName = '',
  initalDescription = '',
  initalPermissions = [],
  handleSubmit
}) => {
  const hasRendered = useRef(false);
  const [name, setName] = useState(initalName);
  const [description, setDescription] = useState(initalDescription);
  const [permissions, setPermissions] = useState(initalPermissions);

  const handleNameChange = event => setName(event.target.value);

  const handleDescriptionChange = event => setDescription(event.target.value);

  const handlePermissionsChange = permissions => setPermissions(permissions);

  const onSubmit = useCallback(() => {
    handleSubmit({
      name,
      description,
      permissions: permissions.map(permission => permission.id)
    });
  }, [name, description, permissions, handleSubmit]);

  const handleFormSubmit = event => {
    event.preventDefault();
    if (autoSave) {
      return;
    }
    handleSubmit({
      name,
      description,
      permissions: permissions.map(permission => permission.id)
    });
  };

  const valid = useMemo(
    () => Boolean(name && description && permissions.length > 0),
    [name, description, permissions]
  );

  useEffect(() => {
    if (!autoSave) {
      return;
    }
    if (!hasRendered.current) {
      hasRendered.current = true;
      return;
    }
    if (!valid) {
      return;
    }
    const timeout = setTimeout(onSubmit, 1500);

    return () => clearTimeout(timeout);
  }, [onSubmit, valid, autoSave]);

  return (
    <form onSubmit={handleFormSubmit}>
      <Flex col ySpace>
        <FormGroup>
          <Label htmlFor="name">Name</Label>
          <TextInput
            id="name"
            value={name}
            onChange={handleNameChange}
            placeholder="Super Admin"
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="description">Description</Label>
          <TextArea
            id="description"
            value={description}
            onChange={handleDescriptionChange}
            placeholder="A role to manage the chatbot."
          />
        </FormGroup>
        <div>
          <Label>Permissions</Label>
          <Permissions
            value={permissions.map(permission => ({
              ...permission,
              disabled: false
            }))}
            onChange={handlePermissionsChange}
          />
        </div>
        {!autoSave && (
          <Flex right>
            <Button type="submit" disabled={!valid}>
              <Flex xSpaceSm center>
                <Icon outline type="save" />
                <span>Save</span>
              </Flex>
            </Button>
          </Flex>
        )}
      </Flex>
    </form>
  );
};

RoleDetails.propTypes = {
  autoSave: PropTypes.bool.isRequired,
  initalName: PropTypes.string,
  initalDescription: PropTypes.string,
  initalPermissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  ),
  handleSubmit: PropTypes.func.isRequired
};

export default RoleDetails;
