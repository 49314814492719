import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Divider,
  Flex,
  TextInput,
  Button,
  Label,
  Select
} from '@ubisend/pulse-components';
import { GroupSelect } from '@ubisend/pulse-groups';

const defaultFilters = {
  search: '',
  groups: [],
  status: null
};

const StatusSelect = ({ value, ...props }) => {
  const options = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' }
  ];

  return (
    <Select
      {...props}
      isClearable
      options={options}
      value={value ? options.find(option => option.value === value) : null}
    />
  );
};

StatusSelect.propTypes = {
  value: PropTypes.string
};

const LinksFilter = ({ handleFilterSubmit }) => {
  const [filters, setFilters] = useState(defaultFilters);

  const handleSubmit = () => {
    handleFilterSubmit(filters);
  };

  const handleReset = () => {
    setFilters(defaultFilters);
    handleFilterSubmit(defaultFilters);
  };

  const handleSearchChange = event => {
    const search = event.target.value;

    setFilters(filters => ({ ...filters, search }));
  };

  const handleGroupsChange = groups => {
    setFilters(filters => ({
      ...filters,
      groups: groups ? groups.map(group => group.value) : []
    }));
  };

  const handleStatusChange = status => {
    setFilters(filters => ({
      ...filters,
      status: status ? status.value : null
    }));
  };

  return (
    <Flex col style={{ width: '20rem' }}>
      <Flex pl pr pt col>
        <Flex fat col mb>
          <Label htmlFor="name">Search</Label>
          <TextInput
            id="Search"
            placeholder="Search..."
            onChange={handleSearchChange}
            value={filters.search}
          />
        </Flex>
        <Flex fat col mb>
          <Label htmlFor="tags">Tags</Label>
          <GroupSelect
            for="links"
            id="tags"
            onChange={handleGroupsChange}
            value={filters.groups}
          />
        </Flex>
        <Flex fat col>
          <Label htmlFor="status">Status</Label>
          <StatusSelect
            id="status"
            onChange={handleStatusChange}
            value={filters.status}
          />
        </Flex>
      </Flex>
      <Divider />
      <Flex right xSpace px pr pb>
        <Button
          variant="secondary"
          colour="danger"
          icon="x"
          onClick={handleReset}>
          Reset
        </Button>
        <Button variant="primary" icon="check" onClick={handleSubmit}>
          Apply
        </Button>
      </Flex>
    </Flex>
  );
};

LinksFilter.propTypes = {
  handleFilterSubmit: PropTypes.func.isRequired
};

export { defaultFilters };
export default LinksFilter;
