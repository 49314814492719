import { QueryClient } from 'react-query';
import axios, { CancelToken } from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['Accept'] = 'application/json';
axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const defaultQueryFn = async ({ queryKey }) => {
  const [key, params = {}] = queryKey;

  const source = CancelToken.source();

  const promise = axios.get(key, {
    params,
    cancelToken: source.token
  });

  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  const { data } = await promise;

  return data;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      enabled: true,
      queryFn: defaultQueryFn,
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    },
    mutations: {
      enabled: true
    }
  }
});

export default queryClient;
