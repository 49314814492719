import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Container,
  LeftSection,
  ComposerButton,
  Airplane,
  Input,
  Label
} from '../Custom/MessageComposer';

const DeactivatedComposer = () => {
  const { t } = useTranslation('full_page');

  return (
    <Container>
      <LeftSection>
        <Label htmlFor="deactivated-composer">
          {t('deactivated_composer_label')}
        </Label>
        <Input id="deactivated-composer" disabled />
      </LeftSection>
      <ComposerButton aria-label={t('send_button_label')} disabled>
        <Airplane />
      </ComposerButton>
    </Container>
  );
};

export default DeactivatedComposer;
