import React from 'react';
import PropTypes from 'prop-types';

import { ConditionalContext } from '../Contexts/index';
import { useConditionalReducer } from '../hooks/index';

const ConditionalProvider = ({ children, ...config }) => {
  const context = useConditionalReducer(config);

  return (
    <ConditionalContext.Provider value={context}>
      {children}
    </ConditionalContext.Provider>
  );
};

ConditionalProvider.propTypes = {
  conditionals: PropTypes.shape({
    conditionals: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
        condition: PropTypes.any
      })
    ),
    match: PropTypes.oneOf(['all', 'any'])
  })
};

export default ConditionalProvider;
