import React from 'react';

import { Flex, Label, TextInput } from '@ubisend/pulse-components';

import { useVariable } from '../../../../hooks/index';

const FloatDefaultValueInput = () => {
  const variable = useVariable();

  const handleDefaultValueChange = event => {
    const value = event.target.value;

    variable.dispatch({
      type: variable.TYPES.UPDATE_DEFAULT_VALUE,
      defaultValue: value
    });
  };

  return (
    <Flex col mb>
      <Label htmlFor="default">Default value</Label>
      <TextInput
        id="default"
        type="number"
        step="0.1"
        value={variable.variable.default_value}
        onChange={handleDefaultValueChange}
        placeholder="My default value"
      />
    </Flex>
  );
};

export default FloatDefaultValueInput;
