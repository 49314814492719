import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chroma from 'chroma-js';

import { motion } from '@ubisend/framer-motion';

import { Divider } from '../Spacing';
import { Placeholder } from '../Loading';
import { Heading2 } from '../Text';
import { grid } from '../styles';

const Container = styled(motion.div)`
  ${grid}
  ${tw`border border-solid border-grey-medium rounded w-full p-4 bg-white`};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => chroma(theme.black).alpha(0.25)} 0px 10px 10px -10px;
  ${props => props.tall && tw`h-full`}
  & h2 {
    ${tw`font-semibold text-xl`}
  }
  & > h2 {
    ${tw`m-0 p-0 mb-4`}
  }
`;

const HeaderContainer = styled.div`
  ${tw`flex items-center border-bottom`}
`;

const ActionsContainer = styled.div`
  ${tw`flex p-4 md:p-0`}
`;

const Grow = styled.div`
  ${tw`flex-grow`}
`;

const Panel = React.forwardRef(
  (
    {
      header,
      actions,
      children,
      divider = false,
      loading = false,
      loadingItems = 2,
      ...props
    },
    ref
  ) => {
    if (header && actions) {
      return (
        <Container {...props} ref={ref}>
          <HeaderContainer>
            <Heading2>{header}</Heading2>
            <Grow />
            <ActionsContainer>{actions}</ActionsContainer>
          </HeaderContainer>
          {divider && <Divider fullWidth />}
          {loading ? <Placeholder /> : children}
        </Container>
      );
    }

    return (
      <Container {...props} ref={ref}>
        {header && <Heading2>{header}</Heading2>}
        {divider && <Divider fullWidth />}
        {loading ? <Placeholder items={loadingItems} /> : children}
      </Container>
    );
  }
);

Panel.displayName = 'Panel';

Panel.propTypes = {
  header: PropTypes.any,
  actions: PropTypes.any,
  divider: PropTypes.bool,
  tall: PropTypes.bool,
  loading: PropTypes.bool,
  loadingItems: PropTypes.number
};

export default Panel;
