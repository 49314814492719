const STATES = {
  LOADING: 'loading',
  TYPING: 'typing'
};

const useTypingIndicatorAnimation = message => {
  if (!message) {
    return STATES.LOADING;
  }

  if (message.type === 'progress') {
    return STATES.LOADING;
  }

  if (message.direction === 'toServer') {
    return STATES.LOADING;
  }

  return STATES.TYPING;
};

export default useTypingIndicatorAnimation;
