import React from 'react';
import { Link } from 'react-router-dom';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Flex,
  Button,
  StretchPanel,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableActions,
  Pagination,
  Tag,
  OrderableTableRow,
  ActionMenu
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import {
  CreateTicketButton,
  TicketStatus,
  NoTickets,
  SubscriberLink,
  TicketCreatedAt,
  TicketGroupTableCell,
  TicketNameTableCell,
  TicketFilters
} from '../Components/index';
import { useTicketFilters } from '../hooks/index';

const ArchivedTickets = () => {
  const { form, props, pagination, order } = useTicketFilters({
    ticket_types: [],
    statuses: [],
    assignee_ids: []
  });

  const query = useQuery(['tickets/ubidesk', { bot_could_answer: 1, ...form }]);

  return (
    <PageWrapper
      header="Bot Tickets"
      subheader="Manage tickets that could be converted to FAQs"
      actions={
        <Flex xSpace>
          <PermissionFilter can="create tickets">
            <CreateTicketButton>Create New</CreateTicketButton>
          </PermissionFilter>
          <ActionMenu
            buttonProps={{
              'aria-label': 'Toggle filter menu',
              variant: 'secondary',
              icon: 'filter',
              children: 'Filter',
              loading: query.isLoading
            }}>
            <TicketFilters {...props} />
          </ActionMenu>
          <Button as={Link} variant="secondary" to={`/tickets`} icon="ticket">
            Tickets
          </Button>
        </Flex>
      }>
      {query.showNoResultsMessage && (
        <NoTickets>
          <h1>Tickets that could be answered by the chatbot appear here</h1>
        </NoTickets>
      )}
      {query.showTable && (
        <StretchPanel>
          <Table loading={query.isLoading} loadingColumns={6}>
            <TableHead>
              <OrderableTableRow
                rows={[
                  { label: 'Summary', sort: 'name' },
                  { label: 'Tags', sort: null },
                  { label: 'Status', sort: 'status' },
                  { label: 'Subscriber', sort: null },
                  { label: 'Date', sort: 'created_at' },
                  null
                ]}
                {...order.props}
              />
            </TableHead>
            {query.isSuccess && (
              <TableBody>
                {query.data.data.map((ticket, key) => (
                  <TableRow key={key}>
                    <TicketNameTableCell>{ticket.name}</TicketNameTableCell>
                    <TicketGroupTableCell>
                      <Flex xSpaceSm xScroll>
                        {ticket.groups.length > 0
                          ? ticket.groups.map(group => (
                              <Tag key={group.id}>{group.name}</Tag>
                            ))
                          : 'N/A'}
                      </Flex>
                    </TicketGroupTableCell>
                    <TableCell>
                      <TicketStatus>{ticket.status}</TicketStatus>
                    </TableCell>
                    <TableCell>
                      <SubscriberLink subscriber={ticket.subscriber} />
                    </TableCell>
                    <TableCell>
                      <TicketCreatedAt date={ticket.created_at} />
                    </TableCell>
                    <TableActions>
                      <Button
                        as={Link}
                        variant="secondary"
                        to={`/tickets/${ticket.id}`}
                        icon="eye">
                        View
                      </Button>
                    </TableActions>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {query.showPagination && (
            <Pagination pagination={query.data.meta} {...pagination.props} />
          )}
        </StretchPanel>
      )}
    </PageWrapper>
  );
};

export default ArchivedTickets;
