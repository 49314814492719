import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Span,
  SecondarySidebarLink as SectionLink
} from '@ubisend/pulse-components';

import SortableSidebarItem from './SortableSidebarItem';
import { useImports } from '../hooks/index';
import { updateSectionOrder as updateSectionOrderApi } from '../api/index';

const SAVE_SECTION_REORDER_DEBOUNCE = 2000;

const orderByIndex = (a, b) => a.index - b.index;

const SaveSectionOrder = ({ sections }) => {
  const orderRef = useRef(null);

  const updateSectionOrder = useMutation(updateSectionOrderApi);

  const formatSections = sections => {
    return sections.sort(orderByIndex).map(({ id }) => id);
  };

  const checkIfSectionsHaveBeenReordered = useCallback(sections => {
    return formatSections(sections).join() !== orderRef.current.join();
  }, []);

  /**
   * Initialise ref with first value of section order.
   */
  useEffect(() => {
    if (!orderRef.current) {
      orderRef.current = formatSections(sections);
    }
  }, [sections]);

  /**
   * Everytime the order of the sections change, start a timer to save the new
   * order and debounce any future changes.
   */
  useEffect(() => {
    let timeout;

    if (orderRef.current && checkIfSectionsHaveBeenReordered(sections)) {
      timeout = setTimeout(() => {
        orderRef.current = formatSections(sections);
        updateSectionOrder.mutate({
          importId: sections[0].import_id,
          section_ids: orderRef.current
        });
      }, SAVE_SECTION_REORDER_DEBOUNCE);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [sections, checkIfSectionsHaveBeenReordered, updateSectionOrder]);

  return null;
};

const SortableSidebarItemWrapper = ({ importItem, hasSections, open }) => {
  const { dispatch } = useImports();

  const isManualImport = importItem.type === 'Manual Import';

  const handleReorder = ({ from, to }) => {
    dispatch({ type: 'REORDER_SECTION', from, to });
  };

  const buildLink = (path = '') => {
    return `/knowledge-bases/${importItem.id}/sections/${path}`;
  };

  const checkActive = path => {
    return location.pathname.includes(buildLink(path));
  };

  return (
    <>
      {hasSections && <SaveSectionOrder sections={importItem.sections} />}
      {open && (
        <Flex ySpace col>
          {hasSections &&
            importItem.sections
              .sort(orderByIndex)
              .map(section => (
                <SortableSidebarItem
                  key={section.id}
                  section={section}
                  importItem={importItem}
                  handleReorder={handleReorder}
                  checkActive={checkActive}
                />
              ))}
          {isManualImport && (
            <Flex shiftUp>
              <SectionLink
                active={false}
                to={`/knowledge-bases/${importItem.id}/sections/new`}>
                + Create new section
              </SectionLink>
            </Flex>
          )}
          {!isManualImport && !hasSections && (
            <Span tinyText>
              Content is being imported. Check back later to see the results.
            </Span>
          )}
        </Flex>
      )}
    </>
  );
};

SortableSidebarItemWrapper.propTypes = {
  importItem: PropTypes.any.isRequired,
  hasSections: PropTypes.bool.isRequired,
  open: PropTypes.any
};

export default SortableSidebarItemWrapper;
