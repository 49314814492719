import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  RightHalfLayout,
  LeftHalfLayout,
  PanelSlider,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableCell,
  Flex,
  Divider,
  FormHeading,
  Span,
  TableActions,
  Button,
  ConfidenceFormatter
} from '@ubisend/pulse-components';
import { AppWidget } from '@ubisend/pulse-bot';
import { PermissionFilter } from '@ubisend/pulse-auth';

import IntentSearcher from './IntentSearcher';
import { getIntentLink } from '../utils/index';
import IntentLink from './IntentLink';

const TrainingWindow = ({
  handleHide,
  message,
  handleIntentChange,
  actions,
  languageId
}) => {
  const { data, isSuccess } = useQuery(`messages/${message.id}/context`);

  const {
    trained_intent: intent,
    other_intents: otherIntents,
    hasMappedIntent
  } = message;

  return (
    <PanelSlider header="Training Window" handleHide={handleHide}>
      <Flex>
        <RightHalfLayout>
          {hasMappedIntent && (
            <>
              <Flex col>
                <FormHeading>Chatbot Understanding</FormHeading>
                <Flex between center>
                  <Flex>
                    <Span>
                      The chatbot was{' '}
                      <ConfidenceFormatter>
                        {intent.confidence}
                      </ConfidenceFormatter>{' '}
                      confident that the question was{' '}
                      <Link to={getIntentLink(intent)} target="_blank">
                        {`${intent.name}`}
                      </Link>
                    </Span>
                  </Flex>
                  {actions && actions}
                </Flex>
              </Flex>
              <Divider tall />
            </>
          )}
          <FormHeading>Quick Train</FormHeading>
          {otherIntents.length > 0 ? (
            <Flex border>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>FAQ</TableHeadCell>
                    <TableHeadCell>Confidence</TableHeadCell>
                    <TableHeadCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {otherIntents
                    .sort((a, b) => b.confidence - a.confidence)
                    .map(intent => (
                      <TableRow key={intent.identifier}>
                        <TableCell>
                          {intent.faq || intent.smallTalk ? (
                            <IntentLink {...intent} />
                          ) : (
                            <Span>{intent.name}</Span>
                          )}
                        </TableCell>
                        <TableCell>
                          <ConfidenceFormatter>
                            {intent.confidence}
                          </ConfidenceFormatter>
                        </TableCell>
                        <TableActions>
                          <PermissionFilter can="edit training messages">
                            <Button
                              icon="save"
                              variant="secondary"
                              onClick={() => {
                                handleIntentChange(message.id, {
                                  identifier: intent.identifier,
                                  name: intent.name
                                });
                              }}>
                              Update
                            </Button>
                          </PermissionFilter>
                        </TableActions>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Flex>
          ) : (
            <Span light>The chatbot could not find any FAQs to recommend</Span>
          )}
          <PermissionFilter can="edit training messages">
            <Divider tall />
            <FormHeading>Manual Train</FormHeading>
            <IntentSearcher
              languageId={languageId}
              handleSubmit={({ value, label }) => {
                handleIntentChange(message.id, {
                  identifier: value,
                  name: label
                });
              }}
            />
          </PermissionFilter>
        </RightHalfLayout>
        <LeftHalfLayout>
          {isSuccess && (
            <Flex middle>
              <AppWidget
                name={'Training'}
                messages={data.data.map(
                  ({ id, message: content, direction, type }) => ({
                    composer: { type: 'hidden' },
                    content,
                    direction:
                      direction === 'incoming' ? 'toServer' : 'toClient',
                    type,
                    highlight: id === message.id
                  })
                )}></AppWidget>
            </Flex>
          )}
        </LeftHalfLayout>
      </Flex>
    </PanelSlider>
  );
};

TrainingWindow.propTypes = {
  handleHide: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  handleIntentChange: PropTypes.func.isRequired,
  actions: PropTypes.any,
  languageId: PropTypes.number.isRequired
};

export default TrainingWindow;
