import axios from 'axios';

const getTriggers = () => axios.get('conversations/builder/triggers');

const updateTriggers = triggers => {
  return axios.put(`conversations/builder/triggers`, {
    triggers
  });
};

const createTrigger = (conversationId, trigger) => {
  return axios.post(
    `conversations/builder/${conversationId}/triggers`,
    trigger
  );
};

const updateTrigger = (conversationId, triggerId, trigger) => {
  return axios.put(
    `conversations/builder/${conversationId}/triggers/${triggerId}`,
    trigger
  );
};

const deleteTrigger = (conversationId, triggerId) => {
  return axios.delete(
    `conversations/builder/${conversationId}/triggers/${triggerId}`
  );
};

const createStepTrigger = (stepId, trigger) => {
  return axios.post(`conversations/builder/steps/${stepId}/triggers`, trigger);
};

const updateStepTrigger = (stepId, triggerId, trigger) => {
  return axios.put(
    `conversations/builder/steps/${stepId}/triggers/${triggerId}`,
    trigger
  );
};

const deleteStepTrigger = (stepId, triggerId) => {
  return axios.delete(
    `conversations/builder/steps/${stepId}/triggers/${triggerId}`
  );
};

export {
  getTriggers,
  updateTriggers,
  createTrigger,
  updateTrigger,
  deleteTrigger,
  createStepTrigger,
  updateStepTrigger,
  deleteStepTrigger
};
