import { useReducer, useCallback } from 'react';

import composers from '../Components/composers';

const TYPES = {
  // General details
  UPDATE_NAME: 'UPDATE_NAME',
  UPDATE_TYPE: 'UPDATE_TYPE',
  UPDATE_CONTENT: 'UPDATE_CONTENT',
  // Subjects
  UPDATE_SUBJECT_TYPE: 'UPDATE_SUBJECT_TYPE',
  RESET_SUBJECT: 'RESET_SUBJECT',
  UPDATE_SUBJECT: 'UPDATE_SUBJECT'
};

const getComposer = type => {
  return composers.find(composer => composer.id === type);
};

const getDefaultValue = type => {
  const composer = getComposer(type);

  return composer.content;
};

const isValid = ({ name, type, content }) => {
  if (!name || !type) {
    return false;
  }

  const composer = getComposer(type);

  return composer.valid(content);
};

const getSubject = (subjects, type) => {
  return subjects.find(({ subject }) => subject.type === type);
};

const reducer = (state, { type, subjects, ...params }) => {
  // General details
  switch (type) {
    case TYPES.UPDATE_NAME:
      return { ...state, name: params.name };
    case TYPES.UPDATE_TYPE:
      return {
        ...state,
        type: params.composer_type,
        content: getDefaultValue(params.composer_type)
      };
    case TYPES.UPDATE_CONTENT:
      return { ...state, content: { ...state.content, ...params.content } };

    // Subjects
    case TYPES.UPDATE_SUBJECT_TYPE: {
      const subject = getSubject(subjects, params.subjectType);

      return {
        ...state,
        subject: subject.toState()
      };
    }
    case TYPES.RESET_SUBJECT:
      return { ...state, subject: null };
    case TYPES.UPDATE_SUBJECT: {
      const { type, ...subject } = state.subject;

      return {
        ...state,
        subject: { ...subject, ...params.subject, type }
      };
    }

    // Errors
    default:
      throw new Error(`No event defined in useComposerReducer for ${type}`);
  }
};

const setInitalSubject = (currentSubject, subjects) => {
  if (!currentSubject) {
    return null;
  }

  const { toState } = subjects.find(({ subject }) => {
    return subject.type === currentSubject.type;
  });

  return toState(currentSubject);
};

const defaultComposer = {
  name: '',
  type: 'message',
  content: getDefaultValue('message'),
  subject: null
};

const useComposerReducer = (initialComposer = defaultComposer, subjects) => {
  const initialValue = {
    ...defaultComposer,
    ...initialComposer
  };
  const [state, dispatch] = useReducer(reducer, {
    ...initialValue,
    subject: setInitalSubject(initialValue.subject, subjects)
  });

  const wrappedDispatch = useCallback(
    (params = {}) => {
      dispatch({ ...params, subjects });
    },
    [dispatch, subjects]
  );

  const valid = isValid(state);

  const formatSubject = chosenSubject => {
    if (!chosenSubject) {
      return null;
    }

    const subject = getSubject(subjects, chosenSubject.type);

    return subject.toApi(chosenSubject);
  };

  const form = {
    name: state.name,
    composer_type: state.type,
    content: state.content,
    subject: formatSubject(state.subject)
  };

  return {
    // State
    state,
    ...state,
    dispatch: wrappedDispatch,
    // Subjects
    subjects,
    getSubject,
    // Types,
    TYPES,
    // Forms
    valid,
    form
  };
};

export default useComposerReducer;
