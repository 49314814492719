import React from 'react';

import {
  FormGroup,
  Label,
  Checkbox,
  TextInput,
  Flex
} from '@ubisend/pulse-components';

import useComposer from '../../../hooks/useComposer';

const MessageInput = () => {
  const composer = useComposer();

  const handlePlaceholderChange = event => {
    const placeholder = event.target.value;

    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { placeholder }
    });
  };

  const handleDisabledChange = () => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { disabled: !composer.content.disabled }
    });
  };

  const handleShowFileUploadChange = () => {
    composer.dispatch({
      type: composer.TYPES.UPDATE_CONTENT,
      content: { show_file_upload: !composer.content.show_file_upload }
    });
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor="placeholder">Placeholder</Label>
        <TextInput
          id="placeholder"
          placeholder="Send a message"
          value={composer.content.placeholder}
          onChange={handlePlaceholderChange}
        />
      </FormGroup>
      <FormGroup>
        <Checkbox
          checked={composer.content.disabled}
          onChange={handleDisabledChange}
          label="Deactivate composer"
        />
      </FormGroup>
      <Flex>
        <Checkbox
          checked={composer.content.show_file_upload}
          onChange={handleShowFileUploadChange}
          label="Show file upload"
        />
      </Flex>
    </>
  );
};

export default MessageInput;
