import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chroma from 'chroma-js';
import { useDrag } from 'react-dnd';

import { motion } from '@ubisend/framer-motion';
import { Label, Grid, Flex, Span } from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';
import { useAuth } from '@ubisend/pulse-auth';

import blockTypes from '../Blocks/Types/index';
import nodeTypes from '../Nodes/Types/index';
import { useCanvas } from '../../hooks/index';

const categories = [
  {
    name: 'Nodes',
    blocks: [nodeTypes.step]
  },
  {
    name: 'Transition Blocks',
    blocks: [blockTypes.variable, blockTypes.metric]
  },
  {
    name: 'Step Blocks',
    blocks: [
      blockTypes.integration,
      blockTypes.email,
      blockTypes.action,
      nodeTypes.trigger,
      nodeTypes.validation
    ]
  }
];

const Item = styled(motion.div)`
  ${tw`px-4 py-3 flex items-center rounded`}
  cursor: grab;
  background: ${props =>
    chroma.mix('white', props.colour || props.theme.primary, 0.25)};
  border: 1px solid
    ${props => chroma.mix('white', props.colour || props.theme.primary, 0.66)};
  color: ${props => props.colour};
  & svg {
    ${tw`w-6 h-6 mr-3 flex`}
  }
  & span {
    ${tw`font-poppins text-sm font-semibold`}
  }
`;

const DraggableItem = ({ block, ...props }) => {
  const ref = useRef(null);
  const { setHoveredBlock } = useCanvas();

  const [, drag] = useDrag({
    type: 'BLOCK',
    item: () => block
  });

  drag(ref);

  return (
    <Item
      ref={ref}
      onMouseOver={() =>
        setHoveredBlock(
          categories.find(category => {
            return category.blocks.find(({ id }) => block.id === id);
          }).name
        )
      }
      onClick={() => setHoveredBlock(null)}
      onMouseLeave={() => setHoveredBlock(null)}
      whileHover={{
        boxShadow: '0px 8px 16px -8px rgba(0, 0, 0, 0.5)',
        scale: 1.025,
        y: -3,
        transition: {
          type: 'spring',
          stiffness: 600,
          damping: 70,
          restDelta: 0.001
        }
      }}
      {...props}
    />
  );
};

DraggableItem.propTypes = {
  block: PropTypes.object.isRequired
};

const Blocks = () => {
  const { hasPermission } = useAuth();

  const filteredCategories = categories.map(category => ({
    ...category,
    blocks: category.blocks.filter(category => {
      if (category.permission) {
        return hasPermission(`view ${category.permission}`);
      }

      return true;
    })
  }));

  return (
    <Flex col ySpace pad>
      {filteredCategories.map((category, key) => (
        <Flex col key={key}>
          <Label>{category.name}</Label>
          {category.blocks.length === 0 && (
            <Span tinyText light>
              No available blocks
            </Span>
          )}
          {category.blocks.length > 0 && (
            <Grid columns={2}>
              {category.blocks.map(block => (
                <DraggableItem
                  block={block}
                  colour={block.colour}
                  key={block.id}>
                  <Icon type={block.icon} outline />
                  <span>{block.name}</span>
                </DraggableItem>
              ))}
            </Grid>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default Blocks;
