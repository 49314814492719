import React, { useState } from 'react';

import { useNotification, Flex, Button } from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';

import { updateClient } from '../api/index';
import { useClient } from '../hooks/index';
import ClientDetails from './ClientDetails';

const CurrentClient = () => {
  const [submitted, setSubmitted] = useState();

  const { client: currentClient, setAuth } = useAuth();
  const { client, handlers, valid } = useClient(currentClient);
  const { showSuccess } = useNotification();

  const handleSubmit = async e => {
    e.preventDefault();

    setSubmitted(true);

    try {
      const { data } = await updateClient(client);
      setAuth(auth => ({ ...auth, client: data.data }));
    } catch (error) {
      return;
    } finally {
      setSubmitted(false);
    }

    showSuccess(`Successfully updated account`);
  };

  return (
    <form onSubmit={handleSubmit}>
      <ClientDetails client={client} {...handlers} />
      <Flex mt right>
        <Button type="submit" loading={submitted} disabled={!valid}>
          Save
        </Button>
      </Flex>
    </form>
  );
};

CurrentClient.propTypes = {};

export default CurrentClient;
