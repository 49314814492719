import ActionInput from './ActionInput';

const action = {
  id: 'action',
  name: 'Action',
  Input: ActionInput,
  content: {
    text: '',
    responses: []
  },
  valid: content => {
    return (
      content.text.length > 0 &&
      content.responses.length > 0 &&
      // TODO
      content.responses.every(({ label, action }) => label && action)
    );
  },
  snippet: `{
    "type": "action",
    "content": {
      "text": "",
      "responses": [
        {"type": "postback", "label": "", "action": ""}
      ]
    }
}`
};

export default action;
