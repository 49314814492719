import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../../hooks/index';

const StyledSelect = styled(Select)`
  ${tw`w-full px-1 h-full flex`}
`;

const styleOverrides = {
  control: () => tw`w-full flex items-center`,
  container: () => tw`static px-4`,
  valueContainer: styles => ({
    maxHeight: 36,
    ...styles,
    ...tw`p-0 overflow-y-auto`
  }),
  menu: base => ({
    ...base,
    ...{
      margin: '0.5rem',
      left: 0,
      width: 'calc(100% - 1rem)'
    }
  })
};

const BotSelect = props => {
  const { sentMessage } = useTheme();

  const { t } = useTranslation('bots');

  const styles = {
    multiValueLabel: styles => ({
      ...styles,
      ...tw`font-poppins uppercase leading-none text-xs`,
      color: sentMessage.text
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: chroma(sentMessage.background).alpha(0.8).css(),
      marginTop: -1
    }),
    multiValueRemove: styles => ({ ...styles, color: sentMessage.text }),
    placeholder: styles => ({ ...styles, color: 'black' })
  };

  return (
    <StyledSelect
      menuPlacement="top"
      styles={{ ...styles, ...styleOverrides }}
      components={{ IndicatorsContainer: () => null }}
      noOptionsMessage={() => t('fuzzy_composer_no_options_message')}
      {...props}
    />
  );
};

export default BotSelect;
