import React, { useState, useEffect, useCallback } from 'react';

import {
  PageWrapper,
  LoadingContainer,
  Panel,
  useNotification,
  LeftHalfLayout,
  RightHalfLayout,
  Flex,
  Explainer
} from '@ubisend/pulse-components';
import { AppComposerWidget } from '@ubisend/pulse-bot';
import {
  MultiComposerProvider,
  MultiComposerSettings,
  MultiComposer,
  MultiComposerSubmitButton,
  MultiComposerWidget,
  MultiComposerLanguageSelect
} from '@ubisend/pulse-composer';

import { getPieceOfSmallTalk, updateSmallTalkResponses } from '../api/index';
import subjects from '../subjects/index';

const EditSmallTalk = ({
  match: {
    params: { smallTalkId }
  }
}) => {
  const [smallTalk, setSmallTalk] = useState();

  const { showNotification } = useNotification();

  const fetchSmallTalk = useCallback(async () => {
    const { data } = await getPieceOfSmallTalk(smallTalkId);
    setSmallTalk(data.data);
  }, [smallTalkId]);

  const handleSubmit = async responses => {
    await updateSmallTalkResponses(smallTalkId, responses);
    showNotification({ message: 'Responses successfully updated' });
  };

  useEffect(() => {
    fetchSmallTalk();
  }, [fetchSmallTalk]);

  const loading = !smallTalk;

  return (
    <PageWrapper
      header="Responses"
      subheader={!loading && `Responses For "${smallTalk.name}"`}>
      {loading && <LoadingContainer />}
      {!loading && (
        <MultiComposerProvider
          initalResponses={smallTalk.responses}
          subjects={subjects.composers.subjects}>
          <Flex>
            <LeftHalfLayout>
              <Panel
                header="Compose your messages"
                actions={<MultiComposerLanguageSelect />}>
                <Explainer mb mt>
                  When a subscriber is small talking with your chatbot, the
                  chatbot will choose a random response in the correct language
                  to respond with.
                </Explainer>
                <MultiComposer />
                <Flex xSpace>
                  <MultiComposerSettings />
                  <MultiComposerSubmitButton onClick={handleSubmit}>
                    Save
                  </MultiComposerSubmitButton>
                </Flex>
              </Panel>
            </LeftHalfLayout>
            <RightHalfLayout>
              <Flex fat middle>
                <MultiComposerWidget as={AppComposerWidget} />
              </Flex>
            </RightHalfLayout>
          </Flex>
        </MultiComposerProvider>
      )}
    </PageWrapper>
  );
};

export default EditSmallTalk;
