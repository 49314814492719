const getParam = (location, param) => {
  const serachParam = param.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + serachParam + '=([^&#]*)');
  const results = regex.exec(location.search);

  return results === null ? '' : decodeURIComponent(results[1]);
};

const useQuerySearch = (location, param) => {
  const result = getParam(location, param);

  return result;
};

export default useQuerySearch;
