import IntegerDefaultValueInput from './IntegerDefaultValueInput';
import IntegerPreview from './IntegerPreview';

const integerType = {
  key: 'Integer',
  name: 'Number',
  default_value: 0,
  DefaultValueInput: IntegerDefaultValueInput,
  Preview: IntegerPreview
};

export default integerType;
