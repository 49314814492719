const types = ['postback', 'url', 'file', 'protocol'];

const getDefaultValue = type => {
  const postback = { type: 'postback', label: '', action: '' };

  const url = { type: 'url', label: '', action: '', linkback: '' };

  const file = { type: 'file', label: '', action: '', linkback: '' };

  const protocol = { type: 'mailto', label: '', action: '', linkback: '' };

  const defaultValues = {
    postback,
    url,
    file,
    protocol
  };

  return defaultValues[type];
};

export { types, getDefaultValue };
