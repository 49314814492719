import React from 'react';
import styled from 'styled-components';

import Footer, { animations } from './Footer';

const FixedContainer = styled(Footer)`
  box-sizing: border-box;
  width: calc(100vw - 12rem);
  ${tw`fixed pin-b pin-r z-20`}
`;

const FixedFooter = ({ children }) => (
  <FixedContainer {...animations}>{children}</FixedContainer>
);

export default FixedFooter;
