import StandardInput from './StandardInput';

const standard = {
  id: 'standard',
  name: 'Standard',
  Input: StandardInput,
  content: { text: '' },
  valid: content => {
    return content.text.length > 0;
  },
  snippet: `{
    "type": "standard",
    "content": {
      "text": ""
    }
}`
};

export default standard;
