import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const FilterInput = ({ filters, filter, ...props }) => {
  const { Input } = useMemo(() => {
    return filters.find(option => option.name === filter.name);
  }, [filters, filter.name]);

  return <Input content={filter.content} {...props} />;
};

FilterInput.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      Input: PropTypes.any.isRequired
    })
  ).isRequired,
  filter: PropTypes.shape({
    name: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired
  })
};

export default FilterInput;
