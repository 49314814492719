import React from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '../hooks/index';
import PermissionDebug from './PermissionDebug';
import { DEBUG_PERMISSIONS } from '../constants';

const PermissionFilter = ({ children, can: permission, fallback }) => {
  const { hasPermission } = useAuth();

  if (DEBUG_PERMISSIONS) {
    return (
      <PermissionDebug
        permission={permission}
        hasPermission={hasPermission(permission)}>
        {children}
      </PermissionDebug>
    );
  }

  if (hasPermission(permission)) {
    return children;
  }

  if (fallback) {
    return fallback;
  }

  return null;
};

PermissionFilter.propTypes = {
  can: PropTypes.string.isRequired,
  fallback: PropTypes.any
};

export default PermissionFilter;
