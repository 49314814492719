const deactivated = {
  id: 'deactivated',
  name: 'Deactivated',
  Input: null,
  content: {},
  valid: () => true,
  snippet: `{
    "type": "deactivated",
    "content": {} 
}`
};

export default deactivated;
