import React from 'react';
import PropTypes from 'prop-types';

import { Accordion, useNotification, Button } from '@ubisend/pulse-components';
import { useDelete, useQueryClient } from '@ubisend/pulse-hooks';

import LookupDetails from './LookupDetails';
import { useSheets } from '../../hooks/index';
import { editLookup, deleteLookup } from '../../api/index';

const EditLookup = ({ id, name, description, match, conditionals }) => {
  const { showSuccess } = useNotification();
  const { viewLookups: sheet } = useSheets();

  const queryClient = useQueryClient();
  const { handleDelete } = useDelete(deleteLookup, {
    queries: ['spreadsheets']
  });

  const handleSubmit = async params => {
    await editLookup(id, { ...params, spreadsheet_id: sheet.id });

    showSuccess('Successfully edited spreadsheet lookup');
    queryClient.invalidateQueries('spreadsheets');
  };

  return (
    <Accordion
      aria-label={`create-endpoint-chevron`}
      header={name}
      actions={
        <Button
          variant="inline"
          colour="danger"
          onClick={() => handleDelete(id)}
          icon={'trash'}>
          Delete
        </Button>
      }>
      <LookupDetails
        handleSubmit={handleSubmit}
        sheet={sheet}
        initalName={name}
        initalDescription={description}
        initialConditionals={{ conditionals, match }}
      />
    </Accordion>
  );
};

EditLookup.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  match: PropTypes.oneOf(['all', 'any']),
  conditionals: PropTypes.array.isRequired
};

export default EditLookup;
