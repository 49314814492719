import React from 'react';

import { LanguageOption, ButtonSelect } from '@ubisend/pulse-components';
import { useLanguages } from '@ubisend/pulse-hooks';

import { useComposer } from '../../hooks/index';

const format = language => ({
  value: language.id,
  label: language.name,
  valid: language.valid
});

const ComposerLanguageSelect = props => {
  const { language, dispatch, isValidLanguage } = useComposer();
  const { languages } = useLanguages();

  const handleLanguageChange = ({ value, label }) => {
    dispatch({ type: 'SELECT_LANGUAGE', language: { id: value, name: label } });
  };

  return (
    <ButtonSelect
      aria-label="language-select"
      isLoading={!languages}
      options={
        languages &&
        languages
          .map(language => ({ ...language, valid: isValidLanguage(language) }))
          .map(format)
      }
      onChange={handleLanguageChange}
      components={{ Option: LanguageOption }}
      value={
        language && languages
          ? format(languages.find(({ id }) => id === language.id))
          : null
      }
      {...props}
    />
  );
};

export default ComposerLanguageSelect;
