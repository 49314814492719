import axios from 'axios';

const syncStepActions = (stepId, actions) => {
  return axios.put(
    `conversations/builder/steps/${stepId}/actions/sync`,
    actions
  );
};

export { syncStepActions };
