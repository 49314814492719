import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chroma from 'chroma-js';

import { motion } from '@ubisend/framer-motion';
import Icon from '@ubisend/pulse-icons';

import { useTheme } from '../../hooks/index';

const Toast = styled(motion.div)`
  ${tw`pl-2 pr-2 py-2 text-sm rounded-lg mr-4 mt-2 inline-flex bg-white cursor-pointer`};
  max-width: 22rem;
  width: max-content;
  overflow-wrap: break-word;
  box-shadow: ${({ theme }) => chroma(theme.black).alpha(0.5)} 0px 10px 10px -10px;
  pointer-events: all;
  color: white;
  background-color: ${({ theme, colour }) => {
    return theme[colour];
  }};
`;

const ToastInnerWrapper = styled.div`
  ${tw`inline-flex items-start`}
  width: 95%;
`;

const ToastContent = styled.div`
  ${tw`pl-1 flex flex-col whitespace-pre-line`};
`;

const Subtitle = styled.div`
  ${tw`text-base`}
  color: ${props => props.theme.white};
`;

const CloseIcon = styled.div`
  ${tw`h-full w-5 ml-2`}
  > i {
    ${tw`cursor-pointer`}
  }
`;

const animation = {
  initial: { opacity: 0, y: 50 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 600,
      damping: 50
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2
    }
  },
  whileHover: { scale: 1.05 }
};

const colours = {
  success: 'positive',
  information: 'primary',
  error: 'danger'
};

const Notification = ({ subtitle, type = 'success', handleClose }) => {
  const { white } = useTheme();

  return (
    <Toast
      role="alert"
      layout
      colour={colours[type]}
      {...animation}
      onClick={handleClose}>
      <ToastInnerWrapper>
        <ToastContent>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </ToastContent>
      </ToastInnerWrapper>
      <CloseIcon>
        <Icon
          onClick={handleClose}
          type={'x'}
          outline
          stroke={white}
          height={'20px'}
          width={'20px'}
        />
      </CloseIcon>
    </Toast>
  );
};

Notification.propTypes = {
  title: PropTypes.any.isRequired,
  subtitle: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error', 'information']),
  handleClose: PropTypes.func.isRequired
};

export default Notification;
