import React from 'react';
import PropTypes from 'prop-types';

import { TextInput } from '@ubisend/pulse-components';

const NumberCondition = ({ onChange, ...props }) => {
  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <TextInput
      type="number"
      min={1}
      max={32767}
      onChange={handleChange}
      placeholder={20}
      {...props}
    />
  );
};

NumberCondition.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default NumberCondition;
