import { useReducer, useMemo, useCallback, useEffect } from 'react';

import { useBot } from '../../../hooks/index';
import { groupMessages } from '../../../utilities/index';

const TYPES = {
  CHANGE_MESSAGE_INDEX: 'CHANGE_MESSAGE_INDEX',
  CHANGE_GROUP_INDEX: 'CHANGE_GROUP_INDEX'
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    case TYPES.CHANGE_MESSAGE_INDEX:
      return {
        groupIndex: state.groupIndex,
        messageIndex: params.newMessageIndex
      };
    case TYPES.CHANGE_GROUP_INDEX:
      return { groupIndex: params.newGroupIndex, messageIndex: 0 };
    default:
      throw new Error(`No event defined in useGroupReducer for ${type}`);
  }
};

const useGroupReducer = () => {
  const [state, dispatch] = useReducer(reducer, {
    groupIndex: 0,
    messageIndex: 0
  });
  const { messages } = useBot();

  const groups = useMemo(() => {
    return messages.length > 0 ? messages.reduce(groupMessages, []) : [];
  }, [messages]);

  const group = useMemo(() => {
    return groups.length > 0 ? groups?.[state.groupIndex] || [] : [];
  }, [groups, state.groupIndex]);

  const message = useMemo(() => {
    return group.length > 0 && group?.[state.messageIndex];
  }, [group, state.messageIndex]);

  const handleNextMessage = useCallback(() => {
    dispatch({
      type: TYPES.CHANGE_MESSAGE_INDEX,
      newMessageIndex: state.messageIndex + 1
    });
  }, [state.messageIndex]);

  const handlePreviousMessage = useCallback(() => {
    dispatch({
      type: TYPES.CHANGE_MESSAGE_INDEX,
      newMessageIndex: state.messageIndex - 1
    });
  }, [state.messageIndex]);

  const handleNextGroup = useCallback(() => {
    dispatch({
      type: TYPES.CHANGE_GROUP_INDEX,
      newGroupIndex: state.groupIndex + 1
    });
  }, [state.groupIndex]);

  const handlePreviousGroup = useCallback(() => {
    dispatch({
      type: TYPES.CHANGE_GROUP_INDEX,
      newGroupIndex: state.groupIndex - 1
    });
  }, [state.groupIndex]);

  const hasNextMessage = useMemo(() => {
    return state.messageIndex < group.length - 1;
  }, [state.messageIndex, group.length]);

  const hasPreviousMessage = useMemo(() => {
    return state.messageIndex !== 0;
  }, [state.messageIndex]);

  const hasNextGroup = useMemo(() => {
    return state.groupIndex < groups.length - 1;
  }, [state.groupIndex, groups.length]);

  const hasPreviousGroup = useMemo(() => {
    return state.groupIndex !== 0;
  }, [state.groupIndex]);

  useEffect(() => {
    if (groups.length > 0) {
      dispatch({
        type: TYPES.CHANGE_GROUP_INDEX,
        newGroupIndex: groups.length - 1
      });
    }
  }, [groups]);

  return {
    ...state,
    groups,
    group,
    message,
    handleNextMessage,
    handlePreviousMessage,
    handleNextGroup,
    handlePreviousGroup,
    hasNextMessage,
    hasPreviousMessage,
    hasNextGroup,
    hasPreviousGroup,
    dispatch
  };
};

export default useGroupReducer;
