import axios from 'axios';

const getCopy = (page = 1) => {
  return axios.get('copy', { params: { page } });
};

const createCopy = (keyId, content) => {
  return axios.post(`copy/${keyId}/values`, {
    content
  });
};

const updateCopyValue = (keyId, valueId, content) => {
  return axios.put(`copy/${keyId}/values/${valueId}`, {
    content
  });
};

const deleteCopyValue = (keyId, valueId) => {
  return axios.delete(`copy/${keyId}/values/${valueId}`);
};

export { getCopy, createCopy, updateCopyValue, deleteCopyValue };
