import React from 'react';

import { Button, Flex } from '@ubisend/pulse-components';

import { useButtons } from '../../hooks/index';
import { getDefaultValue } from './types';

const AddButton = () => {
  const { handleButton, types } = useButtons();

  const handleClick = type => {
    handleButton(getDefaultValue(type));
  };

  return (
    <Flex xSpace right>
      {types.map((type, key) => (
        <Button key={key} variant="secondary" onClick={() => handleClick(type)}>
          + {type}
        </Button>
      ))}
    </Flex>
  );
};

export default AddButton;
