import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  StretchPanel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableActions,
  Flex,
  Pagination,
  Span,
  Button,
  usePaginationReducer,
  OrderableTableRow,
  useOrderableTableReducer
} from '@ubisend/pulse-components';

import { LicenseRouter } from '../Routers/index';
import { LicenseStatus, LicenseTotal, LicenseLink } from '../Components/index';

const Licenses = () => {
  const pagination = usePaginationReducer();
  const order = useOrderableTableReducer();

  const query = useQuery([
    'owner/licenses',
    { ...pagination.params, ...order.params }
  ]);

  return (
    <PageWrapper
      header="Licenses"
      subheader="Manage your licenses"
      actions={
        <Flex col ySpaceSm>
          <Flex right>
            <Button as={Link} variant="secondary" to="/owner/accounts">
              Accounts
            </Button>
          </Flex>
          <Flex right>
            <Flex col right>
              <LicenseTotal />
              <LicenseLink>Request More licenses</LicenseLink>
            </Flex>
          </Flex>
        </Flex>
      }>
      <LicenseRouter />
      <StretchPanel clip>
        <Table loading={query.isLoading} loadingColumns={6}>
          <TableHead>
            <OrderableTableRow
              rows={[
                { label: 'License number', sort: 'id' },
                { label: 'Status', sort: null },
                { label: 'Account', sort: null },
                { label: 'Start', sort: null },
                { label: 'End', sort: null },
                null
              ]}
              {...order.props}
            />
          </TableHead>
          {query.isSuccess && (
            <TableBody>
              {query.data.data.map((license, key) => (
                <TableRow key={key}>
                  <TableCell>#{license.id}</TableCell>
                  <TableCell>
                    <LicenseStatus {...license} />
                  </TableCell>
                  <TableCell>
                    {license.client ? license.client.name : 'Not in use'}
                  </TableCell>
                  <TableCell>
                    <Flex col>
                      <Span>
                        {dayjs(license.period.start).format('DD/MM/YYYY HH:mm')}
                      </Span>
                      <Span light>
                        {dayjs(license.period.start).from(dayjs())}
                      </Span>
                    </Flex>
                  </TableCell>
                  <TableCell>
                    <Flex col>
                      {license.period.end ? (
                        <>
                          <Span>
                            {dayjs(license.period.end).format(
                              'DD/MM/YYYY HH:mm'
                            )}
                          </Span>
                          <Span light>
                            {dayjs(license.period.end).from(dayjs())}
                          </Span>
                        </>
                      ) : (
                        'N/A'
                      )}
                    </Flex>
                  </TableCell>
                  <TableActions>
                    <Button
                      icon="eye"
                      as={Link}
                      variant="secondary"
                      to={`/owner/licenses/${license.id}`}>
                      View
                    </Button>
                  </TableActions>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {query.showPagination && (
          <Pagination pagination={query.data.meta} {...pagination.props} />
        )}
      </StretchPanel>
    </PageWrapper>
  );
};

export default Licenses;
