import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useComposer, useBot, useQuery } from '../../../../hooks/index';
import {
  LiveChatComposer,
  BannerComposer,
  LoadingComposer,
  FeedbackComposer,
  DateComposer,
  DateTimeComposer,
  TimeComposer,
  AuthComposer,
  DeactivatedComposer,
  HiddenComposer
} from './BuiltIn/index';
import {
  FuzzyComposer,
  NumericComposer,
  MessageComposer
} from './Custom/index';

const composers = {
  message: MessageComposer,
  banner: BannerComposer,
  feedback: FeedbackComposer,
  date: DateComposer,
  'date-time': DateTimeComposer,
  time: TimeComposer,
  loading: LoadingComposer,
  hidden: HiddenComposer,
  deactivated: DeactivatedComposer,
  auth: AuthComposer,
  fuzzy: FuzzyComposer,
  numeric: NumericComposer,
  'live-chat': LiveChatComposer
};

const defaultFormatter = ({ id, content }, channel) => {
  return { id, content, channel };
};

const formatDate = (props, channel) => {
  if (channel === 'embed') {
    return { ...defaultFormatter(props, channel), variant: 'dialog' };
  }

  return { ...defaultFormatter(props, channel), variant: 'static' };
};

const filters = {
  date: formatDate,
  'date-time': formatDate,
  time: formatDate
};

const formatContent = ({ type, ...props }, channel) => {
  const filter = filters[type] || defaultFormatter;

  return filter(props, channel);
};

const DynamicComposer = ({ id, channel }) => {
  const query = useQuery(`composers/${id}/dynamic`, {
    cacheTime: 0
  });

  if (!query.isSuccess) {
    return null;
  }

  const composer = query.data;
  const ComposerComponent = composers[composer.type];
  const composerProps = formatContent(composer, channel);

  return <ComposerComponent {...composerProps} />;
};

DynamicComposer.propTypes = {
  id: PropTypes.number.isRequired,
  channel: PropTypes.oneOf(['converse', 'embed'])
};

/**
 * Get the type of Message Composer to show, using the messages sent.
 * The last message sent from the bot that has a composer will be used.
 *
 * A couple of things to note, a subscribers message and a loading message both
 * don't have a composer set. This is to prevent flashing of a different composer
 * while the bot is thinking what to respond.
 */
const getComposerFromMessages = messages => {
  if (messages.length === 0) {
    return { type: 'loading' };
  }

  const lastMessageWithComposer = [...messages]
    .reverse()
    .find(message => Boolean(message.composer));

  if (!lastMessageWithComposer) {
    return { type: 'message' };
  }

  return lastMessageWithComposer.composer;
};

const allComposers = {
  ...composers,
  dynamic: DynamicComposer
};

const Composer = ({ channel = 'converse' }) => {
  const { messages } = useBot();
  const { setReply } = useComposer();

  const composer = getComposerFromMessages(messages);
  const ComposerComponent = allComposers[composer.type];
  const composerProps = formatContent(composer, channel);

  // Clear the input on every Component change.
  useEffect(() => {
    setReply('');
  }, [ComposerComponent, setReply]);

  return <ComposerComponent {...composerProps} />;
};

Composer.propTypes = {
  channel: DynamicComposer.propTypes.channel
};

export default Composer;
export { composers };
