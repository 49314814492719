import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useClickOutside } from '@ubisend/pulse-component-hooks';

import useTooltip, { POSITIONS } from '../../hooks/useTooltip';
import TooltipPopup from '../Tooltip/TooltipPopup';
import DefaultButton from '../Button/Button';
import Flex from '../Layout/Flex';

const ActionMenu = ({
  children,
  position = POSITIONS.BOTTOM,
  Button = DefaultButton,
  buttonProps = {}
}) => {
  const [visible, setVisible] = useState(false);

  const tooltip = useTooltip({
    visible,
    position,
    tooltip: children
  });

  useClickOutside(() => setVisible(false), { ref: tooltip.ref });

  const handleClick = () => {
    setVisible(visible => !visible);
  };

  return (
    <Flex relative left ref={tooltip.ref}>
      <Button
        variant="inline"
        onClick={handleClick}
        icon="dotsHorizontal"
        {...buttonProps}
      />
      <TooltipPopup {...tooltip.props} />
    </Flex>
  );
};

ActionMenu.propTypes = {
  position: PropTypes.oneOf(Object.values(POSITIONS)),
  Button: PropTypes.any,
  buttonProps: PropTypes.object
};
ActionMenu.POSITIONS = POSITIONS;

export default ActionMenu;
