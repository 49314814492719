import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  Explainer,
  useNotification,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCancel,
  ModalBody,
  Flex,
  Checkbox,
  Button
} from '@ubisend/pulse-components';

import { updateSmallTalkSettings } from '../api/smalltalk';

const SmallTalkSettings = ({ initialSettings, handleCancel }) => {
  const [settings, setSettings] = useState(initialSettings);

  const { showSuccess } = useNotification();
  const queryClient = useQueryClient();

  const mutation = useMutation(updateSmallTalkSettings, {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries('smalltalk/settings');
      const {
        data: { enabled }
      } = data;

      showSuccess(
        `Successfully ${enabled ? 'enabled' : 'disabled'} Small Talk`
      );
      handleCancel();
    }
  });

  const handleEnabledChange = () =>
    setSettings({ ...settings, enabled: !settings.enabled });

  const handleSubmit = event => {
    event.preventDefault();

    mutation.mutate({
      enabled: settings.enabled
    });
  };

  return (
    <ModalPortal>
      <form onSubmit={handleSubmit}>
        <ModalContainer>
          <ModalContent>
            <ModalHeader>Small Talk Settings</ModalHeader>
            <ModalBody>
              <Explainer>
                Disabling Small Talk will prevent your chatbot from using Small
                Talk responses in conversations.
              </Explainer>
              <Flex center between mb mt>
                <Checkbox
                  checked={Boolean(settings.enabled)}
                  onChange={handleEnabledChange}
                  label="Enable Small Talk"
                />
              </Flex>
            </ModalBody>
            <ModalFooter>
              <ModalCancel onClick={handleCancel}>Cancel</ModalCancel>
              <Button type="submit" icon="save">
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalContainer>
      </form>
    </ModalPortal>
  );
};

SmallTalkSettings.propTypes = {
  initialSettings: PropTypes.any.isRequired,
  handleCancel: PropTypes.func.isRequired
};

export default SmallTalkSettings;
