import React from 'react';
import PropTypes from 'prop-types';

import { Span } from '@ubisend/pulse-components';

const StandardScope = ({ content }) => {
  return <Span>{content.value}</Span>;
};

StandardScope.propTypes = {
  content: PropTypes.shape({
    value: PropTypes.any.isRequired
  }).isRequired
};

export default StandardScope;
