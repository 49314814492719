import React from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  useNotification,
  Flex,
  Button,
  LoadingContainer
} from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';

import { updateClient } from '../api/index';
import ClientDetails from './ClientDetails';
import { useClient } from '../hooks/index';

const EditClient = ({ client: clientToEdit }) => {
  const { client, handlers, valid } = useClient(clientToEdit);

  const { refetchAuth } = useAuth();
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(updateClient, {
    onSuccess: ({ data: { data } }) => {
      queryClient.invalidateQueries();

      refetchAuth();
      showSuccess(`Successfully updated client "${data.name}"`);
    }
  });

  const handleSubmit = event => {
    event.preventDefault();

    mutate({ id: client.id, ...client });
  };

  if (!client) {
    return <LoadingContainer />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <ClientDetails client={client} {...handlers} />
      <Flex right>
        <Button type="submit" loading={isLoading} disabled={!valid}>
          Save
        </Button>
      </Flex>
    </form>
  );
};

EditClient.propTypes = {
  client: PropTypes.any.isRequired
};

export default EditClient;
