import React, { useState, useEffect } from 'react';

import { AnimatePresence } from '@ubisend/framer-motion';
import {
  Button,
  PanelSlider,
  useNotification,
  useModal,
  Divider,
  Flex,
  Span
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { getLatestTrain, train } from '../api/index';
import TrainingHistory from './TrainingHistory';
import TrainingSettings from './TrainingSettings';
import { createTrainingMessage } from '../utils/index';

const Train = () => {
  const [latestTrain, setLatestTrain] = useState();
  const [showHistory, setShowHistory] = useState(false);

  const { showNotification } = useNotification();
  const { showModal, hideModal } = useModal();

  const fetchLatestTrain = async () => {
    const { data } = await getLatestTrain();
    setLatestTrain(data.data);
  };

  useEffect(() => {
    fetchLatestTrain();
  }, []);

  const handleTrain = () => {
    showModal({
      header: 'Train your chatbot',
      body: `Are you sure you want to train your chatbot? This may take a couple of minutes.`,
      handleConfirm: async () => {
        try {
          const { data } = await train();
          setLatestTrain(data.data);
          showNotification({
            message:
              'Your chatbot is now training, this may take a couple of minutes.'
          });
        } finally {
          hideModal();
        }
      }
    });
  };

  const handleHistoryView = () => {
    setShowHistory(true);
  };

  if (!latestTrain) {
    return null;
  }

  const isTraining = latestTrain.is_training;

  return (
    <Flex col right>
      <PermissionFilter can="create training history">
        <Flex right mbSm>
          <Button onClick={handleTrain} loading={isTraining} icon="chip">
            Train
          </Button>
        </Flex>
      </PermissionFilter>
      <PermissionFilter can="view training history">
        <Flex col right>
          {latestTrain.started_training_at && (
            <Span tinyText light textRight mtSm mbSm>
              {createTrainingMessage(latestTrain)}
            </Span>
          )}
          <Flex right>
            <Button
              onClick={handleHistoryView}
              variant="inline"
              style={{ padding: '0' }}>
              View history
            </Button>
          </Flex>
        </Flex>
      </PermissionFilter>
      <AnimatePresence>
        {showHistory && (
          <PanelSlider
            header="Training Settings"
            width="40vw"
            handleHide={() => setShowHistory(false)}>
            <PermissionFilter can="edit training settings">
              <TrainingSettings />
              <Divider />
            </PermissionFilter>
            <PermissionFilter can="view training history">
              <TrainingHistory />
            </PermissionFilter>
          </PanelSlider>
        )}
      </AnimatePresence>
    </Flex>
  );
};

export default Train;
