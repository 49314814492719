import React from 'react';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Placeholder,
  Flex,
  NoSubcriberGraphic,
  Heading3
} from '@ubisend/pulse-components';
import {
  Comments,
  SubscriberDetails,
  DetailsPanel,
  MainSection,
  NoSubscriber
} from '@ubisend/pulse-tickets';

import { LogContent } from '../Components/index';

const Log = ({ match }) => {
  const { isLoading, data } = useQuery(`logs/${match.params.id}`);

  return (
    <Flex>
      <MainSection>
        <PageWrapper header="Log Details">
          <Flex ySpace col>
            <LogContent log={data?.data} />
            <Comments query={`logs/${match.params.id}/comments`} />
          </Flex>
        </PageWrapper>
      </MainSection>
      <DetailsPanel divider header="Subscriber Details">
        {isLoading && <Placeholder ySpace />}
        {!isLoading && data.data.subscriber && (
          <SubscriberDetails subscriber={data.data.subscriber} />
        )}
        {!isLoading && !data.data.subscriber && (
          <NoSubscriber col center ySpace middle>
            <NoSubcriberGraphic />
            <Heading3>No Subscriber attached to this log</Heading3>
          </NoSubscriber>
        )}
      </DetailsPanel>
    </Flex>
  );
};

export default Log;
