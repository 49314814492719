import React from 'react';
import { Link } from 'react-router-dom';

import { useQueryClient, useQuery, useMutation } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  useNotification,
  NoResults,
  StretchPanel as Panel,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableActions,
  Button,
  useOrderableTableReducer,
  OrderableTableRow,
  usePaginationReducer,
  Pagination
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { deleteCustomMetric } from './api/index';
import { CustomMetricRouter } from './Routers/index';

const CustomMetrics = () => {
  const { showSuccess } = useNotification();

  const order = useOrderableTableReducer();
  const pagination = usePaginationReducer();

  const queryClient = useQueryClient();
  const query = useQuery([
    'custom-metrics',
    { ...order.params, ...pagination.params }
  ]);
  const mutation = useMutation(deleteCustomMetric, {
    onSuccess: () => {
      queryClient.invalidateQueries('custom-metrics');
      queryClient.invalidateQueries('custom-metrics/all');
      showSuccess('Successfully deleted metric');
    }
  });

  const handleDelete = id => {
    mutation.mutate(id);
  };

  return (
    <PageWrapper
      header="Custom metrics"
      subheader="Store custom metrics within conversations"
      actions={
        <PermissionFilter can="create custom metrics">
          <Button variant="secondary" as={Link} to="/custom-metrics/create">
            + Metric
          </Button>
        </PermissionFilter>
      }>
      <Panel>
        {query.showNoResultsMessage && (
          <NoResults subtitle="No metrics found, try creating one" />
        )}
        {query.showTable && (
          <>
            <Table loading={query.isLoading} loadingColumns={3}>
              <TableHead>
                <OrderableTableRow
                  rows={[
                    { label: 'Id', sort: 'id' },
                    { label: 'Name', sort: 'name' },
                    null
                  ]}
                  {...order.props}
                />
              </TableHead>
              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((metric, key) => (
                    <TableRow key={key}>
                      <TableCell>{metric.id}</TableCell>
                      <TableCell>{metric.name}</TableCell>
                      <TableActions>
                        <PermissionFilter can="edit custom metrics">
                          <Button
                            variant="secondary"
                            as={Link}
                            to={`/custom-metrics/${metric.id}`}
                            icon="pencilAlt">
                            Edit
                          </Button>
                        </PermissionFilter>
                        <PermissionFilter can="delete custom metrics">
                          <Button
                            disabled={mutation.isLoading}
                            onClick={() => handleDelete(metric.id)}
                            icon="trash"
                            colour="danger"
                            variant="secondary">
                            Delete
                          </Button>
                        </PermissionFilter>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </>
        )}
      </Panel>
      <CustomMetricRouter />
    </PageWrapper>
  );
};

export default CustomMetrics;
