import React, { useState } from 'react';
import dayjs from 'dayjs';

import {
  PageWrapper,
  StretchPanel as Panel,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableCell,
  Pagination,
  NoResults,
  Flex,
  Button,
  OrderableTableHeadCell,
  ActionMenu
} from '@ubisend/pulse-components';
import { useQuery } from '@ubisend/pulse-hooks';

import Filter from '../Components/Filter';
import SemanticExplore from '../Components/SemanticExplore';
import { tableMessage } from '../utils/index';
import { useFilters } from '../hooks/index';

const SemanticTraining = () => {
  const [explore, setExplore] = useState();

  const filters = useFilters();

  const query = useQuery(
    [
      'training/sections',
      {
        ...filters.filters,
        ...filters.pagination.params,
        ...filters.order.params
      }
    ],
    {
      refetchOnMount: 'always'
    }
  );

  return (
    <PageWrapper
      header="Explore Semantic Searches"
      subheader="View inbound semantic search queries"
      actions={
        <ActionMenu
          position={ActionMenu.POSITIONS.LEFT}
          buttonProps={{
            'aria-label': 'Toggle filter menu',
            variant: 'secondary',
            icon: 'filter',
            children: 'Filter'
          }}>
          <Filter {...filters.props} />
        </ActionMenu>
      }>
      <Panel mt>
        {query.showNoResultsMessage && (
          <NoResults text="No Semantic Search results to display" />
        )}
        {query.showTable && (
          <>
            <Table loading={query.isLoading} loadingColumns={6}>
              <TableHead>
                <TableRow>
                  <OrderableTableHeadCell
                    row={{ label: 'Message', sort: 'message' }}
                    {...filters.order.props}
                  />
                  <OrderableTableHeadCell
                    row={{ label: 'Section', sort: 'section' }}
                    {...filters.order.props}
                  />
                  <OrderableTableHeadCell
                    row={{ label: 'Confidence', sort: 'semantic_confidence' }}
                    {...filters.order.props}
                  />
                  <OrderableTableHeadCell
                    row={{ label: 'Sent on', sort: 'sent_at' }}
                    {...filters.order.props}
                  />
                  <TableHeadCell />
                </TableRow>
              </TableHead>
              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((message, key) => (
                    <TableRow key={key}>
                      <TableCell style={{ overflowWrap: 'anywhere' }}>
                        {tableMessage(
                          message.postback
                            ? message.postback
                            : message.message.text
                        )}
                      </TableCell>
                      <TableCell>
                        <Flex col>
                          {message.trained_section
                            ? message.trained_section.content.name
                            : 'N/A'}
                        </Flex>
                      </TableCell>
                      <TableCell>
                        {message.trained_section
                          ? `${(
                              message.trained_section.confidence * 100
                            ).toFixed(2)}%`
                          : `N/A`}
                      </TableCell>
                      <TableCell>
                        {dayjs(message.sent_at).format('D MMM YYYY HH:mm')}
                      </TableCell>
                      <TableCell>
                        <Button
                          icon="eye"
                          variant="secondary"
                          onClick={() => {
                            setExplore({
                              id: message.id,
                              otherSections: message.other_sections,
                              trainedSection: message.trained_section
                            });
                          }}>
                          Explore
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {query.showPagination && (
              <Pagination
                pagination={query.data.meta}
                {...filters.pagination.props}
              />
            )}
          </>
        )}
      </Panel>
      {explore && (
        <SemanticExplore
          message={explore}
          handleHide={() => setExplore(null)}
        />
      )}
    </PageWrapper>
  );
};

export default SemanticTraining;
