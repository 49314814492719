import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Flex,
  Button,
  FormGroup,
  Label,
  TextInput,
  Tooltip,
  useNotification
} from '@ubisend/pulse-components';
import { GroupSelect } from '@ubisend/pulse-groups';
import {
  LocationsConsumer,
  useMutation,
  useQueryClient
} from '@ubisend/pulse-hooks';

import LocationSelect from '../Locations/LocationSelect';
import SharedFaqLinks from './SharedFaqLinks';
import FaqSelect from './FaqSelect';
import { updateFaq as updateFaqApi, syncGroupsForFaq } from '../../api/index';

const EditFaq = ({ faq: initialFaq, handleCancel }) => {
  const queryClient = useQueryClient();
  const { showSuccess } = useNotification();

  const [faq, setFaq] = useState({
    ...initialFaq,
    groups: initialFaq.groups ? initialFaq.groups.map(group => group.id) : [],
    location: initialFaq.location ? initialFaq.location.id : null
  });

  const [sharedFaq, setSharedFaq] = useState(
    faq?.shared_faqs.length > 0 && faq.shared_faqs[0].id
  );

  const { mutate: updateFaq } = useMutation(updateFaqApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('faqs');
      showSuccess(`Successfully updated "${faq.name}"`);
      handleCancel();
    }
  });

  const { mutate: syncGroups } = useMutation(syncGroupsForFaq, {
    onSuccess: () => {
      handleCancel();
    }
  });

  const handleFaqNameChange = event => {
    setFaq({ ...faq, name: event.target.value });
  };

  const handleGroupSelect = groups => {
    setFaq({
      ...faq,
      groups: groups ? groups.map(group => group.value) : []
    });
  };

  const handleLocationSelect = location => {
    setFaq({
      ...faq,
      location: location ? location.value : null
    });
  };

  const handleAddSharedFaq = faqToShare => {
    setSharedFaq(faqToShare ? faqToShare.value : null);
  };

  const hasSharedFaqs = Boolean(faq.shared_faqs.length > 0);
  const hasLocation = Boolean(faq.location);
  const hasGroups = Boolean(faq.groups.length > 0);

  const handleSubmit = async () => {
    await updateFaq({
      faqId: faq.id,
      randomResponse: faq.should_use_random_response,
      newName: faq.name,
      locationId: hasLocation ? faq.location : null,
      groups: hasGroups ? faq.groups : null,
      sharedFaqId: sharedFaq
    });

    syncGroups({ faqId: faq.id, groupIds: faq.groups });
  };

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>Edit your FAQ</ModalHeader>
          <ModalBody>
            <Flex col>
              <FormGroup>
                <Label htmlFor="name">Name</Label>
                <TextInput
                  name="name"
                  value={faq.name}
                  onChange={handleFaqNameChange}
                  id="name"
                />
              </FormGroup>
              <LocationsConsumer>
                <FormGroup>
                  <Label htmlFor="location">Location</Label>
                  <LocationSelect
                    id="location"
                    value={hasLocation ? faq.location : null}
                    onChange={handleLocationSelect}
                    isCreatable={true}
                  />
                </FormGroup>
              </LocationsConsumer>
              <FormGroup>
                <Label htmlFor="tags">Tags</Label>
                <GroupSelect
                  id="tags"
                  value={faq.groups}
                  onChange={handleGroupSelect}
                  for="faqs"
                />
              </FormGroup>
              <LocationsConsumer>
                {hasSharedFaqs && (
                  <FormGroup>
                    <Flex>
                      <Label>Shares Questions With</Label>
                      <Tooltip
                        tooltip={
                          <Tooltip.Content>
                            Sharing one set of questions across multple
                            locations improves the chatbots accuracy.
                          </Tooltip.Content>
                        }>
                        <Tooltip.Icon>?</Tooltip.Icon>
                      </Tooltip>
                    </Flex>
                    <Flex>
                      <SharedFaqLinks {...faq} />
                    </Flex>
                  </FormGroup>
                )}
                {!hasSharedFaqs && (
                  <FormGroup>
                    <Label>Share questions with</Label>
                    <FaqSelect
                      exclude={faq.id}
                      value={sharedFaq}
                      onChange={handleAddSharedFaq}
                    />
                  </FormGroup>
                )}
              </LocationsConsumer>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex xSpace>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={handleSubmit} icon="save">
                Save
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

EditFaq.propTypes = {
  faq: PropTypes.any.isRequired,
  handleCancel: PropTypes.func.isRequired
};

export default EditFaq;
