import colorPicker from './colourPicker';

/**
 * Copied from https://github.com/JiHong88/SunEditor/blob/master/src/plugins/submenu/hiliteColor.js
 * and updated to work with table cells.
 */
const tableCellBackgroundColour = {
  name: 'tableCellBackgroundColour',
  display: 'submenu',
  title: 'Table cell background color',
  innerHTML:
    '<svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4 2a2 2 0 00-2 2v11a3 3 0 106 0V4a2 2 0 00-2-2H4zm1 14a1 1 0 100-2 1 1 0 000 2zm5-1.757l4.9-4.9a2 2 0 000-2.828L13.485 5.1a2 2 0 00-2.828 0L10 5.757v8.486zM16 18H9.071l6-6H16a2 2 0 012 2v2a2 2 0 01-2 2z" clip-rule="evenodd"></path></svg>',
  add: function (core, targetElement) {
    core.addModule([colorPicker]);

    const context = core.context;
    context.tableCellBackgroundColour = {
      previewEl: null,
      colorInput: null,
      colorList: null
    };

    /** set submenu */
    let listDiv = this.setSubmenu(core);
    context.tableCellBackgroundColour.colorInput = listDiv.querySelector(
      '._se_color_picker_input'
    );

    /** add event listeners */
    context.tableCellBackgroundColour.colorInput.addEventListener(
      'keyup',
      this.onChangeInput.bind(core)
    );
    listDiv
      .querySelector('._se_color_picker_submit')
      .addEventListener('click', this.submit.bind(core));
    listDiv
      .querySelector('._se_color_picker_remove')
      .addEventListener('click', this.remove.bind(core));
    listDiv.addEventListener('click', this.pickup.bind(core));

    context.tableCellBackgroundColour.colorList = listDiv.querySelectorAll(
      'li button'
    );

    /** append target button menu */
    core.initMenuTarget(this.name, targetElement, listDiv);

    /** empty memory */
    listDiv = null;
  },

  setSubmenu: function (core) {
    const colorArea = core.context.colorPicker.colorListHTML;
    const listDiv = core.util.createElement('DIV');

    listDiv.className = 'se-submenu se-list-layer';
    listDiv.innerHTML = colorArea;

    return listDiv;
  },

  /**
   * @Override submenu
   */
  on: function () {
    const contextPicker = this.context.colorPicker;
    const contextTableCellBackgroundColour = this.context
      .tableCellBackgroundColour;

    contextPicker._colorInput = contextTableCellBackgroundColour.colorInput;
    const color = this.wwComputedStyle.backgroundColor;
    contextPicker._defaultColor = color
      ? this.plugins.customColourPicker.isHexColor(color)
        ? color
        : this.plugins.customColourPicker.rgb2hex(color)
      : '#ffffff';
    contextPicker._styleProperty = 'backgroundColor';
    contextPicker._colorList = contextTableCellBackgroundColour.colorList;

    this.plugins.customColourPicker.init.call(
      this,
      this.getSelectionNode(),
      null
    );
  },

  /**
   * @Override _colorPicker
   */
  onChangeInput: function (e) {
    this.plugins.customColourPicker.setCurrentColor.call(this, e.target.value);
  },

  submit: function () {
    this.plugins.tableCellBackgroundColour.applyColor.call(
      this,
      this.context.colorPicker._currentColor
    );
  },

  pickup: function (e) {
    e.preventDefault();
    e.stopPropagation();

    this.plugins.tableCellBackgroundColour.applyColor.call(
      this,
      e.target.getAttribute('data-value')
    );
  },

  remove: function () {
    this.nodeChange(null, ['background-color'], ['span'], true);
    this.submenuOff();
  },

  getTableCell: function (node) {
    if (this.util.isCell(node)) {
      return node;
    }

    return this.util.getParentElement(node, this.util.isCell);
  },

  applyColor: function (color) {
    if (!color) {
      return;
    }

    const currentNode = this.getSelection()
      ? this.getSelection().anchorNode
      : null;

    if (!currentNode) {
      return;
    }

    const cellNode = this.plugins.tableCellBackgroundColour.getTableCell.call(
      this,
      currentNode
    );

    if (!cellNode) {
      return null;
    }

    cellNode.style.backgroundColor = color;
    this.nodeChange(null, ['background-color'], null, null);

    this.submenuOff();
  }
};

export default tableCellBackgroundColour;
