import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  Toggle,
  Flex,
  Heading3,
  Span,
  TextInput,
  InnerPanel,
  Label,
  Button,
  useNotification,
  Placeholder,
  Select,
  formatSelectOption
} from '@ubisend/pulse-components';

import { updateSettings as updateSettingsApi } from '../api/index';

const TicketAssignmentAlgorithmSelect = ({ value, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery(
    'tickets/ubidesk/assign-algorithms'
  );

  return (
    <Select
      value={
        isSuccess &&
        value &&
        formatSelectOption(data.data.find(algorithm => algorithm.id === value))
      }
      options={isSuccess ? data.data.map(formatSelectOption) : []}
      isLoading={isLoading}
      {...props}
    />
  );
};

TicketAssignmentAlgorithmSelect.propTypes = {
  value: PropTypes.number.isRequired
};

const LiveChatSettings = () => {
  const [settings, setSettings] = useState();

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const { isLoading } = useQuery(`live-chat/settings`, {
    onSuccess: ({ data }) => {
      if (!settings) {
        const { algorithm, ...settings } = data;

        setSettings({
          ...settings,
          ticket_assign_algorithm_id: algorithm.id
        });
      }
    }
  });
  const { mutate: updateSettings, isLoading: isUpdating } = useMutation(
    updateSettingsApi,
    {
      onSuccess: () => {
        showSuccess('Successfully updated live chat settings.');
        queryClient.invalidateQueries('live-chat/settings');
      }
    }
  );

  const toggleEnabled = () => {
    const newSettings = {
      ...settings,
      enabled: !settings.enabled
    };
    setSettings(newSettings);
    updateSettings(newSettings);
  };

  const toggleFollowUp = () => {
    const newSettings = {
      ...settings,
      has_follow_ups_enabled: !settings.has_follow_ups_enabled
    };
    setSettings(newSettings);
    updateSettings(newSettings);
  };

  const handleMaxChatsUpdate = event => {
    setSettings({
      ...settings,
      max_chats_per_agent: event.target.value ? event.target.value : ''
    });
  };

  const handleTicketAssignmentChange = option => {
    setSettings({
      ...settings,
      ticket_assign_algorithm_id: option.value
    });
  };

  const handleAgentSubmit = () => {
    updateSettings(settings);
  };

  return (
    <>
      <Flex>
        <Heading3>General</Heading3>
      </Flex>
      <InnerPanel>
        {isLoading && <Placeholder items={2} />}
        {!isLoading && settings && (
          <Flex col ySpace>
            <Flex between middle center xSpace>
              <Flex col>
                <Heading3>Live chat</Heading3>
                <Span>
                  When enabled, users can select live chat as a fallback option.
                </Span>
              </Flex>
              <Toggle
                title="live-chat"
                on={settings.enabled}
                handleToggleChange={toggleEnabled}
              />
            </Flex>
            <Flex between middle center xSpace>
              <Flex col>
                <Heading3>Follow up</Heading3>
                <Span>
                  When enabled, users can rate their live chat experience.
                </Span>
              </Flex>
              <Toggle
                title="follow-up"
                on={settings.has_follow_ups_enabled}
                handleToggleChange={toggleFollowUp}
              />
            </Flex>
          </Flex>
        )}
      </InnerPanel>
      <Flex>
        <Heading3>Agents</Heading3>
      </Flex>
      <InnerPanel>
        {isLoading && <Placeholder items="1" ySpace />}
        {!isLoading && settings && (
          <Flex col ySpace>
            <Flex col>
              <Label htmlFor="max_chats_per_agent">
                Maximum concurrent live chats
              </Label>
              <TextInput
                name="max_chats_per_agent"
                id="max_chats_per_agent"
                type="number"
                placeholder="3"
                value={settings.max_chats_per_agent}
                onChange={handleMaxChatsUpdate}
              />
            </Flex>
            <Flex col>
              <Label htmlFor="algorithm">Assignment algorithm</Label>
              <TicketAssignmentAlgorithmSelect
                id="algorithm"
                value={settings.ticket_assign_algorithm_id}
                onChange={handleTicketAssignmentChange}
              />
            </Flex>
            <Flex right>
              <Button
                variant="secondary"
                icon="save"
                onClick={handleAgentSubmit}
                loading={isUpdating}>
                Save
              </Button>
            </Flex>
          </Flex>
        )}
      </InnerPanel>
    </>
  );
};

export default LiveChatSettings;
