import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Breadcrumbs from './Breadcrumbs';
import { useRouteEffect } from '../hooks/index';

const RenderedRoute = ({ route, ...props }) => {
  const Wrapper = route.wrapper || React.Fragment;

  useRouteEffect();

  const showBreadCrumbs = !route.disableBreadCrumbs ?? true;

  return (
    <Wrapper>
      <Helmet>
        <title>{route.name} | Chatbot Management System</title>
      </Helmet>
      {showBreadCrumbs && <Breadcrumbs match={props.match} />}
      <route.component {...props} />
    </Wrapper>
  );
};

RenderedRoute.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    wrapper: PropTypes.any,
    public: PropTypes.bool,
    disableBreadCrumbs: PropTypes.bool
  }).isRequired
};

export default RenderedRoute;
