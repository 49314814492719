import React from 'react';
import PropTypes from 'prop-types';

import {
  showCondition,
  hasNumberCondition,
  hasGroupCondition,
  hasDateTimeCondition,
  hasTimeCondition
} from '../../utils/index';
import GroupCondition from './GroupCondition';
import NumberCondition from './NumberCondition';
import TextCondition from './TextCondition';
import DateTimeCondition from './DateTimeCondition';
import TimeCondition from './TimeCondition';

const MessageCondition = ({ name, ...props }) => {
  if (hasDateTimeCondition(name)) {
    return (
      <DateTimeCondition aria-label="date-time-conditional-picker" {...props} />
    );
  }

  if (hasTimeCondition(name)) {
    return <TimeCondition aria-label="time-conditional-picker" {...props} />;
  }

  if (hasNumberCondition(name)) {
    return <NumberCondition aria-label="number-conditional-input" {...props} />;
  }

  if (hasGroupCondition(name)) {
    return <GroupCondition aria-label="group-conditional-input" {...props} />;
  }

  if (!showCondition(name)) {
    return null;
  }

  return <TextCondition aria-label="text-conditional-input" {...props} />;
};

MessageCondition.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default MessageCondition;
