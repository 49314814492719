import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { AnimatePresence } from '@ubisend/framer-motion';
import {
  PageWrapper,
  Flex,
  Button,
  StretchPanel,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableActions,
  useNotification,
  Pagination,
  Tag,
  OrderableTableRow,
  ActionMenu
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';
import {
  BetaFlag,
  useQuery,
  useMutation,
  useQueryClient
} from '@ubisend/pulse-hooks';

import {
  CreateTicketButton,
  TicketStatus,
  NoTickets,
  SubscriberLink,
  TicketCreatedAt,
  TicketGroupTableCell,
  TicketNameTableCell,
  TicketFilters,
  TicketSettings
} from '../Components/index';
import { archiveTicket } from '../api/index';
import { useTicketFilters } from '../hooks/index';

const Tickets = () => {
  const [showSettings, setShowSettings] = useState(false);

  const { form, props, pagination, order } = useTicketFilters({
    ticket_types: [],
    statuses: ['submitted', 'in_progress', 'resolved'],
    assignee_ids: []
  });

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const query = useQuery(['tickets/ubidesk', form]);
  const { mutate } = useMutation(archiveTicket, {
    onSuccess: () => {
      showSuccess('Successfully archived ticket');
      queryClient.invalidateQueries('tickets/ubidesk');
    }
  });

  const handleArchive = ticket => () => {
    mutate(ticket.id);
  };

  return (
    <PageWrapper
      header="Tickets"
      subheader="View and manage your tickets"
      actions={
        <Flex xSpace>
          <PermissionFilter can="create tickets">
            <CreateTicketButton>Create New</CreateTicketButton>
          </PermissionFilter>
          <ActionMenu
            buttonProps={{
              'aria-label': 'Toggle filter menu',
              variant: 'secondary',
              icon: 'filter',
              children: 'Filter',
              loading: query.isLoading
            }}>
            <TicketFilters {...props} />
          </ActionMenu>
          <Button
            as={Link}
            variant="secondary"
            to={`/tickets/archived`}
            icon="archive">
            Archived
          </Button>
          <Button
            as={Link}
            variant="secondary"
            to={`/tickets/bot`}
            icon="ticket">
            Bot tickets
          </Button>
          <PermissionFilter can="view ticket settings">
            <BetaFlag feature="ticket settings">
              <Button
                icon="cog"
                variant="secondary"
                onClick={() => setShowSettings(settings => !settings)}>
                Settings
              </Button>
            </BetaFlag>
          </PermissionFilter>
        </Flex>
      }>
      {query.showNoResultsMessage && (
        <NoTickets>
          <Flex col center ySpace>
            <h1>When you receive or create tickets they will appear here</h1>
            <PermissionFilter can="create tickets">
              <CreateTicketButton>Create New</CreateTicketButton>
            </PermissionFilter>
          </Flex>
        </NoTickets>
      )}
      {query.showTable && (
        <>
          <StretchPanel>
            <Table loading={query.isLoading} loadingColumns={6}>
              <TableHead>
                <OrderableTableRow
                  rows={[
                    { label: 'Summary', sort: 'name' },
                    { label: 'Tags', sort: null },
                    { label: 'Status', sort: 'status' },
                    { label: 'Subscriber', sort: null },
                    { label: 'Date', sort: 'created_at' },
                    null
                  ]}
                  {...order.props}
                />
              </TableHead>
              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((ticket, key) => (
                    <TableRow key={key}>
                      <TicketNameTableCell>{ticket.name}</TicketNameTableCell>
                      <TicketGroupTableCell>
                        <Flex xSpaceSm xScroll>
                          {ticket.groups.length > 0
                            ? ticket.groups.map(group => (
                                <Tag key={group.id}>{group.name}</Tag>
                              ))
                            : 'N/A'}
                        </Flex>
                      </TicketGroupTableCell>
                      <TableCell>
                        <TicketStatus>{ticket.status}</TicketStatus>
                      </TableCell>
                      <TableCell>
                        <SubscriberLink subscriber={ticket.subscriber} />
                      </TableCell>
                      <TableCell>
                        <TicketCreatedAt date={ticket.created_at} />
                      </TableCell>
                      <TableActions>
                        <Button
                          as={Link}
                          variant="secondary"
                          to={`/tickets/${ticket.id}`}
                          icon="eye">
                          View
                        </Button>
                        <PermissionFilter can="delete tickets">
                          <Button
                            variant="secondary"
                            onClick={handleArchive(ticket)}
                            icon="lockClosed">
                            Archive
                          </Button>
                        </PermissionFilter>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </StretchPanel>
        </>
      )}
      <AnimatePresence>
        {showSettings && (
          <TicketSettings closeSettings={() => setShowSettings(false)} />
        )}
      </AnimatePresence>
    </PageWrapper>
  );
};

export default Tickets;
