import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MemoryRouter, Route, Switch } from 'react-router-dom';

import {
  Messages,
  Notifications,
  Tickets,
  History,
  Settings
} from './Screens/index';
import { WindowHeader, Background } from './Components/index';
import { GroupProvider } from './Providers/index';

const Container = styled.div`
  ${tw`w-full h-full pin-t absolute text-white overflow-x-hidden`}
`;

const GroupContainer = styled.div`
  ${tw`flex justify-between h-full flex-col-reverse flex-grow md:flex-row`}
`;

const MessageContainer = styled.div`
  ${tw`w-full h-full`}
  & > div:first-child {
    ${tw`px-4`}
  }
  box-sizing: border-box;
`;

const FullPage = ({ start = '/window/body/messages' }) => {
  return (
    <MemoryRouter initialEntries={[start]}>
      <Background />
      <Container>
        <Switch>
          <Route path="/window">
            <Route path="/window/body">
              <GroupProvider>
                <GroupContainer>
                  <MessageContainer>
                    <WindowHeader />
                    <Route path="/window/body/messages" component={Messages} />
                    <Route
                      exact
                      path="/window/body/notifications"
                      component={Notifications}
                    />
                    <Route
                      exact
                      path="/window/body/tickets"
                      component={Tickets}
                    />
                    <Route
                      exact
                      path="/window/body/settings"
                      component={Settings}
                    />
                    <Route
                      exact
                      path="/window/body/history"
                      component={History}
                    />
                  </MessageContainer>
                  <div id="content-message-slide-in" />
                </GroupContainer>
              </GroupProvider>
            </Route>
          </Route>
        </Switch>
      </Container>
    </MemoryRouter>
  );
};

FullPage.propTypes = {
  start: PropTypes.string
};

export default FullPage;
