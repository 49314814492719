import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  useComposer,
  useDelivery,
  useUploadFiles,
  useSendFiles,
  useDelayedBot,
  useTyping
} from '../../../../../hooks/index';
import {
  isValidMessage,
  formatClientMessage,
  formatServerMessage
} from '../../../../../utilities/index';
import {
  MAX_MESSAGE_LENGTH,
  MIN_MESSAGE_LIMIT,
  MAX_FILE_COUNT,
  COMBINED_MAX_FILE_BYTES
} from '../../../../../constants';
import BaseComposer from '../BaseComposer';

const isValid = message => {
  return isValidMessage(message, {
    min: MIN_MESSAGE_LIMIT,
    max: MAX_MESSAGE_LENGTH
  });
};

const MessageComposer = ({ content: passedContent }) => {
  const [notification, setNotification] = useState(null);

  const { reply, setReply } = useComposer();
  const { sendMessage } = useDelivery();
  const {
    setMessages,
    setQueuedMessages,
    showBurgerIcon,
    setShowBurgerMenu
  } = useDelayedBot();
  const typing = useTyping();

  const { t } = useTranslation('bots');

  const sendFileMutation = useSendFiles({
    onSuccess: ({ data }) => {
      if (data.messages.length > 0) {
        setQueuedMessages(messages => {
          return messages.concat(data.messages.map(formatServerMessage));
        });
      }
    },
    onError: () => {
      typing.dispatch({ type: typing.TYPES.STOP_TYPING });
      setNotification({
        type: BaseComposer.Notification.NOTIFICATION_TYPES.ERROR,
        message: t('generic_file_upload_error_message')
      });
    }
  });
  const uploadFileMutation = useUploadFiles({
    onSuccess: files => {
      setMessages(messages => {
        return messages.concat(
          files.map(file => {
            return formatClientMessage({
              type: 'file',
              content: { url: file.base64, text: file.file.name }
            });
          })
        );
      });

      sendFileMutation.mutate({
        files: files.map(file => file.path)
      });
    },
    onError: () => {
      // TODO
    }
  });

  const content = passedContent || {
    placeholder: t('message_composer_label'),
    disabled: false,
    show_file_upload: false
  };

  const handleOnChange = event => {
    const value = event.target.value;

    if (value.length >= MAX_MESSAGE_LENGTH) {
      return;
    }

    setReply(value);
  };

  const handleSubmit = () => {
    const validatedMessage = isValid(reply);

    if (!validatedMessage) {
      return;
    }

    sendMessage(validatedMessage);
    setReply('');
  };

  const handleFileSelect = event => {
    if (event.target.files.length > MAX_FILE_COUNT) {
      return setNotification({
        type: BaseComposer.Notification.NOTIFICATION_TYPES.WARNING,
        message: t('file_count_exceeded_error_message')
      });
    }

    const combinedFileSize = [...event.target.files].reduce((size, file) => {
      return size + file.size;
    }, 0);

    if (combinedFileSize > COMBINED_MAX_FILE_BYTES) {
      return setNotification({
        type: BaseComposer.Notification.NOTIFICATION_TYPES.WARNING,
        message: t('file_size_exceeded_error_message')
      });
    }

    uploadFileMutation.mutate(event.target.files);
  };

  const toggleBurgerMenu = () => {
    setShowBurgerMenu(open => !open);
  };

  const handleNotificationClose = () => {
    setNotification(null);
  };

  return (
    <BaseComposer
      value={reply}
      onChange={handleOnChange}
      handleSubmit={handleSubmit}
      showBurgerIcon={showBurgerIcon}
      toggleBurgerMenu={toggleBurgerMenu}
      showFileUpload={content.show_file_upload}
      disableFileUpload={
        uploadFileMutation.isDisabled ||
        sendFileMutation.isLoading ||
        uploadFileMutation.isLoading
      }
      handleFileSelect={handleFileSelect}
      aria-label={content.placeholder}
      placeholder={content.placeholder}
      disabled={content.disabled}
      notification={notification}
      handleNotificationClose={handleNotificationClose}
    />
  );
};

MessageComposer.propTypes = {
  content: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    show_file_upload: PropTypes.bool.isRequired
  })
};

export default MessageComposer;
