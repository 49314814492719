import axios from 'axios';

const createStepTransition = (stepId, transition, response = {}) => {
  return axios.post(`conversations/builder/steps/${stepId}/transitions`, {
    ...transition,
    ...response
  });
};

const updateStepTransition = (stepId, transitionId, transition) => {
  return axios.put(
    `conversations/builder/steps/${stepId}/transitions/${transitionId}`,
    transition
  );
};

const deleteStepTransition = (stepId, transitionId) => {
  return axios.delete(
    `conversations/builder/steps/${stepId}/transitions/${transitionId}`
  );
};

const updateTransitionResponse = (stepId, transitionId, response) => {
  return axios.put(
    `conversations/builder/steps/${stepId}/transitions/${transitionId}/responses`,
    response
  );
};

export {
  createStepTransition,
  updateStepTransition,
  deleteStepTransition,
  updateTransitionResponse
};
