import FuzzyInput from './FuzzyInput';

const fuzzy = {
  id: 'fuzzy',
  name: 'Fuzzy',
  Input: FuzzyInput,
  content: {
    items: [],
    placeholder: 'Choose an option...',
    disabled: false,
    multi: false
  },
  valid: content => {
    return Boolean(
      content.placeholder &&
        content.items.length > 0 &&
        content.items.every(item => item.label && item.value)
    );
  },
  snippet: `{
    "type": "fuzzy",
    "content": {
      "placeholder": "Choose an option...",
      "disabled": false,
      "multi": false,
      "items": [
        {"label": "", "value": ""}
      ]
    } 
}`
};

export default fuzzy;
