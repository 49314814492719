import React, { useState } from 'react';
import dayjs from 'dayjs';

import {
  DeliveryContext,
  ThemeProvider,
  BotContext
} from '@ubisend/pulse-volt';
import { SubscriptionContext } from '@ubisend/pulse-subscribe';

import { useBotSettings } from '../hooks/index';

const noop = () => undefined;

const messages = [
  {
    content: {
      text: 'An example message from your chatbot'
    },
    direction: 'toClient',
    type: 'standard',
    sentAt: dayjs()
  },
  {
    content: {
      text: "An example message from your chatbot's user"
    },
    direction: 'toServer',
    type: 'standard',
    sentAt: dayjs()
  },
  {
    content: {
      text: 'An example quick reply message from your chatbot',
      responses: [{ label: 'One' }, { label: 'Two' }, { label: 'Three' }]
    },
    direction: 'toClient',
    type: 'quick-reply',
    sentAt: dayjs()
  }
];

const BotProvider = ({ children, ...props }) => {
  const {
    settings,
    name,
    bannerTitle,
    bannerSubtitle,
    buttons
  } = useBotSettings();

  const [showBurgerMenu, setShowBurgerMenu] = useState(false);

  return (
    <SubscriptionContext.Provider value={{ socket: null, connected: true }}>
      <DeliveryContext.Provider
        value={{
          sendMessage: () => null,
          resubscribe: () => null,
          channel: null,
          setChannel: null,
          api_url: null,
          hasChannel: true
        }}>
        <ThemeProvider value={{ logo: settings.logo, ...settings.styles }}>
          <BotContext.Provider
            value={{
              messages,
              showName: settings.name_enabled,
              name,
              logo: settings.logo,
              showLogo: settings.logo_enabled,
              logoLink: settings.logo_link,
              title: bannerTitle,
              subtitle: bannerSubtitle,
              initialShowCta: settings.call_to_action_enabled,
              showCta: settings.call_to_action_enabled,
              setShowCta: () => {},
              ctaText: 'This is what a call to action looks like',
              showAvatars: settings.avatars_enabled,
              humanAvatarUrl: settings.human_avatar_url,
              botAvatarUrl: settings.bot_avatar_url,
              showClose: true,
              showBurgerIcon: buttons.length > 0,
              showBurgerMenu,
              buttons,
              setShowBurgerMenu,
              showReset: settings.reset_enabled,
              showChangeLanguage: settings.change_subscriber_language_enabled,
              showExportTranscript: settings.export_transcript_enabled,
              showExportSubscriber: settings.export_subscriber_enabled,
              showDeleteSubscriber: settings.delete_subscriber_enabled,
              onWidgetShow: noop,
              onWidgetOpen: noop,
              onWidgetClose: noop,
              onCtaHide: noop,
              onLightboxOpen: noop,
              onLightboxClose: noop,
              ...props
            }}>
            {children}
          </BotContext.Provider>
        </ThemeProvider>
      </DeliveryContext.Provider>
    </SubscriptionContext.Provider>
  );
};

export default BotProvider;
