import React from 'react';
import PropTypes from 'prop-types';

import {
  SetLocationInput,
  SetLanguageInput,
  CreateTicketInput,
  NoInput,
  LeaveFeedbackInput,
  ExportAllImportsInput,
  ExportImportInput,
  ExportSectionInput,
  DeleteSubscriberInput,
  ExportSubscriberInput,
  ExportTranscriptInput
} from './Actions/index';

const actions = {
  'Set subscriber location': SetLocationInput,
  'Set subscriber language': SetLanguageInput,
  'Create ticket': CreateTicketInput,
  'Create live chat ticket': CreateTicketInput,
  'Leave feedback': LeaveFeedbackInput,
  'Export subscriber': ExportSubscriberInput,
  'Export transcript': ExportTranscriptInput,
  'Delete subscriber': DeleteSubscriberInput,
  'Export all knowledge bases': ExportAllImportsInput,
  'Export single knowledge base': ExportImportInput,
  'Export knowledge base section': ExportSectionInput
};

const ActionContent = ({ name, ...props }) => {
  const Content = actions[name] || NoInput;

  return <Content {...props} />;
};

ActionContent.propTypes = {
  name: PropTypes.oneOf(Object.keys(actions))
};

export default ActionContent;
