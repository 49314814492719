import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { breakpoints, Flex } from '@ubisend/pulse-components';
import { motion } from '@ubisend/framer-motion';
import { hexToRgba } from '@ubisend/pulse-component-hooks';

import {
  useComposer,
  useDelivery,
  useUploadFiles,
  useSendFiles,
  useDelayedBot
} from '../../../../../hooks/index';
import {
  isValidMessage,
  formatClientMessage,
  formatServerMessage
} from '../../../../../utilities/index';
import {
  MAX_MESSAGE_LENGTH,
  MIN_MESSAGE_LIMIT,
  MAX_FILE_COUNT,
  COMBINED_MAX_FILE_BYTES
} from '../../../../../constants';
import { UploadFileInput } from '../../../../../Components/index';

const isValid = message => {
  return isValidMessage(message, {
    min: MIN_MESSAGE_LIMIT,
    max: MAX_MESSAGE_LENGTH
  });
};

const Airplane = () => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
  </svg>
);

const PaperClip = () => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg">
    <path
      className="secondary"
      fillRule="evenodd"
      d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
      clipRule="evenodd"
    />
  </svg>
);

const Input = styled(motion.input)`
  ${tw`border-0 outline-none text-base mt-2 w-full`}

  &:disabled,
  &[disabled] {
    ${tw`cursor-not-allowed opacity-0`}
  }
`;

const Container = styled(motion.div)`
  ${tw`w-full flex bg-white p-2 fixed md:relative pin-b pin-r md:shadow-lg items-start`}
  box-sizing: border-box;
  margin-left: -1rem;
  @media (min-width: ${breakpoints.md}px) {
    border-radius: 1rem;
    margin-left: 0;
  }
`;

const ComposerButton = styled(motion.button)`
  ${tw`flex p-4 border-0 rounded cursor-pointer`}
  transition: filter 0.4s ease, opacity 0.4s ease;
  background: ${({ theme }) => hexToRgba(theme.bot.gradient.from, 0.25)};

  &:disabled,
  &[disabled] {
    filter: grayscale(100%);
    ${tw`cursor-not-allowed opacity-50`}
  }

  & > svg {
    ${tw`w-6 h-6`}
    fill: ${({ theme }) => theme.bot.gradient.from};
    ${({ rotate = true }) => rotate && 'transform: rotate(90deg);'}
  }
`;

const LeftSection = styled(motion.div)`
  ${tw`w-full px-3`}
`;

const Label = styled(motion.label)`
  ${tw`block text-black text-xs mt-1 z-10`}
  transform-origin: left;
  pointer-events: none;
`;

const labelAnimations = {
  expanded: { scale: 1.25, y: 16 },
  minimised: { scale: 1, y: 0 }
};

const MessageComposer = ({ content: passedContent }) => {
  const [inputActive, setInputActive] = useState(false);

  const { t } = useTranslation('full_page');

  const content = passedContent || {
    placeholder: t('message_composer_label'),
    disabled: false,
    show_file_upload: false
  };

  const { reply, setReply } = useComposer();
  const { sendMessage } = useDelivery();
  const { setMessages } = useDelayedBot();

  const sendFileMutation = useSendFiles({
    onSuccess: ({ data }) => {
      if (data.messages.length > 0) {
        setMessages(messages => {
          return messages.concat(data.messages.map(formatServerMessage));
        });
      }
    },
    onError: () => {
      // TODO
    }
  });
  const uploadFileMutation = useUploadFiles({
    onSuccess: files => {
      setMessages(messages => {
        return messages.concat(
          files.map(file => {
            return formatClientMessage({
              type: 'file',
              content: { url: file.base64, text: file.file.name }
            });
          })
        );
      });

      sendFileMutation.mutate({
        files: files.map(file => file.path)
      });
    },
    onError: () => {
      // TODO
    }
  });

  const handleOnChange = event => {
    const value = event.target.value;

    if (value.length >= MAX_MESSAGE_LENGTH) {
      return;
    }

    setReply(value);
  };

  const handleNewReply = () => {
    const validatedMessage = isValid(reply);

    if (!validatedMessage) {
      return;
    }

    sendMessage(validatedMessage);
    setReply('');
  };

  const handleOnKeyDown = event => {
    if (event.keyCode === 13) {
      handleNewReply();
    }
  };

  const handleBlur = () => {
    if (inputActive && reply.length === 0) {
      setInputActive(false);
    }
  };

  const handleFocus = () => {
    if (!inputActive) {
      setInputActive(true);
    }
  };

  const handleFileSelect = event => {
    if (event.target.files.length > MAX_FILE_COUNT) {
      // TODO
      return;
    }

    const combinedFileSize = [...event.target.files].reduce((size, file) => {
      return size + file.size;
    }, 0);

    if (combinedFileSize > COMBINED_MAX_FILE_BYTES) {
      // TODO
      return;
    }

    uploadFileMutation.mutate(event.target.files);
  };

  return (
    <Container>
      <LeftSection>
        <Label
          htmlFor="message-composer"
          initial="expanded"
          variants={labelAnimations}
          animate={inputActive ? 'minimised' : 'expanded'}>
          {content.placeholder}
        </Label>
        <Input
          id="message-composer"
          value={reply}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          disabled={content.disabled}
        />
      </LeftSection>
      <Flex xSpaceSm>
        {content.show_file_upload && (
          <>
            <UploadFileInput
              id="file"
              disabled={false}
              onChange={handleFileSelect}
            />
            <ComposerButton as="label" htmlFor="file" rotate={false}>
              <PaperClip />
            </ComposerButton>
          </>
        )}
        <ComposerButton
          aria-label={t('send_button_label')}
          disabled={!isValid(reply)}
          onClick={handleNewReply}>
          <Airplane />
        </ComposerButton>
      </Flex>
    </Container>
  );
};

MessageComposer.propTypes = {
  content: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    show_file_upload: PropTypes.bool
  }),
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
};

export default MessageComposer;
export {
  Container,
  LeftSection,
  ComposerButton,
  Airplane,
  Input,
  Label,
  labelAnimations
};
