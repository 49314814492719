import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Flex,
  Button,
  StretchPanel,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableActions,
  useNotification,
  Pagination,
  Label,
  NoResults,
  usePaginationReducer,
  OrderableTableRow,
  useOrderableTableReducer,
  ActionMenu
} from '@ubisend/pulse-components';
import {
  SubscriberLink,
  TicketCreatedAt,
  TicketNameTableCell
} from '@ubisend/pulse-tickets';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { deleteLog } from '../api/index';
import { LogName, LogLevelSelect, LogTypeSelect } from '../Components/index';

const Logs = () => {
  const order = useOrderableTableReducer();
  const pagination = usePaginationReducer();

  const [levels, setLevels] = useState([]);
  const [types, setTypes] = useState([]);

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();

  const query = useQuery([
    'logs',
    { ...pagination.params, ...order.params, types, levels }
  ]);

  const { mutate } = useMutation(deleteLog, {
    onSuccess: () => {
      showSuccess('Successfully deleted log');
      queryClient.invalidateQueries('logs');
    }
  });

  const handleDelete = log => () => {
    mutate(log.id);
  };

  const handleTypeChange = options => {
    pagination.resetPage();
    setTypes(options ? options.map(option => option.value) : []);
  };

  const handleLevelChange = options => {
    pagination.resetPage();
    setLevels(options ? options.map(option => option.value) : []);
  };

  return (
    <PageWrapper
      header="Logs"
      subheader="View logs"
      actions={
        <ActionMenu
          position={ActionMenu.POSITIONS.LEFT}
          buttonProps={{
            'aria-label': 'Toggle filter menu',
            variant: 'secondary',
            icon: 'filter',
            children: 'Filter',
            loading: query.isLoading
          }}>
          <Flex fat between col ySpace pad style={{ width: '20rem' }}>
            <Flex col fat>
              <Label htmlFor="types">Types</Label>
              <LogTypeSelect
                id="types"
                value={types}
                onChange={handleTypeChange}
              />
            </Flex>
            <Flex col fat>
              <Label htmlFor="levels">Levels</Label>
              <LogLevelSelect
                id="levels"
                value={levels}
                onChange={handleLevelChange}
              />
            </Flex>
          </Flex>
        </ActionMenu>
      }>
      <>
        {query.showNoResultsMessage && (
          <StretchPanel>
            <NoResults
              text="No Logs found"
              subtitle="When a log is created it will appear here"
            />
          </StretchPanel>
        )}
        {query.showTable && (
          <StretchPanel>
            <Table loading={query.isLoading} loadingColumns={4}>
              <TableHead>
                <OrderableTableRow
                  rows={[
                    { label: 'Name', sort: 'name' },
                    { label: 'Subscriber', sort: null },
                    { label: 'Date', sort: 'created_at' },
                    null
                  ]}
                  {...order.props}
                />
              </TableHead>
              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((log, key) => (
                    <TableRow key={key}>
                      <TicketNameTableCell>
                        <LogName {...log} />
                      </TicketNameTableCell>
                      <TableCell>
                        <SubscriberLink subscriber={log.subscriber} />
                      </TableCell>
                      <TableCell>
                        <TicketCreatedAt date={log.created_at} />
                      </TableCell>
                      <TableActions>
                        <Button
                          as={Link}
                          variant="secondary"
                          icon="eye"
                          to={`/logs/${log.id}`}>
                          View
                        </Button>
                        <PermissionFilter can="delete logs">
                          <Button
                            variant="secondary"
                            colour="danger"
                            icon="trash"
                            onClick={handleDelete(log)}>
                            Delete
                          </Button>
                        </PermissionFilter>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </StretchPanel>
        )}
      </>
    </PageWrapper>
  );
};

export default Logs;
