import { useEffect, useCallback, useState } from 'react';
import i18nCore from 'i18next';
import { initReactI18next } from 'react-i18next';

import format from '../format';
import translations from '../translations';

const useI18nInstance = (initialLanguage, { namespaces }) => {
  const [instance, setInstance] = useState(null);

  const initialiseI18n = useCallback(async () => {
    const instance = i18nCore.createInstance();

    await instance.use(initReactI18next).init({
      // General config
      debug: process.env.NODE_ENV === 'development',
      initImmediate: false,

      // React config
      interpolation: { escapeValue: false },
      react: { useSuspense: false },

      // Languages
      lng: initialLanguage,
      fallbackLng: null,

      // Namespaces
      ns: namespaces,
      defaultNS: null,

      // Translations
      // Use translation keys in testing.
      resources: process.env.NODE_ENV === 'test' ? {} : format(translations)
    });

    setInstance(instance);
  }, [initialLanguage, namespaces]);

  useEffect(() => {
    if (!instance) {
      initialiseI18n();
    }
  }, [instance, initialiseI18n]);

  return instance;
};

export default useI18nInstance;
