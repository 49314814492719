import { useReducer, useMemo, useRef, useCallback } from 'react';

const TYPES = {
  // Value
  UPDATE_VALUE: 'UPDATE_VALUE',
  ADD_SNIPPET: 'ADD_SNIPPET',
  // Variables
  SHOW_VARIABLES: 'SHOW_VARIABLES',
  HIDE_VARIABLES: 'HIDE_VARIABLES',
  ADD_VARIABLE: 'ADD_VARIABLE',

  // Cursor
  UPDATE_CURSOR: 'UPDATE_CURSOR',
  // Validatiton
  UPDATE_ERROR: 'UPDATE_ERROR'
};

const VARIABLE_OPEN_TAG = '{{';
const VARIABLE_CLOSE_TAG = '}}';

const reducer = (state, { type, refs, ...params }) => {
  switch (type) {
    // Value
    case TYPES.UPDATE_VALUE: {
      refs.wasInternalUpdate.current = true;

      if (params.cursor) {
        refs.cursor.current = params.cursor;
      }

      const value = params.value || '';

      return {
        ...state,
        value,
        showVariables: params.cursor
          ? value.slice(params.cursor - 2, params.cursor) ===
              VARIABLE_OPEN_TAG && value.length > state.value.length
          : state.showVariables
      };
    }
    case TYPES.ADD_SNIPPET: {
      refs.wasInternalUpdate.current = true;

      const before = state.value.slice(0, refs.cursor.current);
      const after = state.value.slice(refs.cursor.current);

      refs.cursor.current = before.length + params.snippet.length;
      refs.justAddedSnippet.current = true;

      return {
        ...state,
        value: `${before}${params.snippet}${after}`
      };
    }

    // Variables
    case TYPES.SHOW_VARIABLES: {
      refs.wasInternalUpdate.current = true;

      const before = state.value.slice(0, refs.cursor.current);
      const after = state.value.slice(refs.cursor.current);

      refs.cursor.current = before.length + VARIABLE_OPEN_TAG.length;

      return {
        ...state,
        value: `${before}${VARIABLE_OPEN_TAG}${after}`,
        showVariables: true
      };
    }
    case TYPES.HIDE_VARIABLES: {
      const before = state.value.slice(
        0,
        refs.cursor.current - VARIABLE_OPEN_TAG.length
      );
      const after = state.value.slice(refs.cursor.current);

      refs.cursor.current = refs.cursor.current - VARIABLE_OPEN_TAG.length;
      refs.justClosedVariables.current = true;
      refs.wasInternalUpdate.current = true;

      return {
        ...state,
        value: `${before}${after}`,
        showVariables: false
      };
    }
    case TYPES.ADD_VARIABLE: {
      const before = state.value.slice(0, refs.cursor.current);
      const after = state.value.slice(refs.cursor.current);

      // Set cursor after their new variable.
      refs.cursor.current =
        before.length + params.variable.length + VARIABLE_CLOSE_TAG.length;
      refs.justClosedVariables.current = true;
      refs.wasInternalUpdate.current = true;

      return {
        ...state,
        value: `${before}${params.variable}}}${after}`,
        showVariables: false
      };
    }

    // Cursor
    case TYPES.UPDATE_CURSOR: {
      refs.cursor.current = params.cursor;

      return state;
    }

    // Validation
    case TYPES.UPDATE_ERROR:
      return { ...state, error: params.error };

    // Errors
    default:
      throw new Error(`No event defined in useTemplatingReducer for ${type}`);
  }
};

const formatInitialValue = value => {
  return {
    value: value.value === null ? '' : value.value,
    error: value.error,
    tags: value.tags,
    showVariables: value.showVariables
  };
};

const defaultTemplating = {
  value: '',
  error: null,
  tags: [],
  showVariables: false
};

const useTemplatingReducer = (initialTemplating = defaultTemplating) => {
  const wasInternalUpdate = useRef(false);
  const justAddedSnippet = useRef(false);
  const justClosedVariables = useRef(false);
  const cursor = useRef(0);
  const input = useRef(null);

  const iniitalValue = { ...defaultTemplating, ...initialTemplating };

  const [templating, dispatch] = useReducer(
    reducer,
    formatInitialValue(iniitalValue)
  );

  const wrappedDispatch = useCallback(
    (params = {}) => {
      return dispatch({
        ...params,
        refs: {
          wasInternalUpdate,
          justAddedSnippet,
          justClosedVariables,
          cursor,
          input
        }
      });
    },
    [dispatch]
  );

  const lineCount = useMemo(() => {
    return (templating.value.match(/\n/g) || '').length + 1;
  }, [templating.value]);

  const isValid = useMemo(() => {
    return !templating.error;
  }, [templating.error]);

  return {
    templating,
    ...templating,
    wasInternalUpdate,
    justAddedSnippet,
    justClosedVariables,
    input,
    cursor,
    isValid,
    isNotValid: !isValid,
    lineCount,
    dispatch: wrappedDispatch,
    TYPES
  };
};

export default useTemplatingReducer;
