import DynamicInput from './DynamicInput';

const dynamic = {
  id: 'dynamic',
  name: 'Dynamic',
  content: {
    template: `{
  "type": "message",
  "content": {
    "placeholder": "Send a message",
    "disabled": false
  }
}`
  },
  valid: content => Boolean(content.template),
  Input: DynamicInput,
  snippet: null
};

export default dynamic;
