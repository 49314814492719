import React from 'react';
import PropTypes from 'prop-types';

import { NumberFormatter } from '@ubisend/pulse-components';

const FloatPreview = ({ value }) => {
  return <NumberFormatter>{value}</NumberFormatter>;
};

FloatPreview.propTypes = {
  value: PropTypes.number.isRequired
};

export default FloatPreview;
