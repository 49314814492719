import React from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { useNotification, Flex, Button } from '@ubisend/pulse-components';

import { createClient } from '../api/index';
import { useClient } from '../hooks/index';
import ClientDetails from './ClientDetails';

const CreateClient = ({ onClientCreated }) => {
  const { client, handlers, valid } = useClient();
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(createClient, {
    onSuccess: ({ data: { data } }) => {
      queryClient.invalidateQueries('clients');
      queryClient.invalidateQueries('owner/clients');
      onClientCreated(data);
      showSuccess(`Successfully created client "${data.name}"`);
    }
  });

  const handleSubmit = event => {
    event.preventDefault();
    mutate(client);
  };

  return (
    <form onSubmit={handleSubmit}>
      <ClientDetails client={client} {...handlers} creating />
      <Flex right>
        <Button icon="save" type="submit" loading={isLoading} disabled={!valid}>
          Save
        </Button>
      </Flex>
    </form>
  );
};

CreateClient.propTypes = {
  onClientCreated: PropTypes.func.isRequired
};

export default CreateClient;
