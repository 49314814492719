import React, { useState, useEffect } from 'react';

import {
  Toggle,
  LoadingContainer,
  useNotification,
  Label,
  Span,
  Flex
} from '@ubisend/pulse-components';

import { getTrainSettings, updateTrainSettings } from '../api/index';

const TrainingSettings = () => {
  const [settings, setSettings] = useState();

  const { showSuccess } = useNotification();

  const fetchTrainSettings = async () => {
    const { data } = await getTrainSettings();
    setSettings(data.data);
  };

  useEffect(() => {
    fetchTrainSettings();
  }, []);

  const handleTrainDailyChange = async () => {
    const newSettings = {
      ...settings,
      train_daily: !settings.train_daily
    };
    await updateTrainSettings(newSettings);
    setSettings(newSettings);
    showSuccess('Successfully updated train settings');
  };

  return (
    <div>
      {!settings && <LoadingContainer />}
      {settings && (
        <Flex col>
          <Label>Daily Train</Label>
          <Flex xSpace center>
            <Toggle
              on={settings.train_daily}
              handleToggleChange={handleTrainDailyChange}
            />
            <Span tinyText>Automatically train your chatbot every night</Span>
          </Flex>
        </Flex>
      )}
    </div>
  );
};

export default TrainingSettings;
