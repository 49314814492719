import { useReducer } from 'react';

const actions = {
  'Set subscriber location': { location: '' },
  'Set subscriber language': { language: '' },
  'Create ticket': { name: '', description: '', tags: [] },
  'Create live chat ticket': { name: '', description: '', tags: [] },
  'Leave feedback': { feedback: '' },
  'Export subscriber': { email: '' },
  'Export transcript': { email: '' },
  'Delete subscriber': {},
  'Export all knowledge bases': { email: '' },
  'Export single knowledge base': { email: '', import_id: '' },
  'Export knowledge base section': { email: '', section_id: '' }
};

const checks = {
  'Set subscriber location': content => Boolean(content.location),
  'Set subscriber language': content => Boolean(content.language),
  'Create ticket': content => Boolean(content.name && content.description),
  'Create live chat ticket': content => {
    return Boolean(content.name && content.description);
  },
  'Leave feedback': content => Boolean(content.feedback),
  'Export subscriber': content => Boolean(content.email),
  'Export transcript': content => Boolean(content.email),
  'Delete subscriber': () => true,
  'Export all knowledge bases': content => Boolean(content.email),
  'Export single knowledge base': content =>
    Boolean(content.email && content.import_id),
  'Export knowledge base section': content =>
    Boolean(content.email && content.section_id)
};

const checkValid = action => {
  const check = checks[action.name];

  if (!check) {
    return false;
  }

  return check(action.content);
};

const getInitalContent = name => {
  return { ...actions[name] };
};

const MAX_ACTIONS = 5;

const setInitialActions = (actions = []) => {
  return actions.map(action => ({
    name: action.name,
    content: action.content
  }));
};

const reducer = (actions, { type, index, ...params }) => {
  const updateAction = callback => {
    return actions.map((action, key) => {
      if (index === key) {
        return {
          ...action,
          ...callback(action)
        };
      }

      return action;
    });
  };

  switch (type) {
    // Edit single action
    case 'UPDATE_NAME':
      return updateAction(() => ({
        name: params.name,
        content: getInitalContent(params.name)
      }));
    case 'UPDATE_CONTENT':
      return updateAction(({ content }) => ({
        content: { ...content, ...params.content }
      }));

    // Global action events.
    case 'ADD_ACTION':
      return actions.concat({
        name: null,
        content: null
      });
    case 'DELETE_ACTION':
      return actions.filter((action, key) => index !== key);

    default:
      throw new Error(`No event defined in useActionReducer for ${type}`);
  }
};

const useActionReducer = ({ initialActions = [] }) => {
  const [actions, dispatch] = useReducer(
    reducer,
    setInitialActions(initialActions)
  );

  const valid = actions.every(action => {
    return action.name && checkValid(action);
  });

  const canAdd = actions.length < MAX_ACTIONS;

  const canNotAdd = !canAdd;

  const form = { actions };

  return { actions, form, dispatch, valid, canAdd, canNotAdd };
};

export default useActionReducer;
