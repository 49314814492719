import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSelect, { components } from 'react-select';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';

import { motion } from '@ubisend/framer-motion';
import { breakpoints } from '@ubisend/pulse-components';

import {
  Container,
  LeftSection,
  ComposerButton,
  Airplane,
  Label,
  labelAnimations
} from './MessageComposer';
import { useComposer, useDelivery, useTheme } from '../../../../../hooks/index';

const menuAnim = {
  initial: { opacity: 0, overflow: 'hidden' },
  show: { opacity: 1 }
};

const StyledMenu = styled(components.Menu)`
  margin-left: -1rem;
  &:first-of-type {
    width: calc(100% + 5.5rem);
  }

  @media (max-width: ${breakpoints.sm - 1}px) {
    &:first-child {
      top: -12rem;
    }
  }
`;

const Menu = props => {
  return (
    <motion.div
      variants={menuAnim}
      initial="initial"
      animate="show"
      exit="initial">
      <StyledMenu {...props} />
    </motion.div>
  );
};

const Control = ({ children }) => {
  return <div>{children}</div>;
};

const Group = ({ headingProps, ...props }) => {
  const headingRef = useRef();

  useEffect(() => {
    headingRef.current.scrollIntoView();
  }, []);

  const { cx, ...divProps } = props;

  return (
    <motion.div
      initial={{ maxHeight: 16 }}
      animate={{ maxHeight: 168 }}
      transition={{ duration: 0.6, delay: 0.2, ease: [0.76, 0, 0.24, 1] }}
      {...divProps}>
      <motion.span
        ref={headingRef}
        style={props.getStyles('groupHeading', props)}>
        {headingProps.data.label}
      </motion.span>
      {props.children}
    </motion.div>
  );
};

Group.propTypes = {
  cx: PropTypes.any,
  getStyles: PropTypes.func,
  headingProps: PropTypes.shape({
    data: PropTypes.shape({
      label: PropTypes.string
    })
  })
};

const FuzzyComposer = ({ content }) => {
  const [inputActive, setInputActive] = useState(false);
  const inputRef = useRef(null);
  const { reply, setReply } = useComposer();
  const { sendMessage } = useDelivery();

  const { t } = useTranslation('full_page');

  const theme = useTheme();

  const handleOptionSelect = selection => {
    if (!selection) {
      return setReply('');
    }

    if (content.multi) {
      return setReply(selection.map(option => option.value).join(','));
    }

    setReply(selection.value);
  };

  const customStyles = {
    menu: styles => ({
      ...styles,
      borderRadius: '1rem',
      ...tw`text-black px-4 py-2 border-0 shadow-lg md:mt-4`
    }),
    groupHeading: styles => ({
      ...styles,
      ...tw`px-0 pt-1 pb-2 font-semibold tracking-wide`
    }),
    valueContainer: styles => ({
      ...styles,
      ...tw`w-full px-0`
    }),
    placeholder: () => tw`hidden`,
    singleValue: styles => ({
      ...styles,
      ...(content.multi ? tw`hidden` : '')
    }),
    multiValueLabel: styles => ({
      ...styles,
      ...tw`font-poppins uppercase leading-none text-sm`,
      color: theme.gradient.from
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: chroma(theme.gradient.from).alpha(0.25).css(),
      marginTop: -1
    }),
    multiValueRemove: styles => ({ ...styles, color: theme.gradient.from }),
    option: (styles, state) => {
      return {
        ...styles,
        ...tw`rounded-lg py-3 px-4 cursor-pointer`,
        boxShadow: 'none',
        background: state.isSelected
          ? theme.gradient.from
          : state.isFocused
          ? chroma(theme.gradient.from).alpha(0.25).css()
          : 'none',
        color: state.isSelected || state.isFocuesed ? 'white' : state.color,
        '&:hover': {
          background:
            state.isSelected || state.isFocuesed
              ? theme.gradient.from
              : chroma(theme.gradient.from).alpha(0.25).css(),
          color: state.isSelected || state.isFocuesed ? 'white' : 'black'
        }
      };
    }
  };

  const handleClick = () => {
    inputRef.current.focus();
  };

  const handleBlur = () => {
    setInputActive(false);
  };

  const handleFocus = () => {
    setInputActive(true);
  };

  const handleNewReply = () => {
    const postback = reply;
    const message = postback
      .split(',')
      .map(item => content.items.find(option => option.value === item).label)
      .join(', ');
    sendMessage(message, postback);
    setReply('');
  };

  /**
   * We want React Select to manage the Input value of the select, until
   * we need to clear it.
   */
  const value = useMemo(() => {
    // Let React Select manage it's own state of the Input.
    if (reply) {
      return undefined;
    }

    // Control the Input so we can clear the value.
    return '';
  }, [reply]);

  const handleOnKeyDown = event => {
    if (!reply) {
      return;
    }

    if (event.keyCode === 13) {
      handleNewReply();
    }
  };

  return (
    <Container>
      <LeftSection onClick={handleClick}>
        <Label
          variants={labelAnimations}
          animate={
            inputActive || reply.length !== 0 ? 'minimised' : 'expanded'
          }>
          {content.placeholder}
        </Label>
        <ReactSelect
          styles={customStyles}
          ref={inputRef}
          aria-label={content.placeholder}
          components={{
            Control,
            Menu,
            Group,
            IndicatorsContainer: () => null
          }}
          options={[
            {
              label: t('fuzzy_composer_group_label'),
              options: content.items
            }
          ]}
          openMenuOnFocus
          openMenuOnClick
          onBlur={handleBlur}
          onFocus={handleFocus}
          blurInputOnSelect={false}
          onChange={handleOptionSelect}
          onKeyDown={handleOnKeyDown}
          isSearchable
          isMulti={content.multi}
          value={value}
          noOptionsMessage={() => t('fuzzy_composer_no_options_message')}
        />
      </LeftSection>
      <ComposerButton
        aria-label={t('send_button_label')}
        disabled={!reply}
        onClick={handleNewReply}>
        <Airplane />
      </ComposerButton>
    </Container>
  );
};

FuzzyComposer.propTypes = {
  content: PropTypes.shape({
    placeholder: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ).isRequired,
    multi: PropTypes.bool
  })
};

export default FuzzyComposer;
