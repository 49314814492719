import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { motion } from '@ubisend/framer-motion';
import { pseudoStyles, breakpoints } from '@ubisend/pulse-components';

import SplitText from '../SplitText';

const Button = styled(motion.button)`
  ${tw`px-6 py-2 text-white uppercase tracking-wide font-semibold font-poppins rounded-full text-xs cursor-pointer whitespace-no-wrap mb-2`}
  &:not(:last-of-type) {
    ${tw`mr-3`}
  }
  background-color: rgba(255, 255, 255, 0.25);
  border: 2px solid rgba(255, 255, 255, 0.25);
  ${pseudoStyles}
`;

const RepliesContainer = styled(motion.div)`
  ${tw`mt-12 w-full overflow-x-auto overflow-y-hidden px-3 sm:px-0 flex-grow h-18 items-start justify-center fixed relative flex flex-wrap max-h-64 overflow-y-auto`}
  box-sizing: border-box;

  @media (min-width: ${breakpoints.md}px) {
    max-height: 13rem;
  }
`;

const Container = styled(motion.div)`
  ${tw`flex flex-col items-center`}
`;

const containerAnim = {
  initial: { oapcity: 0 },
  animate: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.075
    }
  },
  exit: { opacity: 0 }
};

const buttonAnim = {
  initial: { opacity: 0, y: 25, scale: 1 },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      ease: 'circOut',
      duration: 0.4
    }
  },
  exit: { opacity: 0 }
};

const QuickReply = ({ content, handleButtonClick }) => (
  <Container>
    <SplitText>{content.text}</SplitText>
    <RepliesContainer
      initial="initial"
      animate="animate"
      exit="exit"
      variants={containerAnim}>
      {content.responses.map((response, i) => (
        <Button
          colour="white"
          onClick={() => handleButtonClick(response.label, response.postback)}
          variants={buttonAnim}
          key={i}>
          {response.label}
        </Button>
      ))}
    </RepliesContainer>
  </Container>
);

QuickReply.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string.isRequired,
    responses: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        postback: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  handleButtonClick: PropTypes.func.isRequired
};

export default QuickReply;
