import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  NoResults,
  PageWrapper,
  StretchPanel as Panel,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
  Flex,
  TableActions,
  Divider,
  useModal,
  useNotification,
  Span,
  Tag,
  Indicator,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalClose,
  Heading2,
  Button,
  ActionMenu,
  usePaginationReducer,
  OrderableTableRow,
  useOrderableTableReducer
} from '@ubisend/pulse-components';
import { PermissionFilter, useAuth } from '@ubisend/pulse-auth';

import { deleteLink, enablement } from '../api';
import LinksFilter, { defaultFilters } from '../Components/LinksFilter';

const LinkStatus = ({ link }) => {
  if (!link.expires_at && !link.disabled_at) {
    return (
      <Flex xSpaceSm center>
        <Indicator colour="positive" />
        <Span colour="positive">Active forever</Span>
      </Flex>
    );
  }

  if (link.disabled_at) {
    return (
      <Flex xSpaceSm center>
        <Indicator colour="danger" />
        <Span colour="danger">
          Disabled {dayjs(link.disabled_at).from(dayjs())}
        </Span>
      </Flex>
    );
  }

  if (dayjs().isAfter(dayjs(link.expires_at))) {
    return (
      <Flex xSpaceSm center>
        <Indicator colour="warning" />
        <Span colour="warning">
          Expired {dayjs(link.expires_at).from(dayjs())}
        </Span>
      </Flex>
    );
  }

  return (
    <Flex xSpaceSm center>
      <Indicator colour="positive" />
      <Span colour="positive">
        Active until {dayjs(link.expires_at).format('DD/MM/YY')}
      </Span>
    </Flex>
  );
};

LinkStatus.propTypes = {
  link: PropTypes.shape({
    expires_at: PropTypes.string,
    disabled_at: PropTypes.string
  }).isRequired
};

const Img = styled.img`
  ${tw`w-full`}
`;

const QrCodeButton = ({ link }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      {show && (
        <ModalPortal>
          <ModalContainer>
            <ModalContent>
              <Flex between fat pad center middle>
                <Heading2>QR Code</Heading2>
                <ModalClose onClick={() => setShow(false)} />
              </Flex>
              <Flex pad col fat ySpace>
                <Flex fat>
                  <Img src={link.qr_code.link} />
                </Flex>
                <Flex right>
                  <Button
                    variant="secondary"
                    as="a"
                    download
                    href={link.qr_code.link}
                    target="_blank"
                    rel="noopener noreferrer">
                    Download
                  </Button>
                </Flex>
              </Flex>
            </ModalContent>
          </ModalContainer>
        </ModalPortal>
      )}
      <Button variant="inline" icon="qrCode" onClick={() => setShow(true)}>
        QR Code
      </Button>
    </>
  );
};

QrCodeButton.propTypes = {
  link: PropTypes.shape({
    qr_code: PropTypes.shape({
      link: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

const Links = () => {
  const pagination = usePaginationReducer();
  const order = useOrderableTableReducer();

  const [filters, setFilters] = useState(defaultFilters);

  const { showModal, hideModal } = useModal();
  const { showNotification } = useNotification();

  const { hasSomePermissions } = useAuth();

  const queryClient = useQueryClient();
  const query = useQuery([
    'links',
    { ...pagination.params, ...order.params, ...filters }
  ]);
  const { mutate: deleteLinkApi } = useMutation(deleteLink, {
    onSuccess: () => {
      showNotification({ message: `Link was succesfully deleted.` });
      queryClient.invalidateQueries('links');
    }
  });

  const { mutate: mutateEnablement } = useMutation(enablement, {
    onSuccess: ({ data }) => {
      showNotification({
        message: `Link was succesfully ${
          data.data.disabled_at ? 'disabled' : 'enabled'
        }.`
      });
      queryClient.invalidateQueries('links');
    }
  });

  const handleLinkDelete = ({ id, name, url }) => {
    showModal({
      header: 'Delete Tracked Link',
      body: `Are you sure you want to delete "${name || url}"?`,
      handleConfirm: () => {
        deleteLinkApi(id);
        hideModal();
      }
    });
  };

  const handleEnablement = (id, state) => {
    let disabledAt = null;

    if (!state) {
      disabledAt = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
    }

    mutateEnablement({ id, disabled_at: disabledAt });
  };

  const handleFilterSubmit = newFilters => {
    pagination.resetPage();
    setFilters(newFilters);
  };

  return (
    <PageWrapper
      header="Link Tracking"
      subheader="View and manage tracked links"
      actions={
        <Flex xSpace>
          <PermissionFilter can="create links">
            <Button as={Link} to="/links/create" icon="plus">
              Link
            </Button>
          </PermissionFilter>
          <ActionMenu
            position={ActionMenu.POSITIONS.LEFT}
            buttonProps={{
              'aria-label': 'Toggle filter menu',
              variant: 'secondary',
              icon: 'filter',
              children: 'Filter',
              loading: query.isLoading
            }}>
            <LinksFilter handleFilterSubmit={handleFilterSubmit} />
          </ActionMenu>
        </Flex>
      }>
      <Panel style={{ overflow: 'visible' }} mt>
        {query.showNoResultsMessage && <NoResults />}
        {query.showTable && (
          <>
            <Flex
              col
              xScroll
              style={{ paddingBottom: 250, marginBottom: -250 }}>
              <Table loading={query.isLoading} loadingColumns={6}>
                <TableHead>
                  <OrderableTableRow
                    rows={[
                      { label: 'Link', sort: 'url' },
                      { label: 'Tracked link', sort: null },
                      { label: 'Tags', sort: null },
                      { label: 'Status', sort: null },
                      { label: 'Clicks', sort: 'clicks' },
                      null
                    ]}
                    {...order.props}
                  />
                </TableHead>
                {query.isSuccess && (
                  <TableBody>
                    {query.data.data.map((link, key) => (
                      <TableRow key={key}>
                        <TableCell
                          style={{
                            maxWidth: '16vw',
                            overflowX: 'auto',
                            WebkitMaskImage:
                              'linear-gradient(90deg, black 80%, transparent 100%)'
                          }}>
                          <Flex col>
                            {link.name && <Span>{link.name}</Span>}
                            <Flex>
                              <Button
                                style={{ paddingLeft: '0', paddingRight: '0' }}
                                variant="inline"
                                as="a"
                                href={link.url}
                                target="_blank"
                                rel="noreferrer">
                                {link.url}
                              </Button>
                            </Flex>
                          </Flex>
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: '16vw',
                            overflowX: 'auto',
                            WebkitMaskImage:
                              'linear-gradient(90deg, black 80%, transparent 100%)'
                          }}>
                          <Button
                            variant="inline"
                            as="a"
                            href={link.tracked_url}
                            target="_blank"
                            rel="noreferrer">
                            {link.tracked_url}
                          </Button>
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: '12vw',
                            overflowX: 'auto',
                            WebkitMaskImage:
                              'linear-gradient(90deg, black 80%, transparent 100%)'
                          }}>
                          {link.groups.length > 0 ? (
                            <Flex xSpaceSm>
                              {link.groups.map((group, key) => (
                                <Tag key={key}>{group.name}</Tag>
                              ))}
                            </Flex>
                          ) : (
                            'N/A'
                          )}
                        </TableCell>
                        <TableCell>
                          <LinkStatus link={link} />
                        </TableCell>
                        <TableCell style={{ width: '2rem' }}>
                          <Span>{link.clicks}</Span>
                        </TableCell>
                        <TableActions style={{ width: '2rem' }}>
                          {hasSomePermissions('delete links', 'edit links') && (
                            <ActionMenu
                              position={ActionMenu.POSITIONS.LEFT}
                              buttonProps={{
                                'aria-label': 'Toggle link menu'
                              }}>
                              {link.qr_code && <QrCodeButton link={link} />}
                              <PermissionFilter can="edit links">
                                {link.qr_code && <Divider mtNone mbNone />}
                                <Button
                                  variant="inline"
                                  as={Link}
                                  to={`/links/${link.id}/edit`}
                                  icon="pencilAlt">
                                  Edit
                                </Button>
                                <Divider mtNone mbNone />
                                <Button
                                  variant="inline"
                                  icon={link.disabled_at ? 'check' : 'x'}
                                  onClick={() =>
                                    handleEnablement(link.id, link.disabled_at)
                                  }>
                                  {link.disabled_at ? 'Enable' : 'Disable'}
                                </Button>
                                <PermissionFilter can="delete links">
                                  <Divider mtNone mbNone />
                                </PermissionFilter>
                              </PermissionFilter>
                              <PermissionFilter can="delete links">
                                <Button
                                  variant="inline"
                                  title={`delete-link-${key}`}
                                  icon="trash"
                                  colour={'danger'}
                                  onClick={() => {
                                    handleLinkDelete({ ...link });
                                  }}>
                                  Delete
                                </Button>
                              </PermissionFilter>
                            </ActionMenu>
                          )}
                        </TableActions>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </Flex>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </>
        )}
      </Panel>
    </PageWrapper>
  );
};

export default Links;
