import React, { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

import {
  Flex,
  Button as ComponentLibraryButton
} from '@ubisend/pulse-components';

import UrlButton from './UrlButton';
import PostbackButton from './PostbackButton';
import FileButton from './FileButton';
import ProtocolButton from './ProtocolButton';
import DeleteButton from '../DeleteButton';
import { types } from '../types';
import { useButtons } from '../../../hooks/index';

const inputs = {
  postback: PostbackButton,
  url: UrlButton,
  file: FileButton,
  mailto: ProtocolButton,
  callto: ProtocolButton
};

const Button = ({ type, index, ...props }) => {
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  const { handleButtonMove, min, value } = useButtons();

  const [, drop] = useDrop({
    accept: 'BUTTON',
    drop(item) {
      if (item.index === index) {
        return;
      }

      handleButtonMove(item.index, index);
    }
  });
  const [, drag] = useDrag({
    type: 'BUTTON',
    item: () => ({ index })
  });

  drag(buttonRef);
  drop(containerRef);

  const Component = useMemo(() => {
    return inputs[type] || PostbackButton;
  }, [type]);

  return (
    <Flex xSpace border padSm top ref={containerRef}>
      <ComponentLibraryButton
        variant="inline"
        icon="menuAlt4"
        ref={buttonRef}
      />
      <Component index={index} type={type} {...props} />
      {value.length > min && (
        <Flex>
          <DeleteButton index={index} />
        </Flex>
      )}
    </Flex>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(types),
  index: PropTypes.number.isRequired
};

export default Button;
