import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimatePresence } from '@ubisend/framer-motion';
import {
  Flex,
  Button,
  Accordion,
  Explainer,
  Span,
  InlineNotification,
  Label,
  TextInput
} from '@ubisend/pulse-components';

import FormGroup from './FormGroup';
import SettingContainer from './SettingContainer';
import { exportSubscriber } from '../../api/index';
import { isValidEmail } from '../../utilities/index';
import { useMutation, useTheme } from '../../hooks/index';
import getErrorMessage from './errors';

const RequestData = () => {
  const [email, setEmail] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);

  const theme = useTheme();

  const { t } = useTranslation('bots');

  const mutation = useMutation(exportSubscriber, {
    onSuccess: () => {
      setEmail('');
    }
  });

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const valid = Boolean(mutation.isEnabled && !mutation.isLoading && email);

  const handleSubmit = event => {
    event.preventDefault();
    setShowEmailError(false);

    if (!isValidEmail(email)) {
      return setShowEmailError(true);
    }

    if (valid) {
      mutation.mutate(email);
    }
  };

  return (
    <SettingContainer>
      <Accordion divider header={t('request_data_setting_header')}>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Explainer type="note" hideLabel>
              <Span tinyText colour="black">
                {t('request_data_setting_description')}
              </Span>
            </Explainer>
            <Flex col>
              <Label htmlFor="email" colour="black">
                {t('request_data_setting_email_label')}
              </Label>
              {/* eslint-disable i18next/no-literal-string */}
              <TextInput
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="example@email.com"
              />
              {/* eslint-enable i18next/no-literal-string */}
            </Flex>
            <AnimatePresence>
              {mutation.isError && (
                <InlineNotification
                  description={t(getErrorMessage(mutation.error))}
                  type="error"
                />
              )}
              {showEmailError && (
                <InlineNotification
                  description={t(
                    'request_data_setting_invalid_email_error_message'
                  )}
                  type="error"
                />
              )}
            </AnimatePresence>
            <AnimatePresence>
              {mutation.isSuccess && (
                <InlineNotification
                  description={t('request_data_setting_success_message')}
                  type="success"
                />
              )}
            </AnimatePresence>
            <Button
              disabled={!valid}
              icon="download"
              type="submit"
              customTextColour={theme.sentMessage?.text || 'white'}>
              {t('request_data_setting_submit_button')}
            </Button>
          </FormGroup>
        </form>
      </Accordion>
    </SettingContainer>
  );
};

export default RequestData;
