import { useState } from 'react';

const defaultClient = {
  name: '',
  email: '',
  phone: '',
  language_id: null,
  address_1: '',
  address_2: '',
  city: '',
  region: '',
  postcode: '',
  country: ''
};

const setInitalClient = client => {
  if (!client.default_language) {
    return client;
  }

  const { default_language, ...params } = client;
  return {
    ...params,
    language_id: default_language.id
  };
};

const useClient = (initialClient = defaultClient) => {
  const [client, setClient] = useState(setInitalClient(initialClient));

  const handleInputChange = field => event => {
    const { value } = event.target;
    setClient(client => ({
      ...client,
      [field]: value
    }));
  };

  const handleSelectChange = field => option => {
    setClient(client => ({
      ...client,
      [field]: option.value
    }));
  };

  const requiredKeys = ['name', 'email', 'language_id'];

  const valid = requiredKeys.every(key => Boolean(client[key]));

  return {
    client,
    setClient,
    valid,
    handlers: {
      handleInputChange,
      handleSelectChange
    }
  };
};

export default useClient;
