const hidden = {
  id: 'hidden',
  name: 'Hidden',
  Input: null,
  content: {},
  valid: () => true,
  snippet: `{
    "type": "hidden",
    "content": {} 
}`
};

export default hidden;
