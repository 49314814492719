import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useQueries } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  StretchPanel as Panel,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableCell,
  Pagination,
  Button,
  Tooltip,
  TableActions,
  Explainer,
  usePaginationReducer,
  ActionMenu,
  Flex
} from '@ubisend/pulse-components';

import { Filter } from '../Components/index';
import SmallTalkSettings from './SmallTalkSettings';

const defaultFilters = {
  name: null
};

const ManageSmallTalk = () => {
  const pagination = usePaginationReducer();
  const [settingsModal, setSettingsModal] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);

  const queries = useQueries([
    {
      queryKey: ['smalltalk', { ...pagination.params, ...filters }]
    },
    {
      queryKey: ['smalltalk/settings']
    }
  ]);

  const handleFilterChange = filters => {
    pagination.resetPage();
    setFilters(filters);
  };

  return (
    <>
      {settingsModal && (
        <SmallTalkSettings
          initialSettings={queries[1].data.data}
          handleCancel={() => setSettingsModal(!settingsModal)}
        />
      )}
      <PageWrapper
        header="Small Talk"
        actions={
          <Flex xSpace>
            <ActionMenu
              position={ActionMenu.POSITIONS.LEFT}
              buttonProps={{
                'aria-label': 'Toggle filter menu',
                variant: 'secondary',
                icon: 'filter',
                children: 'Filter',
                loading: queries[0].isLoading
              }}>
              <Filter
                filters={filters}
                handleFilterChange={handleFilterChange}
              />
            </ActionMenu>
            <Button onClick={() => setSettingsModal(!settingsModal)} icon="cog">
              Settings
            </Button>
          </Flex>
        }>
        {queries[1].isSuccess && !queries[1].data.data.enabled && (
          <Explainer type="warning" mb>
            Small Talk is currently turned off. You can still edit your Small
            Talk responses but they will not be used by your bot within
            conversations. You can turn it back on in the Small Talk settings
            menu.
          </Explainer>
        )}
        <Panel>
          <>
            <Table loading={queries[0].isLoading} loadingColumns={3}>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>
                    Example user trigger
                    <Tooltip
                      tooltip={
                        <Tooltip.Content>
                          An example message a user would send to trigger this
                          small talk
                        </Tooltip.Content>
                      }>
                      <Tooltip.Icon>?</Tooltip.Icon>
                    </Tooltip>
                  </TableHeadCell>
                  <TableHeadCell />
                </TableRow>
              </TableHead>
              {queries[0].isSuccess && (
                <TableBody>
                  {queries[0].data.data.map((smallTalk, key) => (
                    <TableRow key={key}>
                      <TableCell>{smallTalk.name}</TableCell>
                      <TableCell>{smallTalk.message_example}</TableCell>
                      <TableActions>
                        <Button
                          as={Link}
                          variant="secondary"
                          to={`/smalltalk/${smallTalk.id}/edit`}>
                          Manage responses
                        </Button>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {queries[0].showPagination && (
              <Pagination
                pagination={queries[0].data.meta}
                {...pagination.props}
              />
            )}
          </>
        </Panel>
      </PageWrapper>
    </>
  );
};

export default ManageSmallTalk;
