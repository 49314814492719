const auth = {
  id: 'auth',
  name: 'Auth',
  Input: null,
  content: {},
  valid: () => true,
  snippet: `{
    "type": "auth",
    "content": {} 
}`
};

export default auth;
