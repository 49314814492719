import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MemoryRouter, Route, Switch } from 'react-router-dom';

import { Messages, Message } from './Screens/index';
import { WindowHeader } from './Components/index';
import { Notifications, Tickets } from '../Converse/Screens/index';
import { LightBox } from '../Converse/Components/index';
import { Settings } from '../../Screens/index';
import { useBot } from '../../hooks/index';

const Container = styled.div`
  ${tw`w-full h-full flex flex-col absolute pin-t`}
  box-sizing: border-box;
`;

const Embed = ({ start = '/window/body/messages' }) => {
  const { lightBoxImage } = useBot();

  return (
    <MemoryRouter initialEntries={[start]}>
      <Container>
        {lightBoxImage && <LightBox />}
        <Switch>
          <Route path="/window">
            <Route exact path="/window/messages/:id" component={Message} />
            <Route path="/window/body">
              <WindowHeader />
              <Route exact path="/window/body/messages" component={Messages} />
              <Route
                exact
                path="/window/body/notifications"
                component={Notifications}
              />
              <Route exact path="/window/body/tickets" component={Tickets} />
              <Route exact path="/window/body/settings" component={Settings} />
            </Route>
          </Route>
        </Switch>
      </Container>
    </MemoryRouter>
  );
};

Embed.propTypes = {
  start: PropTypes.string
};

export default Embed;
