import { useReducer } from 'react';

const TYPES = {
  CHANGE_PAGE: 'CHANGE_PAGE',
  CHANGE_PER_PAGE: 'CHANGE_PER_PAGE'
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    case TYPES.CHANGE_PAGE:
      return { ...state, page: params.page };
    case TYPES.CHANGE_PER_PAGE:
      return { perPage: params.perPage, page: 1 };
    default:
      throw new Error(`No event defined in usePaginationReducer for ${type}`);
  }
};

const defaultParams = {
  page: 1,
  perPage: 15,
  perPageOptions: [15, 30, 50, 75, 100]
};

const usePaginationReducer = (initialParams = defaultParams) => {
  const builtParams = {
    ...defaultParams,
    ...initialParams
  };

  const [pagination, dispatch] = useReducer(reducer, {
    page: builtParams.page,
    perPage: builtParams.perPage
  });

  const handlePageChange = page => {
    dispatch({ type: TYPES.CHANGE_PAGE, page });
  };

  const resetPage = () => {
    dispatch({ type: TYPES.CHANGE_PAGE, page: 1 });
  };

  const handlePerPageChange = option => {
    dispatch({ type: TYPES.CHANGE_PER_PAGE, perPage: option.value });
  };

  return {
    ...pagination,
    dispatch,
    perPageOptions: builtParams.perPageOptions,
    handlePageChange,
    resetPage,
    handlePerPageChange,
    TYPES,
    params: {
      page: pagination.page,
      per_page: pagination.perPage
    },
    props: {
      perPageOptions: builtParams.perPageOptions,
      handlePageChange,
      handlePerPageChange
    }
  };
};

export default usePaginationReducer;
