import styled from 'styled-components';

import { spacing, text } from '../styles';

const Heading1 = styled.h1`
  ${tw`text-2xl m-0`}
  ${spacing}
  ${text}
`;

export default Heading1;
