import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { motion, AnimatePresence } from '@ubisend/framer-motion';
import { breakpoints } from '@ubisend/pulse-components';

import { useDelivery } from '../../../hooks/index';
import {
  useGroups,
  useTypingIndicatorAnimation,
  useHasMediaMessage,
  useHasContentMessage
} from '../hooks/index';
import {
  Message,
  Composer,
  TypingIndicator,
  MediaMessageSlideIn,
  ContentMessageSlideIn,
  Button
} from '../Components/index';
import { RecordSubscriberTyping } from '../../../Components/index';
import { ComposerProvider } from '../../../Providers/index';

const Arrow = props => (
  <svg
    {...props}
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
      clipRule="evenodd"
    />
  </svg>
);

const Container = styled.div`
  ${tw`w-full flex items-center justify-center flex-col-reverse md:flex-row relative`}
  height: calc(100% - 70px);
`;

const ButtonContainer = styled(motion.div)`
  ${tw`z-10 w-full absolute flex items-center h-16 md:h-24 mb-2 md:mb-0 max-w-lg`}
  justify-content: space-evenly;
  box-sizing: border-box;

  @media (max-width: ${breakpoints.md}px) {
    top: ${props => {
      if (props.hasContentMessage) {
        return '0';
      }

      if (props.hasMediaMessage) {
        return '37.5vh';
      }

      return '65px';
    }};
  }

  @media (min-width: ${breakpoints.md}px) {
    bottom: 0;
  }

  @media (max-width: ${breakpoints.lg}px) {
    bottom: 0;
  }
`;

const ForwardArrow = styled(Arrow)`
  ${tw`bot:ml-2`}
`;

const BackArrow = styled(Arrow)`
  ${tw`bot:mr-2`}
  transform: rotate(180deg);
`;

const MessageNavLabel = styled.span`
  ${tw`hidden bot:block`}
`;

const Inner = styled.div`
  ${tw`flex-1 flex flex-col p-4 w-full relative justify-center max-w-lg md:mx-12`}
  box-sizing: border-box;
  min-width: 0;
`;

const TypingContainer = styled.div`
  & > div {
    ${tw`hidden md:flex`}
  }
`;

const GroupProgress = styled.div`
  ${tw`absolute relative flex items-center`}
`;

const ProgressBar = styled(motion.div)`
  width: ${props => props.width};
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
`;

const ProgressBarContainer = styled.div`
  ${tw`w-24 sm:w-48 h-3 rounded-full overflow-hidden`}
  background: rgba(255,255,255,0.25);
`;

const ProgressNumber = styled.span`
  ${tw`font-poppins text-xs md:text-md w-2 flex items-center justify-center`}
  &:last-child {
    ${tw`ml-2 md:ml-4`}
  }
  &:first-child {
    ${tw`mr-2 md:mr-4`}
  }
`;

const Messages = () => {
  const [active, setActive] = useState(0);

  const {
    message,
    handleNextMessage,
    handlePreviousMessage,
    hasNextMessage,
    hasPreviousMessage,
    messageIndex,
    group
  } = useGroups();
  const { sendMessage } = useDelivery();
  const animation = useTypingIndicatorAnimation(message);
  const hasMediaMessage = useHasMediaMessage(message);
  const hasContentMessage = useHasContentMessage(message);

  const { t } = useTranslation('bots');

  const groupProgress = useMemo(() => {
    return (messageIndex + 1) / group.length;
  }, [messageIndex, group.length]);

  useEffect(() => {
    if (message) {
      setActive(0);
    }
  }, [message]);

  return (
    <>
      <Container>
        <Inner>
          <TypingContainer>
            <TypingIndicator animation={animation} />
          </TypingContainer>
          {message && message.direction === 'toClient' && (
            <React.Fragment key={message.id}>
              <Message
                index={active}
                message={message}
                handleButtonClick={sendMessage}
              />
              <ComposerProvider>
                <RecordSubscriberTyping />
                <AnimatePresence exitBeforeEnter>
                  <Composer custom={message.id} composer={message.composer} />
                </AnimatePresence>
              </ComposerProvider>
            </React.Fragment>
          )}
        </Inner>
        <ButtonContainer
          layout="position"
          hasContentMessage={hasContentMessage}
          hasMediaMessage={hasMediaMessage}>
          <Button
            disabled={!hasPreviousMessage}
            initial={{ visibility: 'hidden' }}
            animate={{ visibility: hasPreviousMessage ? 'visible' : 'hidden' }}
            onClick={handlePreviousMessage}>
            <BackArrow />
            <MessageNavLabel>{t('message_group_back_button')}</MessageNavLabel>
          </Button>
          {group.length > 1 && (
            <GroupProgress>
              <ProgressNumber>{messageIndex + 1}</ProgressNumber>
              <ProgressBarContainer>
                <ProgressBar layout width={`${groupProgress * 100}%;`} />
              </ProgressBarContainer>
              <ProgressNumber>{group.length}</ProgressNumber>
            </GroupProgress>
          )}
          <Button
            disabled={!hasNextMessage}
            initial={{ visibility: 'hidden' }}
            animate={{ visibility: hasNextMessage ? 'visible' : 'hidden' }}
            onClick={handleNextMessage}>
            <MessageNavLabel>{t('message_group_next_button')}</MessageNavLabel>{' '}
            <ForwardArrow />
          </Button>
        </ButtonContainer>
        <MediaMessageSlideIn
          visible={hasMediaMessage}
          message={message}
          active={active}
          setActive={setActive}
        />
      </Container>
      <ContentMessageSlideIn message={message} />
    </>
  );
};

export default Messages;
export {
  BackArrow,
  ForwardArrow,
  TypingContainer,
  GroupProgress,
  ProgressBar,
  ProgressNumber,
  ButtonContainer,
  ProgressBarContainer,
  Container,
  Inner,
  MessageNavLabel
};
