import MessageInput from '../Message/MessageInput';

const numeric = {
  id: 'numeric',
  name: 'Numeric',
  Input: MessageInput,
  content: {
    placeholder: 'Enter a number...',
    disabled: false
  },
  valid: content => {
    return Boolean(content.placeholder);
  },
  snippet: `{
    "type": "numeric",
    "content": {
      "placeholder": "Enter a number...",
      "disabled": false
    } 
}`
};

export default numeric;
