import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import styled, { createGlobalStyle } from 'styled-components';

import 'flatpickr/dist/themes/dark.css';
import { inputStyles } from './TextInput';

const OveriddenFlatpickrStyles = createGlobalStyle`
  .flatpickr-current-month {
    padding: 2px 0 0 0 !important;
  }
  .flatpickr-calendar,
  .flatpickr-month,
  .flatpickr-current-month,
  .flatpickr-monthDropdown-months,
  span.flatpickr-weekday {
    background: #1a1a1a !important;
  }
  .flatpickr-day.selected {
    background: #684b99 !important;
  }
`;

/**
 * Wrapper for flatpickr to show date time.
 *
 * Flatpickr: https://github.com/flatpickr/flatpickr
 * Flatpickr React: https://github.com/haoxins/react-flatpickr
 */
const DateTimePicker = ({
  date,
  onChange,
  enableTime = true,
  options = {},
  ...rest
}) => (
  <>
    <OveriddenFlatpickrStyles />
    <Flatpickr
      data-enable-time={enableTime}
      value={date}
      style={{ boxSizing: 'border-box' }}
      onChange={onChange}
      options={{
        dateFormat: 'J M Y H:i',
        ...options
      }}
      {...rest}
    />
  </>
);

DateTimePicker.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  enableTime: PropTypes.bool,
  options: PropTypes.object
};

// appearance none fixes datepicker on ios
const StyledDateTimePicker = styled(DateTimePicker)`
  ${inputStyles}
  ${tw`w-full`}
  box-sizing: border-box;
  appearance: none;
`;

export default StyledDateTimePicker;
