import GeneralInput from './GeneralInput';

const general = {
  id: 'general',
  name: 'General',
  Input: GeneralInput,
  content: {
    title: '',
    subtitle: '',
    image: 'http://placehold.it/400x200',
    image_description: '',
    url: '',
    responses: []
  },
  valid: content => {
    return (
      content.title.length > 0 &&
      content.subtitle.length > 0 &&
      content.image.length > 0 &&
      content.url.length > 0 &&
      content.image_description?.length > 0 &&
      content.responses.length > 0 &&
      // TODO: Validate per button type
      content.responses.every(({ label, action }) => label && action)
    );
  },
  snippet: `{
    "type": "general",
    "content": {
      {
        "title": "",
        "subtitle": "",
        "image": "http://placehold.it/400x200",
        "image_description": "",
        "url": "",
        "responses": [
          {"type": "postback", "label": "", "action": ""}
        ]
      }
    }
}`
};

export default general;
