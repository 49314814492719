import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  PageWrapper,
  StretchPanel as Panel,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableCell,
  TableActions,
  Button,
  Pagination,
  NoResults,
  useModal,
  useNotification,
  Flex,
  Tooltip,
  usePaginationReducer,
  OrderableTableHeadCell,
  useOrderableTableReducer,
  ActionMenu
} from '@ubisend/pulse-components';
import {
  LocationsConsumer,
  useLanguages,
  useQuery,
  useQueryClient,
  useMutation
} from '@ubisend/pulse-hooks';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { FaqFilter, ExportFaqs, EditFaq } from '../Components/index';
import { deleteFaq } from '../api/index';

const defaultFilters = {
  groups: [],
  locations: [],
  faq_id: null
};
const Faqs = () => {
  const pagination = usePaginationReducer();
  const order = useOrderableTableReducer();

  const [filters, setFilters] = useState(defaultFilters);
  const [showEdit, setShowEdit] = useState(false);

  const { showModal, hideModal } = useModal();
  const { showNotification } = useNotification();
  const { defaultLanguage } = useLanguages();

  const queryClient = useQueryClient();

  const params = {
    ...(!filters.faq_id && { ...pagination.params, ...order.params }),
    faq_id: filters.faq_id,
    groupIds: filters.groups,
    locations: filters.locations
  };

  const query = useQuery(['faqs', params]);
  const mutation = useMutation(deleteFaq, {
    onSuccess: () => {
      showNotification({ message: `FAQ was succesfully deleted.` });
      queryClient.invalidateQueries(['faqs', params]);
    }
  });

  const handleFaqDelete = ({ id, name }) => {
    showModal({
      header: 'Delete FAQ',
      body: `Are you sure you want to delete "${name}"?`,
      handleConfirm: () => {
        mutation.mutate(id);
        hideModal();
      }
    });
  };

  const handleFilterChange = state => {
    pagination.resetPage();
    setFilters(state);
  };

  const resetFilters = () => {
    handleFilterChange({ ...defaultFilters });
  };

  return (
    <PageWrapper
      header="FAQs"
      subheader="View and manage FAQs"
      actions={
        <Flex xSpace>
          <PermissionFilter can="create faqs">
            <Button variant="secondary" as={Link} to={`/faqs/create`}>
              + FAQ
            </Button>
          </PermissionFilter>
          <ActionMenu
            buttonProps={{
              'aria-label': 'Toggle filter menu',
              variant: 'secondary',
              icon: 'filter',
              children: 'Filter',
              loading: query.isLoading
            }}>
            <FaqFilter
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleReset={resetFilters}
            />
          </ActionMenu>
          <ExportFaqs />
          <PermissionFilter can="edit faq settings">
            <Button
              as={Link}
              variant="secondary"
              icon="cog"
              to={'/faqs/follow-up'}>
              Follow up
            </Button>
          </PermissionFilter>
        </Flex>
      }>
      {showEdit && (
        <EditFaq handleCancel={() => setShowEdit(false)} faq={showEdit} />
      )}
      <Panel mt>
        {query.showNoResultsMessage && <NoResults />}
        {query.showTable && (
          <Table loading={query.isLoading} loadingColumns={5}>
            <TableHead>
              <TableRow>
                <OrderableTableHeadCell
                  row={{ label: 'Name', sort: 'name' }}
                  {...order.props}
                />
                <TableHeadCell>Example Question</TableHeadCell>
                <LocationsConsumer>
                  <TableHeadCell>Location</TableHeadCell>
                </LocationsConsumer>
                <TableHeadCell>Tags</TableHeadCell>
                <OrderableTableHeadCell
                  row={{ label: 'Number of answers', sort: 'response_count' }}
                  {...order.props}
                />
                <TableHeadCell />
              </TableRow>
            </TableHead>
            {query.isSuccess && (
              <TableBody>
                {query.data.data.map((faq, key) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Flex center>
                        <span>{faq.name}</span>
                      </Flex>
                    </TableCell>
                    <TableCell>
                      {
                        faq.intents.find(intent => {
                          return intent.language.id === defaultLanguage.id;
                        }).utterances[0].utterance
                      }
                    </TableCell>
                    <LocationsConsumer>
                      <TableCell>
                        {faq.location ? faq.location.name : 'No Location'}
                      </TableCell>
                    </LocationsConsumer>
                    <TableCell>
                      {faq.groups.length > 0
                        ? faq.groups.map(({ name }) => name).join(', ')
                        : 'No Tags'}
                    </TableCell>
                    <TableCell>
                      <Flex center>
                        {faq.response_count}
                        {faq.response_count === 0 && (
                          <TableCell>
                            <Tooltip
                              tooltip={
                                <Tooltip.Content>
                                  This FAQ has no answers. It must have at least
                                  one answer to be used within the bot.
                                </Tooltip.Content>
                              }>
                              <Tooltip.Icon>!</Tooltip.Icon>
                            </Tooltip>
                          </TableCell>
                        )}
                      </Flex>
                    </TableCell>
                    <TableActions>
                      <PermissionFilter can="edit faqs">
                        <Button
                          variant="secondary"
                          onClick={() => setShowEdit(faq)}
                          icon="pencilAlt">
                          Edit
                        </Button>
                      </PermissionFilter>
                      <PermissionFilter can="edit faqs">
                        <Button
                          as={Link}
                          variant="secondary"
                          to={`/faqs/${faq.id}/questions`}>
                          Questions
                        </Button>
                      </PermissionFilter>
                      <PermissionFilter can="edit faqs">
                        <Button
                          as={Link}
                          variant="secondary"
                          to={`/faqs/${faq.id}/responses`}>
                          Answers
                        </Button>
                      </PermissionFilter>
                      <PermissionFilter can="delete faqs">
                        <Button
                          variant="secondary"
                          colour="danger"
                          icon="trash"
                          onClick={() => handleFaqDelete(faq)}>
                          Delete
                        </Button>
                      </PermissionFilter>
                    </TableActions>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        )}
        {query.showPagination && (
          <Pagination pagination={query.data.meta} {...pagination.props} />
        )}
      </Panel>
    </PageWrapper>
  );
};

Faqs.propTypes = {
  history: PropTypes.any
};

export default Faqs;
export { defaultFilters };
