import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import {
  Flex,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useNotification,
  FormHeading,
  Span,
  Label,
  Select,
  Divider,
  formatSelectOption,
  OrderableTableRow,
  useOrderableTableReducer,
  usePaginationReducer,
  Pagination
} from '@ubisend/pulse-components';
import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';

import {
  revokeInvite as revokeInviteApi,
  inviteUser as inviteUserApi
} from '../api/index';
import PermissionFilter from './PermissionFilter';
import methods from './LoginMethods/index';

const AuthSelect = ({ value, ...props }) => {
  const query = useQuery('auth');

  return (
    <Select
      {...props}
      value={
        query.isSuccess && value
          ? formatSelectOption(
              query.data.data.find(method => method.id === value)
            )
          : null
      }
      isLoading={query.isLoading}
      options={query.isSuccess ? query.data.data.map(formatSelectOption) : []}
    />
  );
};

AuthSelect.propTypes = {
  value: PropTypes.number
};

const LoginMethodInvite = ({ method, ...props }) => {
  const { Invite: ChosenMethodInvite } = useMemo(() => {
    return methods[method];
  }, [method]);

  return <ChosenMethodInvite {...props} />;
};

LoginMethodInvite.propTypes = {
  method: PropTypes.string.isRequired
};

const Invite = () => {
  const [method, setMethod] = useState(null);

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const mutation = useMutation(inviteUserApi, {
    onSuccess: () => {
      showSuccess(`Successfully sent invite`);
      queryClient.invalidateQueries('invites');
      queryClient.invalidateQueries('users');
    }
  });

  const handleMethodChange = option => {
    setMethod(option);
  };

  const handleSubmit = params => {
    mutation.mutate({
      auth_driver_id: method.value,
      ...params
    });
  };

  return (
    <Flex col>
      <FormHeading>Invite a new user</FormHeading>
      <Flex col>
        <Label htmlFor="method">Login method</Label>
        <AuthSelect
          id="method"
          value={method ? method.value : null}
          onChange={handleMethodChange}
        />
      </Flex>
      {method && (
        <>
          <Divider />
          <LoginMethodInvite
            method={method.label}
            handleSubmit={handleSubmit}
            loading={mutation.isLoading}
          />
        </>
      )}
    </Flex>
  );
};

const Invites = () => {
  const { showSuccess } = useNotification();

  const order = useOrderableTableReducer();
  const pagination = usePaginationReducer();

  const queryClient = useQueryClient();
  const query = useQuery([
    'invites',
    { ...pagination.params, ...order.params }
  ]);
  const mutation = useMutation(revokeInviteApi, {
    onSuccess: () => {
      showSuccess(`Successfully revoked invite`);
      queryClient.invalidateQueries(['invites', order.params]);
    }
  });

  const handleInviteRevokeSubmit = ({ id }) => () => {
    mutation.mutate(id);
  };

  return (
    <Flex xSpace>
      <Flex col fat>
        <FormHeading>Pending Invites</FormHeading>
        {query.showNoResultsMessage && (
          <Span>No invites are currently pending</Span>
        )}
        {query.showTable && (
          <Flex col border>
            <Table loading={query.isLoading} loadingColumns={3}>
              <TableHead>
                <OrderableTableRow
                  rows={[
                    { label: 'Identifier', sort: 'identifier' },
                    { label: 'Invite sent', sort: 'created_at' },
                    null
                  ]}
                  {...order.props}
                />
              </TableHead>
              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((invite, key) => (
                    <TableRow key={key}>
                      <TableCell>{invite.identifier}</TableCell>
                      <TableCell>
                        {dayjs(invite.created_at).from(dayjs())}
                      </TableCell>
                      <TableCell>
                        <PermissionFilter can="delete invites">
                          <Button
                            colour="danger"
                            onClick={handleInviteRevokeSubmit(invite)}>
                            Revoke
                          </Button>
                        </PermissionFilter>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </Flex>
        )}
      </Flex>
      <Flex col fat>
        <PermissionFilter can="create invites">
          <Invite />
        </PermissionFilter>
      </Flex>
    </Flex>
  );
};

export default Invites;
