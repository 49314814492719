import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  TextInput,
  Button,
  ToggleButton,
  Span
} from '@ubisend/pulse-components';
import {
  Condition,
  useConditional,
  useConditionalTypes,
  ConditionalSelect,
  SubjectSelect,
  CaseSensitiveToggle
} from '@ubisend/pulse-conditionals';

import { SheetSelect, LookupSelect } from '../../../Sheets/index';

const LookupConditional = ({
  index,
  labelPrefix,
  type,
  condition,
  target,
  subject,
  case_sensitive,
  flipped
}) => {
  const types = useConditionalTypes();
  const {
    dispatch,
    conditionals,
    match,
    handleConditionalChange,
    handleConditionChange,
    handleSubjectChange,
    handleTargetChange,
    handleConditionalDelete,
    handleCaseToggle,
    handleFlippedToggle,
    getSubjectValue
  } = useConditional();

  const label = (string, index) => `${labelPrefix}-${string}-${index}`;

  const conditionalType = useMemo(() => {
    const conditional = types.find(({ name }) => name === type);

    if (!conditional) {
      return null;
    }

    return conditional.type;
  }, [types, type]);

  const handleSheetChange = option => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: {
        ...subject,
        spreadsheet_id: option ? option.value : null,
        lookup_id: null
      },
      index
    });
  };

  const handleLookupChange = ({ value }) => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: { ...subject, lookup_id: value },
      index
    });
  };

  return (
    <Flex xSpace center fat>
      <Span uppercase style={{ minWidth: '4rem', textAlign: 'right' }}>
        {index === 0 && 'if'}
        {index !== 0 && match === 'any' && 'Or'}
        {index !== 0 && match === 'all' && 'And'}
      </Span>
      <ToggleButton
        on={flipped}
        onChange={handleFlippedToggle(index)}
        aria-label={label('flipped', index)}>
        not
      </ToggleButton>
      <Flex col fat ySpace border pad>
        <Flex xSpace center fat>
          <SubjectSelect
            aria-label={label('subject', index)}
            value={getSubjectValue({ conditionalType, type, subject })}
            onChange={handleSubjectChange(index)}
          />
          <SheetSelect
            aria-label={label('sheet', index)}
            value={subject.spreadsheet_id}
            onChange={handleSheetChange}
          />
          <span>using</span>
          <LookupSelect
            aria-label={label('lookup', index)}
            sheet={subject.spreadsheet_id}
            value={subject.lookup_id}
            onChange={handleLookupChange}
          />
        </Flex>
        {subject.lookup_id && (
          <Flex xSpace center>
            <Flex fat xSpace growChildren center>
              <TextInput
                aria-label={label('target', index)}
                value={target}
                onChange={handleTargetChange(index)}
                placeholder="users.first_name"
              />
              <CaseSensitiveToggle
                aria-label={label('case-sensitive', index)}
                index={index}
                value={case_sensitive}
                onChange={handleCaseToggle(index)}
              />
              <ConditionalSelect
                aria-label={label('conditional', index)}
                value={type}
                onChange={handleConditionalChange(index)}
              />
              <Condition
                aria-label={label('condition', index)}
                name={conditionalType}
                value={condition}
                onChange={handleConditionChange(index)}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex grow right>
        <Button
          variant="secondary"
          colour="danger"
          icon="trash"
          disabled={conditionals.length <= 1}
          onClick={handleConditionalDelete(index)}>
          Delete
        </Button>
      </Flex>
    </Flex>
  );
};

LookupConditional.propTypes = {
  index: PropTypes.number.isRequired,
  labelPrefix: PropTypes.string.isRequired,
  subject: PropTypes.shape({
    spreadsheet_id: PropTypes.number,
    lookup_id: PropTypes.number
  }).isRequired,
  type: PropTypes.string,
  condition: PropTypes.any,
  target: PropTypes.string,
  case_sensitive: PropTypes.bool,
  flipped: PropTypes.bool
};

export default LookupConditional;
