import React, { useState, useMemo } from 'react';

import {
  PageWrapper,
  Panel,
  Flex,
  OneThirdLayout,
  TwoThirdsLayout,
  Accordion,
  ColourPicker,
  Label,
  FormGroup,
  useTheme,
  Button,
  TextInput,
  ThemeProvider,
  Heading3,
  useNotification
} from '@ubisend/pulse-components';
import { usePulse, useQueryClient, useMutation } from '@ubisend/pulse-hooks';

import { updateOwner } from '../api/index';
import { WhiteLabelPreview } from '../Components/index';

const WhiteLabel = () => {
  const { owner: initalOwner } = usePulse();
  const initalTheme = useTheme();
  const { showSuccess } = useNotification();

  const [owner, setOwner] = useState({
    ...initalOwner,
    theme: { ...initalTheme }
  });

  const queryClient = useQueryClient();
  const { mutate } = useMutation(updateOwner, {
    onSuccess: () => {
      queryClient.invalidateQueries('pulse');
      showSuccess('Successfully updated your whitelabel settings.');
    }
  });

  const handleChangeTheme = key => colour => {
    setOwner(owner => ({
      ...owner,
      theme: { ...owner.theme, [key]: colour.hex }
    }));
  };

  const handleChangeOwner = key => event => {
    const value = event.target.value;

    setOwner(owner => ({
      ...owner,
      [key]: value
    }));
  };

  const valid = useMemo(() => {
    return Boolean(
      owner.url &&
        owner.logo_url &&
        owner.theme.white &&
        owner.theme.black &&
        owner.theme.grey &&
        owner.theme.primary &&
        owner.theme.secondary &&
        owner.theme.positive &&
        owner.theme.danger &&
        owner.theme.warning
    );
  }, [owner]);

  const handleSubmit = event => {
    event.preventDefault();

    if (!valid) {
      return;
    }

    mutate(owner);
  };

  return (
    <PageWrapper
      header="White Label"
      subheader={'Customise the core Platform settings'}>
      <Flex xSpace>
        <OneThirdLayout>
          <form onSubmit={handleSubmit}>
            <Flex ySpace col>
              <Panel header="Owner settings" divider>
                <FormGroup>
                  <Label htmlFor="logo_url">Logo URL</Label>
                  <TextInput
                    id="logo_url"
                    placeholder=""
                    value={owner.logo_url}
                    onChange={handleChangeOwner('logo_url')}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="url">URL</Label>
                  <TextInput
                    id="url"
                    placeholder=""
                    value={owner.url}
                    onChange={handleChangeOwner('url')}
                  />
                </FormGroup>
              </Panel>
              <Accordion inner header="Colour settings">
                <Flex mbSm>
                  <Heading3>Brand Colours</Heading3>
                </Flex>
                <FormGroup>
                  <Label>Primary</Label>
                  <ColourPicker
                    color={owner.theme.primary}
                    onChangeComplete={handleChangeTheme('primary')}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Secondary</Label>
                  <ColourPicker
                    color={owner.theme.secondary}
                    onChangeComplete={handleChangeTheme('secondary')}
                  />
                </FormGroup>
                <Flex mbSm>
                  <Heading3>Status Colours</Heading3>
                </Flex>
                <FormGroup>
                  <Label>Positive</Label>
                  <ColourPicker
                    color={owner.theme.positive}
                    onChangeComplete={handleChangeTheme('positive')}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Warning</Label>
                  <ColourPicker
                    color={owner.theme.warning}
                    onChangeComplete={handleChangeTheme('warning')}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Danger</Label>
                  <ColourPicker
                    color={owner.theme.danger}
                    onChangeComplete={handleChangeTheme('danger')}
                  />
                </FormGroup>
                <Flex mbSm>
                  <Heading3>Core Colours</Heading3>
                </Flex>
                <FormGroup>
                  <Label>White</Label>
                  <ColourPicker
                    color={owner.theme.white}
                    onChangeComplete={handleChangeTheme('white')}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Black</Label>
                  <ColourPicker
                    color={owner.theme.black}
                    onChangeComplete={handleChangeTheme('black')}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Grey</Label>
                  <ColourPicker
                    color={owner.theme.grey}
                    onChangeComplete={handleChangeTheme('grey')}
                  />
                </FormGroup>
              </Accordion>
              <Flex right>
                <Button type="submit" icon="save" disabled={!valid}>
                  Save
                </Button>
              </Flex>
            </Flex>
          </form>
        </OneThirdLayout>
        <TwoThirdsLayout>
          <ThemeProvider theme={owner.theme}>
            <WhiteLabelPreview />
          </ThemeProvider>
        </TwoThirdsLayout>
      </Flex>
    </PageWrapper>
  );
};

export default WhiteLabel;
