import React from 'react';
import styled from 'styled-components';

import { Settings as CoreSettings } from '../../../Screens/index';

const Container = styled.div`
  height: calc(100% - 70px);

  ${tw`mt-16 md:mt-0`}
  & h2 {
    ${tw`text-3xl`}
  }
  & span {
    ${tw`opacity-100`}
  }
`;

const Settings = () => {
  return (
    <Container>
      <CoreSettings />
    </Container>
  );
};

export default Settings;
