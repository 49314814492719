import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Button,
  ToggleButton,
  TextInput,
  Span
} from '@ubisend/pulse-components';
import {
  useConditionalTypes,
  useConditional,
  ConditionalSelect,
  CaseSensitiveToggle,
  Condition
} from '@ubisend/pulse-conditionals';

const SheetConditional = ({
  index,
  labelPrefix,
  type,
  condition,
  case_sensitive,
  target,
  flipped
}) => {
  const types = useConditionalTypes();
  const {
    conditionals,
    match,
    handleConditionalChange,
    handleConditionChange,
    handleConditionalDelete,
    handleCaseToggle,
    handleFlippedToggle,
    handleTargetChange
  } = useConditional();

  const label = (string, index) => `${labelPrefix}-${string}-${index}`;

  const conditionalType = useMemo(() => {
    const conditional = types.find(({ name }) => name === type);

    if (!conditional) {
      return null;
    }

    return conditional.type;
  }, [types, type]);

  return (
    <Flex col ySpace>
      <Flex xSpace center>
        <Span uppercase style={{ minWidth: '4rem', textAlign: 'right' }}>
          {index === 0 && 'if'}
          {index !== 0 && match === 'any' && 'Or'}
          {index !== 0 && match === 'all' && 'And'}
        </Span>
        <ToggleButton
          on={flipped}
          onChange={handleFlippedToggle(index)}
          aria-label={label('flipped', index)}>
          not
        </ToggleButton>
        <Flex fat xSpace growChildren>
          <TextInput
            aria-label={label('target', index)}
            value={target}
            onChange={handleTargetChange(index)}
            placeholder="name_row"
          />
          <CaseSensitiveToggle
            aria-label={label('case-sensitive', index)}
            index={index}
            value={case_sensitive}
            onChange={handleCaseToggle(index)}
          />
          <ConditionalSelect
            aria-label={label('conditional', index)}
            value={type}
            onChange={handleConditionalChange(index)}
          />
          <Condition
            aria-label={label('condition', index)}
            name={conditionalType}
            value={condition}
            onChange={handleConditionChange(index)}
          />
        </Flex>
        <Flex grow right>
          <Button
            variant="secondary"
            colour="danger"
            icon="trash"
            disabled={conditionals.length <= 1}
            onClick={handleConditionalDelete(index)}>
            Delete
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

SheetConditional.propTypes = {
  index: PropTypes.number.isRequired,
  labelPrefix: PropTypes.string.isRequired,
  target: PropTypes.string,
  type: PropTypes.string,
  condition: PropTypes.any,
  case_sensitive: PropTypes.bool,
  flipped: PropTypes.bool
};

export default SheetConditional;
