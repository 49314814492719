import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { TimePicker, Flex, Checkbox } from '@ubisend/pulse-components';

const CheckboxContainer = styled.div`
  width: 7rem;
  flex-shrink: 0;
`;

const WeekDay = ({
  handleTimeUpdate,
  toggleAvailability,
  day,
  times: { open, close },
  disabled
}) => {
  const closed = Boolean(!open && !close);

  const handleOpenChange = ([time]) => {
    handleTimeUpdate(day, {
      open: dayjs(time).format('HH:mm:ss'),
      close: close
    });
  };

  const handleCloseChange = ([time]) => {
    handleTimeUpdate(day, {
      open: open,
      close: dayjs(time).format('HH:mm:ss')
    });
  };

  return (
    <Flex center xSpace>
      <CheckboxContainer>
        <Checkbox
          checked={!closed}
          onChange={() => toggleAvailability(day, closed)}
          label={day.charAt(0).toUpperCase() + day.slice(1)}
          disabled={disabled}
        />
      </CheckboxContainer>
      <TimePicker
        aria-label={`${day}-opening-hours`}
        disabled={disabled || closed}
        time={open}
        onChange={handleOpenChange}
      />
      <TimePicker
        aria-label={`${day}-closing-hours`}
        disabled={disabled || closed}
        time={close}
        onChange={handleCloseChange}
      />
    </Flex>
  );
};

WeekDay.propTypes = {
  handleTimeUpdate: PropTypes.func.isRequired,
  toggleAvailability: PropTypes.func.isRequired,
  times: PropTypes.shape({
    open: PropTypes.string,
    close: PropTypes.string
  }),
  day: PropTypes.oneOf([
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ]).isRequired,
  disabled: PropTypes.bool
};

export default WeekDay;
