import React from 'react';

import { PageWrapper, ActionMenu, Flex } from '@ubisend/pulse-components';

import { Messages, Train, Filter } from '../Components/index';
import { useFilters } from '../hooks/index';

const TrainedMessages = () => {
  const { filters, pagination, order, props } = useFilters();

  return (
    <PageWrapper
      header="Training"
      subheader="Train and improve your chatbot's knowledge"
      actions={
        <Flex xSpace>
          <ActionMenu
            buttonProps={{
              'aria-label': 'Toggle filter menu',
              variant: 'secondary',
              icon: 'filter',
              children: 'Filter'
            }}>
            <Filter {...props} />
          </ActionMenu>
          <Train />
        </Flex>
      }>
      <Messages
        filters={filters}
        pagination={pagination}
        order={order}
        query="training/trained"
        multi={false}
        showLink={false}
        showActions={false}
      />
    </PageWrapper>
  );
};

export default TrainedMessages;
