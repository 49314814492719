import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useQueryClient, useQuery, useMutation } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  PageWrapper,
  StretchPanel as Panel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useNotification,
  useModal,
  PanelSlider,
  NoResults,
  TableActions,
  OrderableTableRow,
  useOrderableTableReducer,
  Button,
  usePaginationReducer,
  Pagination
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { deleteBroadcast, editBroadcast } from '../api/index';
import { EditBroadcast } from '../Components/index';
import { getFormattedDate } from '../utils/index';

const QueuedBroadcasts = () => {
  const [chosenBroadcast, setChosenBroadcast] = useState();
  const order = useOrderableTableReducer();
  const pagination = usePaginationReducer();

  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();

  const queryClient = useQueryClient();
  const query = useQuery([
    'broadcasts',
    { ...pagination.params, ...order.params }
  ]);
  const deleteBroadcastMutation = useMutation(deleteBroadcast, {
    onSuccess: () => {
      showSuccess('The broadcast was succesfully deleted.');
      queryClient.invalidateQueries('broadcasts');
    }
  });
  const editBroadcastMutation = useMutation(editBroadcast, {
    onSuccess: () => {
      showSuccess('Successfully updated broadcast');
      queryClient.invalidateQueries('broadcasts');
      setChosenBroadcast(null);
    }
  });

  const handleBroadcastDelete = ({ id }) => {
    showModal({
      header: 'Delete Broadcast',
      body: `Are you sure you want to delete the broadcast?`,
      handleConfirm: async () => {
        try {
          await deleteBroadcastMutation.mutateAsync(id);
        } finally {
          hideModal();
        }
      }
    });
  };

  const handleEditSubmit = ({ id, sendNow, scheduleAt, responses }) => {
    if (sendNow) {
      showModal({
        header: 'Broadcast your message',
        body:
          'Are you sure you want to brodcast this message? All subscribers currently using the chatbot will see it.',
        handleConfirm: async () => {
          try {
            // Trigger a broadcast over the API.
            await editBroadcastMutation.mutateAsync({
              id,
              send_now: true,
              ...responses
            });
          } finally {
            hideModal();
          }
        }
      });
    } else {
      editBroadcastMutation.mutateAsync({
        id,
        scheduled_at: scheduleAt,
        send_now: false,
        ...responses
      });
    }
  };

  const handleHide = () => setChosenBroadcast(null);

  const handleBroadcastEdit = broadcast => {
    setChosenBroadcast(broadcast);
  };

  return (
    <PageWrapper
      header="Scheduled Broadcasts"
      subheader="Manage scheduled broadcasts"
      actions={
        <Button as={Link} to="/broadcast" variant="secondary">
          Broadcast a message
        </Button>
      }>
      <Panel>
        {query.showNoResultsMessage && (
          <NoResults text="No scheduled broadcasts" />
        )}
        {query.showTable && (
          <>
            <Table loading={query.isLoading} loadingColumns={3}>
              <TableHead>
                <OrderableTableRow
                  rows={[
                    { label: 'Scheduled for', sort: 'scheduled_at' },
                    { label: 'Message', sort: null },
                    null
                  ]}
                  {...order.props}
                />
              </TableHead>

              {query.isSuccess && (
                <TableBody>
                  {query.data.data.map((broadcast, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        {getFormattedDate(broadcast.scheduled_at)}
                      </TableCell>
                      <TableCell>
                        {broadcast.responses[0].content.text}
                      </TableCell>
                      <TableActions>
                        <PermissionFilter can="edit broadcasts">
                          <Button
                            variant="secondary"
                            icon="pencilAlt"
                            onClick={() => handleBroadcastEdit(broadcast)}>
                            Edit
                          </Button>
                        </PermissionFilter>
                        <PermissionFilter can="delete broadcasts">
                          <Button
                            variant="secondary"
                            icon="trash"
                            colour="danger"
                            onClick={() => handleBroadcastDelete(broadcast)}>
                            Delete
                          </Button>
                        </PermissionFilter>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </>
        )}
      </Panel>
      <AnimatePresence>
        {chosenBroadcast && (
          <PanelSlider header="Edit Broadcast" handleHide={handleHide}>
            <EditBroadcast
              broadcast={chosenBroadcast}
              handleEditSubmit={handleEditSubmit}
            />
          </PanelSlider>
        )}
      </AnimatePresence>
    </PageWrapper>
  );
};

export default QueuedBroadcasts;
