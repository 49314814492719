import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Flex, Button, ToggleButton, Span } from '@ubisend/pulse-components';

import ConditionalSelect from '../../ConditionalSelect';
import SubjectSelect from '../../SubjectSelect';
import CaseSensitiveToggle from '../../CaseSensitiveToggle';
import { Condition } from '../../Conditions/index';
import { useConditionalTypes, useConditional } from '../../../hooks/index';
import { showCondtionals } from '../../../utils/index';

const LOCATION_TYPES = ['message-check', 'message-group-check'];

const LocationConditional = ({
  index,
  labelPrefix,
  type,
  condition,
  case_sensitive,
  flipped,
  subject
}) => {
  const types = useConditionalTypes();
  const {
    conditionals,
    match,
    handleConditionalChange,
    handleConditionChange,
    handleConditionalDelete,
    handleSubjectChange,
    handleCaseToggle,
    handleFlippedToggle,
    getSubjectValue
  } = useConditional();

  const label = (string, index) => `${labelPrefix}-${string}-${index}`;

  const conditionalType = useMemo(() => {
    const conditional = types.find(({ name }) => name === type);

    if (!conditional) {
      return null;
    }

    return conditional.type;
  }, [types, type]);

  return (
    <Flex col ySpace>
      <Flex xSpace center>
        <Span uppercase style={{ minWidth: '4rem', textAlign: 'right' }}>
          {index === 0 && 'if'}
          {index !== 0 && match === 'any' && 'Or'}
          {index !== 0 && match === 'all' && 'And'}
        </Span>
        <ToggleButton
          on={flipped}
          onChange={handleFlippedToggle(index)}
          aria-label={label('flipped', index)}>
          not
        </ToggleButton>
        <SubjectSelect
          aria-label={label('subject', index)}
          value={getSubjectValue({ conditionalType, type, subject })}
          onChange={handleSubjectChange(index)}
        />

        {(!type || showCondtionals(conditionalType)) && (
          <Flex fat xSpace growChildren>
            <CaseSensitiveToggle
              aria-label={label('case-sensitive', index)}
              index={index}
              value={case_sensitive}
              onChange={handleCaseToggle(index)}
            />
            <ConditionalSelect
              types={LOCATION_TYPES}
              aria-label={label('conditional', index)}
              value={type}
              onChange={handleConditionalChange(index)}
            />
            <Condition
              aria-label={label('condition', index)}
              name={conditionalType}
              value={condition}
              onChange={handleConditionChange(index)}
            />
          </Flex>
        )}
        <Flex grow right>
          <Button
            variant="secondary"
            colour="danger"
            icon="trash"
            disabled={conditionals.length <= 1}
            onClick={handleConditionalDelete(index)}>
            Delete
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

LocationConditional.propTypes = {
  index: PropTypes.number.isRequired,
  labelPrefix: PropTypes.string.isRequired,
  subject: PropTypes.shape({
    type: PropTypes.string
  }).isRequired,
  type: PropTypes.string,
  condition: PropTypes.any,
  case_sensitive: PropTypes.bool,
  flipped: PropTypes.bool
};

export default LocationConditional;
