import QuickReplyInput from './QuickReplyInput';

const quickReply = {
  id: 'quick-reply',
  name: 'Quick reply',
  Input: QuickReplyInput,
  content: { text: '', responses: [] },
  valid: content => {
    return (
      content.text.length > 0 &&
      content.responses.length > 0 &&
      // TODO
      content.responses.every(({ label }) => label)
    );
  },
  snippet: `{
    "type": "quick-reply",
    "content": {
      "text": "",
      "responses": [
        {"label": "", "postback": ""}
      ]
    }
}`
};

export default quickReply;
