import React, { useState, useRef } from 'react';
import dayjs from 'dayjs';
import { useDrop } from 'react-dnd';
import styled from 'styled-components';
import chroma from 'chroma-js';

import { useQuery, useMutation } from '@ubisend/pulse-hooks';
import { motion, AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  PageWrapper,
  Panel,
  Button,
  StickyFooter,
  PanelSlider,
  useNotification,
  Flex,
  NoResults,
  Grid,
  Heading2,
  ActionMenu,
  Label,
  Divider,
  DateTimePicker
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { AllMetrics, Metric } from './Components/index';
import types from '../../Components/Types/index';
import { updateMetrics } from './api/index';
import { MetricRouter } from './Routers/index';

const Placeholder = styled(motion.div)`
  ${tw`rounded-sm`}
  width: 100%;
  height: 100%;
  min-height: 8rem;
  grid-column: span ${props => props.columns} / auto;
  grid-row: auto / span ${props => props.rows};
  border: 1px solid ${props => chroma(props.theme.primary).alpha(0.5)};
  background: ${props => chroma(props.theme.primary).alpha(0.25)};
`;

const Dashboard = () => {
  const [metricKeys, setMetricKeys] = useState();
  const [startInput, setStartInput] = useState(
    new Date(dayjs().subtract(1, 'weeks'))
  );
  const [endInput, setEndInput] = useState(new Date(dayjs().add('1', 'hours')));
  const [filters, setFilters] = useState({
    start: startInput,
    end: endInput
  });
  const [editing, setEditing] = useState(false);
  const [addingNew, setAddingNew] = useState(false);
  const [dragPoint, setDragPoint] = useState(null);

  const { showSuccess } = useNotification();

  const ref = useRef(null);
  const oldKeys = useRef();

  useQuery('metrics', {
    onSuccess: ({ data }) => {
      setMetricKeys(data);
    }
  });
  const { mutate } = useMutation(updateMetrics, {
    onSuccess: () => {
      setEditing(false);
      showSuccess('Successfully updated your metrics.');
    }
  });

  const toggleAdding = () => setAddingNew(adding => !adding);

  const startEditing = () => {
    oldKeys.current = metricKeys;
    setEditing(true);
  };

  const cancelEditing = () => {
    setMetricKeys(oldKeys.current);
    setEditing(false);
  };

  const handleNewStart = ([newStart]) => setStartInput(newStart);
  const handleNewEnd = ([newEnd]) => setEndInput(newEnd);

  const handleSearch = () => {
    setFilters({ start: startInput, end: endInput });
  };

  const handleDelete = id => {
    setMetricKeys(keys => keys.filter(key => key.id !== id));
  };

  const handleNewMetric = item => setMetricKeys(keys => keys.concat(item));

  const handleSubmit = () => {
    mutate(metricKeys.map(({ id }) => id));
  };

  const [placeholderIndex, setPlaceholderIndex] = useState(null);
  const [draggedMetric, setDraggedMetric] = useState(null);

  const handleDrop = () => {
    const fromIndex = metricKeys.findIndex(key => key.id === draggedMetric);
    const mutableMetricKeys = [...metricKeys];
    mutableMetricKeys.splice(
      placeholderIndex,
      0,
      mutableMetricKeys.splice(fromIndex, 1)[0]
    );

    setPlaceholderIndex(null);
    setDraggedMetric(null);
    setMetricKeys(mutableMetricKeys);
  };

  const [, drop] = useDrop({
    accept: 'METRIC',
    drop(item) {
      if (!ref.current) {
        return;
      }

      handleDrop(item.id);
    }
  });

  const handleHover = (ref, mousePosition, fromId, toId) => {
    setDraggedMetric(fromId);

    const index = metricKeys.findIndex(key => key.id === toId);
    if (toId === fromId) {
      return setPlaceholderIndex(index);
    }

    const rect = ref.current.getBoundingClientRect();

    const x = mousePosition.x - rect.left;

    const normalisedX = x / rect.width;

    // If on the right hand side of a metric, set the placeholder to show after
    setPlaceholderIndex(normalisedX > dragPoint.x ? index + 1 : index);
  };

  const handleMouseDown = event => {
    // If we aren't editing metrics or d
    if (!editing || dragPoint) {
      return;
    }
    // Get the bounding rectangle of target
    const rect = event.target.getBoundingClientRect();

    // Mouse position
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const normalisedX = x / rect.width;
    const normalisedY = y / rect.height;
    setDragPoint({ x: normalisedX, y: normalisedY });
  };

  drop(ref);

  return (
    <React.Fragment>
      <MetricRouter />
      <PageWrapper
        header="Dashboard"
        subheader="Your chatbot's performance"
        actions={
          <Flex xSpace>
            <ActionMenu
              position={ActionMenu.POSITIONS.LEFT}
              buttonProps={{
                'aria-label': 'Toggle filter menu',
                variant: 'secondary',
                icon: 'filter',
                children: 'Filter',
                disabled: !(metricKeys && metricKeys.length !== 0)
              }}>
              <Flex col style={{ width: '20rem' }}>
                <Flex pl pr pt col>
                  <Flex col fat mb>
                    <Label htmlFor="start">Start</Label>
                    <DateTimePicker
                      id="start"
                      date={startInput}
                      onChange={handleNewStart}
                    />
                  </Flex>
                  <Flex col fat>
                    <Label htmlFor="end">End</Label>
                    <DateTimePicker
                      id="end"
                      date={endInput}
                      onChange={handleNewEnd}
                    />
                  </Flex>
                </Flex>
                <Divider />
                <Flex right fat pb pr pl>
                  <Button variant="primary" icon="check" onClick={handleSearch}>
                    Apply
                  </Button>
                </Flex>
              </Flex>
            </ActionMenu>
            <PermissionFilter can="edit metrics">
              <Button
                variant="secondary"
                {...(editing && { colour: 'danger' })}
                icon={editing ? null : 'pencilAlt'}
                onClick={editing ? cancelEditing : startEditing}>
                {editing ? 'Cancel' : 'Edit'}
              </Button>
            </PermissionFilter>
          </Flex>
        }>
        {!metricKeys && (
          <Grid columns={3}>
            <Panel loading loadingItems={1} />
            <Panel loading loadingItems={1} />
            <Panel loading loadingItems={1} />
            <Panel loading loadingItems={1} />
            <Panel loading loadingItems={1} />
            <Panel loading loadingItems={1} />
          </Grid>
        )}
        {metricKeys && metricKeys.length === 0 && (
          <Panel>
            <Flex col middle>
              <NoResults
                text="You do not currently have any metrics selected"
                subtitle="Click edit to get started"
              />
            </Flex>
          </Panel>
        )}
        {metricKeys && metricKeys.length !== 0 && (
          <Flex col ySpace>
            {/* <MetricFilter
              
            /> */}
            <Grid columns={6} ref={ref}>
              <AnimateSharedLayout>
                {metricKeys.map((metric, i) => {
                  return (
                    <React.Fragment key={i}>
                      {editing && i === placeholderIndex && (
                        <Placeholder
                          layoutId="drag-placeholder"
                          columns={
                            types[
                              metricKeys.find(({ id }) => draggedMetric === id)
                                .type
                            ].columns
                          }
                          rows={
                            types[
                              metricKeys.find(({ id }) => draggedMetric === id)
                                .type
                            ].rows
                          }
                        />
                      )}
                      {draggedMetric !== metric.id && (
                        <Metric
                          key={metric.id}
                          metric={metric}
                          filters={filters}
                          editing={editing}
                          handleHover={handleHover}
                          handleDelete={handleDelete}
                          handleMouseDown={handleMouseDown}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </AnimateSharedLayout>
            </Grid>
          </Flex>
        )}
      </PageWrapper>
      {editing && (
        <StickyFooter>
          <Flex center between>
            <Heading2>Editing your dashboard</Heading2>
            <Flex xSpace>
              <Button
                variant="secondary"
                colour="danger"
                onClick={cancelEditing}>
                Cancel
              </Button>
              <Button variant="secondary" onClick={toggleAdding}>
                View metrics
              </Button>
              <Button onClick={handleSubmit} icon="save">
                Save
              </Button>
            </Flex>
          </Flex>
        </StickyFooter>
      )}
      {addingNew && (
        <PanelSlider width="40%" handleHide={toggleAdding} header="New Metrics">
          <AllMetrics
            handleMetricClick={handleNewMetric}
            activeMetrics={metricKeys}
          />
        </PanelSlider>
      )}
    </React.Fragment>
  );
};

export default Dashboard;
