import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '@ubisend/pulse-icons';

import { Flex } from '../Layout/index';
import Panel from './Panel';

const IconImage = styled(Icon)`
  ${tw`w-12 h-12 mr-4`}
`;
const Title = styled.p`
  ${tw`m-0`}
`;
const Info = styled.p`
  ${tw`opacity-50 m-0 text-xs mt-2`}
`;

const IconPanel = ({ actions, icon = 'document', title, status }) => (
  <Panel>
    <Flex between center>
      <Flex center>
        {icon && <IconImage alt="panel-icon" type={icon} />}
        <Flex col middle>
          {title && <Title>{title}</Title>}
          {status && <Info>{status}</Info>}
        </Flex>
      </Flex>
      {actions}
    </Flex>
  </Panel>
);

IconPanel.propTypes = {
  actions: PropTypes.any,
  icon: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string
};

export default IconPanel;
