import styled from 'styled-components';

import { spacing, text } from '../styles';

const Heading2 = styled.h2`
  ${tw`m-0 font-semibold`}
  ${spacing}
  ${text}
`;

export default Heading2;
