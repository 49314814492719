import React from 'react';
import PropTypes from 'prop-types';

import { Flex } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

import { useButtons } from '../../../hooks/index';

const placeholder = {
  action: 'What is the annual leave policy?',
  label: 'Annual Leave'
};

const PostbackButton = ({ label, action, index }) => {
  const { handleButtonChange } = useButtons();

  return (
    <Flex col ySpace fat>
      <TemplatingTextInput
        aria-label={`postback-label-${index + 1}`}
        value={label}
        placeholder={placeholder.label}
        onChange={value => {
          handleButtonChange(index, 'label', value);
        }}
      />
      <TemplatingTextInput
        aria-label={`postback-action-${index + 1}`}
        value={action}
        placeholder={placeholder.action}
        onChange={value => {
          handleButtonChange(index, 'action', value);
        }}
      />
    </Flex>
  );
};

PostbackButton.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};

export default PostbackButton;
export { placeholder };
