import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';
import { breakpoints } from '@ubisend/pulse-components';

import Image from './Image';
import { useBelowScreenSize } from '../../../hooks/index';

const Dots = styled.div`
  ${tw`flex absolute z-10 w-full justify-center pb-4 md:pb-0`}
  @media (min-width: ${breakpoints.md}px) {
    bottom: 6rem;
  }
`;

const Dot = styled(motion.div)`
  ${tw`rounded-full bg-white w-3 h-3 mx-2 cursor-pointer`}
`;

const Container = styled(motion.div)`
  @media (min-width: ${breakpoints.md}px) {
    ${tw`h-full w-full flex items-center justify-center`}
  }
  @media (max-width: ${breakpoints.md - 1}px) {
    ${tw`h-full flex`}
  }
`;

const StyledImage = styled(Image)`
  ${tw`w-full md:w-auto`}
`;

const sliderAnims = {
  stacked: { opacity: 0, scale: 0.5, x: 0, zIndex: 0 },
  active: { scale: 1, x: 0, opacity: 1, zIndex: 2 },
  inActive: { scale: 0.8, x: '100%', opacity: 1, zIndex: 1 },
  offScreen: { scale: 0.8, x: '200%', opacity: 0, zIndex: 0 }
};

const getAnim = (active, index) => {
  const isActive = active === index;
  const stacked = index < active;
  const offScreen = index > active + 1;

  if (isActive) {
    return 'active';
  }

  if (stacked) {
    return 'stacked';
  }

  if (offScreen) {
    return 'offScreen';
  }

  return 'inActive';
};

const Slider = ({ active, setActive, content }) => {
  const mobile = useBelowScreenSize();

  const handleImageClick = index => () => {
    const imageIsActive = active === index;

    if (imageIsActive && index === 0) {
      return;
    }

    setActive(imageIsActive ? index - 1 : index);
  };

  return (
    <>
      <Container>
        {content.items.map((response, index) => (
          <StyledImage
            key={index}
            lightbox={false}
            transition={{ type: 'spring', duration: 0.6 }}
            variants={sliderAnims}
            animate={!mobile && getAnim(active, index)}
            initial="inactive"
            src={response.image}
            onClick={handleImageClick(index)}
            alt={response.image_description || 'Image message'}
          />
        ))}
      </Container>
      <Dots>
        {content.items.map((_, index) => (
          <Dot
            initial={{ opacity: 0.5 }}
            animate={{ opacity: active === index ? 1 : 0.5 }}
            key={index}
            onClick={() => setActive(index)}
          />
        ))}
      </Dots>
    </>
  );
};

Slider.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.string,
    items: PropTypes.arrayOf({
      image: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  active: PropTypes.bool,
  setActive: PropTypes.func
};

export default Slider;
