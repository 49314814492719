import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Divider,
  TooltipMenu,
  useModal,
  useNotification,
  Button
} from '@ubisend/pulse-components';
import { useClickOutside } from '@ubisend/pulse-component-hooks';

import { exportMetric } from '../api/index';

const ActionContainer = styled(Flex)`
  ${tw`absolute pin-t pin-r z-10 mt-2`}
`;

const MetricMenu = ({ metric, params }) => {
  const [open, setOpen] = useState(false);
  const handleClickOutside = useCallback(() => setOpen(false), []);

  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();
  const ref = useClickOutside(handleClickOutside);

  const { start, end } = params;

  const mutation = useMutation(exportMetric, {
    id: metric.id,
    start,
    end
  });

  const handleExport = () => {
    showModal({
      header: 'Export Metric',
      body: `Do you want to export "${metric.name}"?`,
      handleConfirm: async () => {
        await mutation.mutateAsync({ id: metric.id, ...params });
        showSuccess(
          `Successfully exported "${metric.name}". Keep an eye on your emails.`
        );
        hideModal();
      }
    });
  };

  return (
    <ActionContainer>
      <Button
        variant="inline"
        icon="dotsHorizontal"
        colour="black"
        ref={ref}
        onClick={() => setOpen(open => !open)}
        aria-label={`metric-menu-${metric.name}`}
      />
      {open && (
        <TooltipMenu>
          <Button
            variant="inline"
            icon="externalLink"
            as={Link}
            to={{
              pathname: `dashboard/${metric.id}`,
              state: { start, end }
            }}>
            View more
          </Button>
          <Divider mtNone mbNone />
          <Button
            variant="inline"
            onClick={() => handleExport()}
            icon="download">
            Export
          </Button>
        </TooltipMenu>
      )}
    </ActionContainer>
  );
};

MetricMenu.propTypes = {
  hasRecordedMetrics: PropTypes.any.isRequired,
  metric: PropTypes.any.isRequired,
  params: PropTypes.any.isRequired
};

export default MetricMenu;
