import FloatDefaultValueInput from './FloatDefaultValueInput';
import FloatPreview from './FloatPreview';

const floatType = {
  key: 'Float',
  name: 'Decimal',
  default_value: 0,
  DefaultValueInput: FloatDefaultValueInput,
  Preview: FloatPreview
};

export default floatType;
