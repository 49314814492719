import React from 'react';
import { useTranslation } from 'react-i18next';

import BaseComposer from '../BaseComposer';

const LoadingComposer = () => {
  const { t } = useTranslation('bots');

  return (
    <BaseComposer
      value={''}
      onChange={() => {}}
      handleSubmit={() => {}}
      showBurgerIcon={false}
      aria-label={t('loading_composer_label')}
      placeholder={t('loading_composer_label')}
    />
  );
};

export default LoadingComposer;
