import { useState } from 'react';

const useRows = (initalRows = []) => {
  const [rows, setRows] = useState(
    initalRows.map(({ key, value }) => ({ key, value }))
  );

  const handleNewRow = () => {
    setRows(rows => rows.concat({ key: '', value: '' }));
  };

  const handleEditKey = index => option => {
    setRows(
      rows.map((row, key) => {
        if (key === index) {
          return { value: row.value, key: option ? option.value : null };
        }

        return row;
      })
    );
  };

  const handleEditValue = index => event => {
    const value = event.target.value;

    setRows(
      rows.map((row, key) => {
        if (key === index) {
          return { key: row.key, value };
        }
        return row;
      })
    );
  };

  const handleDeleteRow = index => () => {
    setRows(rows => rows.filter((_, key) => key !== index));
  };

  const valid = rows.every(({ key, value }) => key && value);

  return {
    rows,
    setRows,
    handleNewRow,
    handleEditKey,
    handleEditValue,
    handleDeleteRow,
    valid
  };
};

export default useRows;
