import dayjs from 'dayjs';

/**
 * Check whether the intent is mapped to an FAQ or SmallTalk,
 * generating the correct link to the edit page.
 */
const getIntentLink = ({ faq, smallTalk }) => {
  if (smallTalk) {
    return `/smalltalk/${smallTalk.id}/edit`;
  }

  if (faq) {
    return `/faqs/${faq.id}/questions`;
  }

  return null;
};

const getIntentName = ({ name, faq, faqs }) => {
  // If the intent is shared (has multiple FAQS) don't return the location.
  if (faqs?.length > 1) {
    return name;
  }

  // If the FAQ is a single FAQ from a single location, show the location name.
  if (faq?.location) {
    return `${name} (${faq.location.name})`;
  }

  // For standard small talk and FAQs.
  return name;
};

const createTrainingMessage = ({
  is_training,
  started_training_at,
  finished_training_at
}) => {
  return is_training
    ? `Started training ${dayjs(started_training_at).from(dayjs())}`
    : `Last trained ${dayjs(finished_training_at).from(dayjs())}`;
};

const tableMessage = message => {
  return message.length >= 100 ? message.substring(0, 100) + '...' : message;
};

export { getIntentLink, getIntentName, createTrainingMessage, tableMessage };
