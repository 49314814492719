import React from 'react';
import { Link } from 'react-router-dom';

import {
  PageWrapper,
  Flex,
  ActionMenu,
  Button
} from '@ubisend/pulse-components';

import { Messages, Train, Filter } from '../Components/index';
import { useFilters } from '../hooks/index';

const Training = () => {
  const { filters, props, pagination, order } = useFilters();

  return (
    <PageWrapper
      header="Training"
      subheader="Train and improve your chatbot's knowledge"
      actions={
        <Flex xSpace>
          <ActionMenu
            buttonProps={{
              'aria-label': 'Toggle filter menu',
              variant: 'secondary',
              icon: 'filter',
              children: 'Filter'
            }}>
            <Filter {...props} />
          </ActionMenu>
          <Button
            variant="secondary"
            as={Link}
            icon="check"
            to="/training/trained">
            Trained
          </Button>
          <Train />
        </Flex>
      }>
      <Messages
        query="training"
        filters={filters}
        pagination={pagination}
        order={order}
      />
    </PageWrapper>
  );
};

export default Training;
