import VideoInput from './VideoInput';

const video = {
  id: 'video',
  name: 'Video',
  Input: VideoInput,
  content: {
    url: '',
    text: '',
    provider: 'youtube',
    content_description: ''
  },
  valid: content => {
    return (
      content.url.length > 0 &&
      content.text.length > 0 &&
      content.content_description?.length > 0
    );
  },
  snippet: `{
    "type": "video",
    "content": {
      "url": "",
      "text": "",
      "provider": "youtube",
      "content_description": ""
    }
}`
};

export default video;
