import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  useNotification,
  Flex,
  Placeholder,
  Button
} from '@ubisend/pulse-components';

import { updateActiveHours } from '../api/index';
import WeekDay from './WeekDay';
import { reduceToLocalTime, reduceToUtcTime } from '../utilities/index';

const ActiveHours = ({ query, disabled = false }) => {
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const { data } = useQuery(query);
  const { mutate } = useMutation(updateActiveHours, {
    onSuccess: () => {
      showSuccess(`Successfully updated active hours.`);
      queryClient.invalidateQueries(query);
    }
  });

  const [hours, setHours] = useState();

  useEffect(() => {
    if (!hours && data) {
      setHours(Object.entries(data.data).reduce(reduceToLocalTime, {}));
    }
  }, [data, hours]);

  const updateHours = (day, dayHours) => {
    setHours(hours => ({ ...hours, [day]: dayHours }));
  };

  const handleTimeUpdate = (day, newTimes) => updateHours(day, newTimes);

  const toggleAvailability = (day, open) => {
    if (open) {
      return updateHours(day, { open: '09:00:00', close: '17:00:00' });
    }

    return updateHours(day, { open: null, close: null });
  };

  const handleSubmit = () => {
    mutate({
      url: query,
      hours: Object.entries(hours).reduce(reduceToUtcTime, {})
    });
  };

  if (!hours) {
    return <Placeholder />;
  }

  return (
    <Flex ySpace col>
      {Object.keys(hours).map(day => (
        <WeekDay
          key={day}
          day={day}
          times={hours[day]}
          handleTimeUpdate={handleTimeUpdate}
          toggleAvailability={toggleAvailability}
          disabled={disabled}
        />
      ))}
      {!disabled && (
        <Flex right>
          <Button variant="secondary" onClick={handleSubmit} icon="save">
            Save
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

ActiveHours.propTypes = {
  query: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default ActiveHours;
