import axios from 'axios';

const getConversation = id => axios.get(`conversations/builder/${id}`);

const createConversation = conversation => {
  return axios.post(`conversations/builder`, conversation);
};

const updateConversation = (id, conversation) => {
  return axios.put(`conversations/builder/${id}`, conversation);
};

const deleteConversation = id => axios.delete(`conversations/builder/${id}`);

const createStep = (conversationId, step) => {
  return axios.post(`conversations/builder/${conversationId}/steps`, step);
};

const updateStep = (conversationId, stepId, step) => {
  return axios.put(
    `conversations/builder/${conversationId}/steps/${stepId}`,
    step
  );
};

const deleteStep = (conversationId, stepId) => {
  return axios.delete(
    `conversations/builder/${conversationId}/steps/${stepId}`
  );
};

const toggleLockConversation = (conversationId, boolean) => {
  return axios.put(`conversations/builder/${conversationId}/toggleLocked`, {
    locked: boolean
  });
};

export {
  getConversation,
  createConversation,
  updateConversation,
  deleteConversation,
  createStep,
  updateStep,
  deleteStep,
  toggleLockConversation
};
