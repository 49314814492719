import array from './Array/index';
import boolean from './Boolean/index';
import float from './Float/index';
import integer from './Integer/index';
import string from './String/index';

const allTypes = [string, integer, array, boolean, float];

const types = {
  array,
  boolean,
  float,
  integer,
  string,
  default: allTypes,
  all: allTypes
};

export default types;
