import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

import {
  flex,
  size,
  spacing,
  position,
  grid,
  border,
  colours,
  text
} from '../styles';

const Flex = styled(motion.div)`
  ${tw`flex`}

  ${flex}
  ${size}
  ${spacing}
  ${position}
  ${grid}
  ${border}
  ${colours}
  ${text}

  box-sizing: border-box;
`;

export default Flex;
