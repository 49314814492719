import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import { Button, useNotification } from '@ubisend/pulse-components';

import { useImports } from '../hooks/index';
import { approveSectionContent } from '../api/index';
import { ContentDiff, ActionsPortal } from '../Components/index';

const Version = () => {
  const { item, section, version, dispatch } = useImports();
  const { showSuccess } = useNotification();

  const handleApprove = async () => {
    await approveSectionContent(item.id, section.id, version.id);

    dispatch({
      type: 'APPROVE_SECTION_CONTENT',
      import: item,
      section,
      content: version
    });
    showSuccess('Successfully approved content and made current version');
  };

  if (!version) {
    return (
      <Redirect
        to={`/knowledge-bases/${item.id}/sections/${section.id}/content`}
      />
    );
  }

  return (
    <>
      <ContentDiff before={section.content.content} after={version.content} />
      <ActionsPortal>
        <Button onClick={handleApprove}>Approve</Button>
        <Button
          as={Link}
          variant="secondary"
          to={`/knowledge-bases/${item.id}/sections/${section.id}`}>
          Edit current
        </Button>
      </ActionsPortal>
    </>
  );
};

export default Version;
