import { hasPermission } from '@ubisend/pulse-auth';

import { Training, TrainedMessages, SemanticTraining } from './Pages/index';

const routes = [
  {
    path: '/training',
    component: Training,
    name: 'Training',
    canAccess: hasPermission('view training messages'),
    actions: [
      {
        title: 'Train your chatbot',
        description: "Improve your chatbot's performance.",
        icon: 'cog'
      },
      {
        title: "View your chatbot's training history",
        description: "Review your chatbot's latest improvements.",
        icon: 'cog'
      },
      {
        title: 'Review sent messages',
        description: "Assess and improve your chatbot's understanding.",
        icon: 'chat'
      }
    ]
  },
  {
    path: '/semantic-search/explore',
    component: SemanticTraining,
    name: 'Semantic Searches',
    disableBreadCrumbs: true,
    canAccess: hasPermission('view knowledge bases')
  },
  {
    path: '/training/trained',
    component: TrainedMessages,
    name: 'Trained Messages',
    canAccess: hasPermission('view training messages')
  }
];

export default routes;
