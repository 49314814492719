import React from 'react';
import dayjs from 'dayjs';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  PageWrapper,
  Placeholder,
  Flex,
  NoSubcriberGraphic,
  Heading3,
  Panel,
  Label,
  Heading2,
  Paragraph,
  LeftHalfLayout
} from '@ubisend/pulse-components';
import {
  SubscriberDetails,
  DetailsPanel,
  MainSection,
  NoSubscriber,
  TopSection
} from '@ubisend/pulse-tickets';

const Feedback = ({ match }) => {
  const { isLoading, isSuccess, data } = useQuery(
    `feedback/${match.params.id}`
  );

  return (
    <Flex>
      <MainSection>
        <PageWrapper header="Feedback">
          <Flex ySpace col>
            <Panel>
              <Flex col mb>
                <TopSection>
                  <Heading2>Feedback Details</Heading2>
                </TopSection>
                {isSuccess && (
                  <Label secondary>
                    {dayjs(data.data.created_at).from(dayjs())} by Subscriber #
                    {data.data.subscriber.id}
                  </Label>
                )}
              </Flex>
              <Flex>
                {isSuccess && (
                  <LeftHalfLayout>
                    <Label>Feedback</Label>
                    <Paragraph>{data.data.message}</Paragraph>
                  </LeftHalfLayout>
                )}
              </Flex>
            </Panel>
          </Flex>
        </PageWrapper>
      </MainSection>
      <DetailsPanel divider header="Subscriber Details">
        {isLoading && <Placeholder ySpace />}
        {!isLoading && data.data.subscriber && (
          <SubscriberDetails subscriber={data.data.subscriber} />
        )}
        {!isLoading && !data.data.subscriber && (
          <NoSubscriber col center ySpace middle>
            <NoSubcriberGraphic />
            <Heading3>No Subscriber attached to this log</Heading3>
          </NoSubscriber>
        )}
      </DetailsPanel>
    </Flex>
  );
};

export default Feedback;
