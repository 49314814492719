import dayjs from 'dayjs';

/**
 * Format a message sent from the subscriber, using the text input.
 */
const formatClientMessage = ({
  id = null,
  type = 'standard',
  content,
  sent_at
}) => ({
  id: id || Math.random(),
  type,
  composer: null,
  direction: 'toServer',
  content,
  sentAt: sent_at ? dayjs(sent_at) : dayjs()
});

/**
 * Format a message sent from the server (the bot).
 */
const formatServerMessage = ({
  id = null,
  type,
  composer,
  content,
  agent = null,
  sent_at = null
}) => ({
  id,
  type,
  composer,
  direction: 'toClient',
  content,
  agent,
  sentAt: sent_at ? dayjs(sent_at) : null
});

/**
 * Message types that look like they are sent by the system.
 * e.g. 'User has joined the chat.'
 */
const systemMessageTypes = ['event'];

/**
 * Loop through every message, grouping by the type of user sending.
 * The 3 types of group are:
 * - Bot message: automatically sent by the bot e.g. an FAQ.
 * - Human message: a message that a CMS user has sent e.g. Live Chat.
 * - Subscriber message: message the user has typed in the widget and sent.
 *
 * E.g.
 * [botMessage, subscriberMessage, subscriberMessage, liveChatMessage]
 * would result in:
 * [[botMessage], [subscriberMessage, subscriberMessage], [liveChatMessage]]
 */
const groupMessages = (allGroups, message) => {
  // First message, initialise the first group.
  if (allGroups.length === 0) {
    return [[message]];
  }

  const lastGroup = allGroups[allGroups.length - 1];
  const lastMessage = lastGroup[lastGroup.length - 1];
  const needsOwnGroup = type => systemMessageTypes.includes(type);

  if (needsOwnGroup(message.type)) {
    // Create a new group.
    return allGroups.concat([[message]]);
  }

  // If the message is from the same place (server or client) and
  // sent by the same agent add to the group.
  // Don't concat message if previous message requires it's own group.
  if (
    lastMessage.direction === message.direction &&
    !needsOwnGroup(lastMessage.type)
  ) {
    if (lastMessage.agent?.id === message.agent?.id) {
      allGroups[allGroups.length - 1] = lastGroup.concat(message);
      return allGroups;
    }
  }

  // Create a new group.
  return allGroups.concat([[message]]);
};

export {
  formatClientMessage,
  formatServerMessage,
  groupMessages,
  systemMessageTypes
};
