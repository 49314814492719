import React from 'react';
import PropTypes from 'prop-types';

import {
  TextInput,
  InnerPanel,
  Flex,
  Label,
  Tooltip,
  Accordion
} from '@ubisend/pulse-components';

import AllLanguagesSelect from './AllLanguagesSelect';
import CountrySelect from './CountrySelect';

const ClientDetails = ({ client, handleInputChange, handleSelectChange }) => {
  return (
    <Flex col pad>
      <InnerPanel mb>
        <Flex col>
          <Flex col fat ySpace>
            <Flex col>
              <Label htmlFor="name">Name</Label>
              <TextInput
                name="name"
                onChange={handleInputChange('name')}
                value={client.name}
                id="name"
                placeholder="Joe Bloggs"
              />
            </Flex>
            <Flex col>
              <Label htmlFor="email">Email</Label>
              <TextInput
                name="email"
                onChange={handleInputChange('email')}
                value={client.email}
                id="email"
                placeholder="joe@bloggs.com"
              />
            </Flex>
            <Flex mb col>
              <Flex xSpace>
                <Label htmlFor="language">Default Language</Label>
                <Tooltip
                  tooltip={
                    <Tooltip.Content>
                      Responses and FAQs can be written in multiple languages.
                      The default will be used when building these, as well as
                      the language for new subscribers.
                    </Tooltip.Content>
                  }>
                  <Tooltip.Icon>?</Tooltip.Icon>
                </Tooltip>
              </Flex>
              <AllLanguagesSelect
                name="language"
                id="language"
                value={client.language_id}
                onChange={handleSelectChange('language_id')}
              />
            </Flex>
          </Flex>
        </Flex>
      </InnerPanel>

      <Accordion header="Additional Details">
        <Flex col ySpace fat>
          <Flex col>
            <Label htmlFor="phone">Phone</Label>
            <TextInput
              name="phone"
              onChange={handleInputChange('phone')}
              value={client.phone}
              id="phone"
              placeholder="012345678900"
            />
          </Flex>
          <Flex col>
            <Label htmlFor="address_1">Address line 1</Label>
            <TextInput
              name="address_1"
              onChange={handleInputChange('address_1')}
              value={client.address_1}
              id="address_1"
              placeholder="1, A Street"
            />
          </Flex>

          <Flex col>
            <Label htmlFor="address_2">Address line 2</Label>
            <TextInput
              name="address_2"
              onChange={handleInputChange('address_2')}
              value={client.address_2 || ''}
              id="address_2"
              placeholder="A Place"
            />
          </Flex>
          <Flex col>
            <Label htmlFor="city">City</Label>
            <TextInput
              name="city"
              onChange={handleInputChange('city')}
              value={client.city}
              id="city"
              placeholder="London"
            />
          </Flex>
          <Flex col>
            <Label htmlFor="region">Region</Label>
            <TextInput
              name="region"
              onChange={handleInputChange('region')}
              value={client.region}
              id="region"
              placeholder="London"
            />
          </Flex>
          <Flex col>
            <Label htmlFor="postcode">Postcode</Label>
            <TextInput
              name="postcode"
              onChange={handleInputChange('postcode')}
              value={client.postcode}
              id="postcode"
              placeholder="SW1A 2AA"
            />
          </Flex>
          <Flex col>
            <Label htmlFor="country">Country</Label>
            <CountrySelect
              name="country"
              id="country"
              value={client.country}
              onChange={handleSelectChange('country')}
            />
          </Flex>
        </Flex>
      </Accordion>
    </Flex>
  );
};

ClientDetails.propTypes = {
  client: PropTypes.any.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleNewLogo: PropTypes.func.isRequired
};

export default ClientDetails;
