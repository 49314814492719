import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Paragraph from '../Text/Paragraph';
import { Flex } from '../Layout/index';

const TitleContainer = styled(Flex)`
  ${tw`mr-10 mt-4`}
  & > h2 {
    ${tw`my-0 font-semibold`}
  }
`;

const InfoContainer = styled(Paragraph)`
  ${tw`pr-4 pb-2 leading-normal`}
`;

const InfoSection = ({ title, info, actions }) => (
  <>
    <TitleContainer between center>
      <h2>{title}</h2>
    </TitleContainer>
    <InfoContainer sm secondary>
      {info}
    </InfoContainer>
    {actions}
  </>
);

InfoSection.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
  actions: PropTypes.any
};

export default InfoSection;
