import Messenger from './Messenger';
import Workplace from './Workplace';
import Teams from './Teams';
import Telegram from './Telegram';
import Pulse from './Pulse';
import Brosix from './Brosix';
import Cisco from './Cisco';
import Connecteam from './Connecteam';
import Discord from './Discord';
import Google from './Google';
import Hangouts from './Hangouts';
import Instagram from './Instagram';
import Kakaotalk from './Kakaotalk';
import Apple from './Apple';
import Kik from './Kik';
import Linkedin from './Linkedin';
import Mattermost from './Mattermost';
import Slack from './Slack';
import Twitter from './Twitter';
import Viber from './Viber';
import WeChat from './WeChat';
import Whatsapp from './Whatsapp';
import Proprietary from './Proprietary';

const channelIcons = {
  messenger: Messenger,
  workplace: Workplace,
  teams: Teams,
  telegram: Telegram,
  pulse: Pulse,
  brosix: Brosix,
  cisco: Cisco,
  connecteam: Connecteam,
  discord: Discord,
  google: Google,
  hangouts: Hangouts,
  instagram: Instagram,
  kakaotalk: Kakaotalk,
  apple: Apple,
  kik: Kik,
  linkedin: Linkedin,
  mattermost: Mattermost,
  slack: Slack,
  twitter: Twitter,
  viber: Viber,
  wechat: WeChat,
  whatsapp: Whatsapp,
  proprietary: Proprietary
};

export default channelIcons;
