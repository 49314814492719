import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Container,
  LeftSection,
  ComposerButton,
  Airplane,
  Input,
  Label,
  labelAnimations
} from '../Custom/MessageComposer';
import { useDelivery } from '../../../../../hooks/index';

const DateComposer = () => {
  const [inputActive, setInputActive] = useState(false);
  const [step, setStep] = useState('EMAIL');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { t } = useTranslation('full_page');

  const { sendMessage } = useDelivery();

  const handleEmailSubmit = () => {
    setStep('PASSWORD');
  };

  const handlePasswordSubmit = () => {
    sendMessage(
      `Authentication details`,
      `user(${email}) password(${password})`
    );
    setEmail('');
    setPassword('');
    setStep('EMAIL');
  };

  const handleClick = () => {
    if (step === 'EMAIL' && email.length > 4) {
      handleEmailSubmit();
    } else if (password.length > 4) {
      handlePasswordSubmit();
    }
  };

  const handleInputChange = event => {
    const value = event.target.value;

    if (step === 'EMAIL') {
      setEmail(value);
    } else {
      setPassword(value);
    }
  };

  const handleOnKeyDown = event => {
    if (event.keyCode === 13) {
      handleClick();
    }
  };

  const handleBlur = () => {
    const reply = step === 'EMAIL' ? email : password;

    if (inputActive && reply.length === 0) {
      setInputActive(false);
    }
  };

  const handleFocus = () => {
    if (!inputActive) {
      setInputActive(true);
    }
  };

  return (
    <Container>
      <LeftSection>
        <Label
          htmlFor={step === 'EMAIL' ? 'email-composer' : 'password-composer'}
          initial="expanded"
          variants={labelAnimations}
          animate={inputActive ? 'minimised' : 'expanded'}>
          {t(
            step === 'EMAIL'
              ? 'auth_email_composer_label'
              : 'auth_password_composer_label'
          )}
        </Label>
        <Input
          id={step === 'EMAIL' ? 'email-composer' : 'password-composer'}
          value={step === 'EMAIL' ? email : password}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleOnKeyDown}
        />
      </LeftSection>
      <ComposerButton aria-label={t('send_button_label')} onClick={handleClick}>
        <Airplane />
      </ComposerButton>
    </Container>
  );
};

export default DateComposer;
