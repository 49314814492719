import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalBody,
  Flex,
  PanelTab,
  NoResults,
  Grid,
  Pagination,
  ModalClose,
  Heading2,
  Divider,
  Placeholder,
  StretchPanel,
  InnerPanel
} from '@ubisend/pulse-components';

import types from './Types/index';
import FilePreview from './FilePreview';
import CreateFile from './CreateFile';
import SourceSelect from './SourceSelect';
import { formatFileSource } from '../Pages/FileSources/utils/index';
import { FileSourceContext } from '../Contexts/index';

const defaultTypes = [
  types.image,
  types.spreadsheet,
  types.pdf,
  types.document
];

const File = styled(StretchPanel)`
  ${tw`w-full cursor-pointer`}

  aspect-ratio: 1 / 1;

  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 0.5;
  }
`;

const useFiles = ({ filesQueryKey, sourcesQueryKey, types }) => {
  const sourcesQuery = useQuery(sourcesQueryKey);

  const [filters, setFilters] = useState({
    source_id: sourcesQuery.isSuccess ? sourcesQuery.data.data[0].id : null,
    page: 1,
    type: types[0].type,
    uploaded_by: 'user',
    subscriber_id: null
  });

  const sourceQuery = useQuery(`${sourcesQueryKey}/${filters.source_id}`, {
    enabled: Boolean(filters.source_id)
  });

  const updateFilters = useCallback(newFilters => {
    setFilters(oldFilters => ({ ...oldFilters, ...newFilters }));
  }, []);

  const query = useQuery([filesQueryKey, filters], {
    enabled: Boolean(filters.source_id)
  });

  useEffect(() => {
    if (sourcesQuery.isSuccess) {
      updateFilters({ source_id: sourcesQuery.data.data[0].id });
    }
  }, [sourcesQuery.isSuccess, sourcesQuery.data, updateFilters]);

  return {
    filters,
    setFilters,
    source: sourceQuery.isSuccess
      ? formatFileSource(sourceQuery.data.data)
      : null,
    updateFilters,
    query
  };
};

const FileSelect = ({
  filesQueryKey = 'files',
  sourcesQueryKey = 'files/sources',
  types = defaultTypes,
  handleFileSelect,
  handleCancel
}) => {
  const { filters, source, updateFilters, query } = useFiles({
    filesQueryKey,
    sourcesQueryKey,
    types
  });

  const handleTypeChange = type => {
    updateFilters({ type, page: 1 });
  };

  const handleSourceChange = source => {
    updateFilters({ source_id: source.value });
  };

  const paginateFiles = page => updateFilters({ page });

  const isLoading = query.isLoading || query.isIdle || !source;

  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent
          style={{
            width: '100%',
            maxWidth: '60rem'
          }}>
          <Flex between fat pad center middle>
            <Heading2>Choose a file</Heading2>
            <ModalClose onClick={handleCancel} />
          </Flex>
          <Divider mtNone mbNone />
          <ModalBody style={{ paddingTop: 0 }}>
            <FileSourceContext.Provider value={source}>
              <Flex>
                <Flex col mr borderRight pr pt style={{ width: '16rem' }}>
                  <Flex col tall between>
                    <Flex col mb>
                      <Flex mb>
                        <SourceSelect
                          aria-label="Source"
                          queryKey={sourcesQueryKey}
                          value={filters.source_id}
                          onChange={handleSourceChange}
                        />
                      </Flex>
                      {types.map((mappedType, key) => (
                        <PanelTab
                          key={key}
                          disabled={isLoading}
                          icon={{ type: mappedType.icon, colour: 'grey' }}
                          active={mappedType.type === filters.type}
                          onClick={() => handleTypeChange(mappedType.type)}>
                          {mappedType.name}
                        </PanelTab>
                      ))}
                    </Flex>
                    <CreateFile queryKey={filesQueryKey} type={filters.type} />
                  </Flex>
                </Flex>
                {isLoading && (
                  <Flex col pl pr pt fat>
                    <Grid columns={4}>
                      {[0, 1, 2, 3, 4, 5].map(i => (
                        <InnerPanel key={i}>
                          <Flex fat>
                            <Placeholder items={1} />
                          </Flex>
                        </InnerPanel>
                      ))}
                    </Grid>
                  </Flex>
                )}
                {!isLoading && query.isSuccess && query.data.data.length === 0 && (
                  <Flex middle center fat mt>
                    <NoResults text="No files found" />
                  </Flex>
                )}
                {!isLoading && query.isSuccess && query.data.data.length > 0 && (
                  <Flex
                    col
                    ySpace
                    fat
                    mt
                    style={{
                      maxHeight: '30rem',
                      overflowY: 'auto'
                    }}>
                    <Grid columns={4}>
                      {query.data.data.map((file, key) => (
                        <File
                          key={key}
                          title={`file-select-${key + 1}`}
                          onClick={() => handleFileSelect(file)}>
                          <FilePreview file={file} type={filters.type} />
                        </File>
                      ))}
                    </Grid>
                    <Flex shiftLeft>
                      <Pagination
                        pagination={query.data.meta}
                        handlePageChange={paginateFiles}
                      />
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </FileSourceContext.Provider>
          </ModalBody>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

FileSelect.propTypes = {
  handleFileSelect: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      icon: PropTypes.string
    })
  ),
  filesQueryKey: PropTypes.string,
  sourcesQueryKey: PropTypes.string
};

export default FileSelect;
