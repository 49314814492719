import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { motion } from '@ubisend/framer-motion';

import PulsingNotification from './PulsingNotification';
import { useTheme } from '../../../../../hooks/index';

const NotificationSymbol = styled(motion.div)`
  ${tw`w-5 h-5 absolute rounded-full`}
  background: ${props => (props.inverse ? props.gradient.from : 'white')};
  left: -0.25rem;
  & > span {
    ${tw`font-bold font-poppins`}
    color: ${props => (props.inverse ? 'white' : props.gradient.from)}
  }
  margin-top: -1.375rem;
`;

const Number = styled(motion.span)`
  ${tw`flex justify-center items-center`};
  line-height: 1.375rem;
`;

const NotificationDot = ({ showNumber, children, inverse, scale }) => {
  const theme = useTheme();
  return (
    <div style={{ transform: `scale(${scale})`, transformOrigin: 'left' }}>
      {!showNumber && <PulsingNotification inverse={inverse} {...theme} />}
      <NotificationSymbol
        inverse={inverse}
        showNumber={showNumber}
        {...theme}
        animate={
          showNumber ? { scale: 1, x: 0, y: 0 } : { scale: 0.375, x: 9, y: -9 }
        }>
        <Number animate={showNumber ? { opacity: 1 } : { opacity: 0 }}>
          {children}
        </Number>
      </NotificationSymbol>
    </div>
  );
};

NotificationDot.propTypes = {
  showNumber: PropTypes.bool,
  inverse: PropTypes.bool,
  scale: PropTypes.number
};

export default NotificationDot;
