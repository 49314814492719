import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  PanelSlider,
  Heading2,
  Divider,
  Placeholder,
  Select,
  Button,
  Flex,
  formatSelectOption,
  useNotification
} from '@ubisend/pulse-components';

import { LicenseSummary, LicensePeriod } from '../Components/index';
import { updateLicense } from '../api/index';

const AllClientSelect = ({ value, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('owner/clients/all');

  return (
    <Select
      isClearable
      isLoading={isLoading}
      options={isSuccess && data.data.map(formatSelectOption)}
      value={
        isSuccess &&
        value &&
        formatSelectOption(data.data.find(({ id }) => value === id))
      }
      {...props}
    />
  );
};

AllClientSelect.propTypes = {
  value: PropTypes.number
};

const LicenseAccount = ({ id, client: initalClient }) => {
  const [client, setClient] = useState(initalClient ? initalClient.id : null);

  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const { mutate } = useMutation(updateLicense, {
    onSuccess: () => {
      showSuccess('Successfully updated license.');
      queryClient.invalidateQueries('owner/clients');
      queryClient.invalidateQueries('owner/licenses');
      queryClient.invalidateQueries('owner/licenses/summary');
    }
  });

  const handleClientChange = option => {
    setClient(option ? option.value : null);
  };

  const handleSubmit = event => {
    event.preventDefault();

    mutate({ id, client_id: client });
  };

  return (
    <>
      <Heading2>Attached Account</Heading2>
      <form onSubmit={handleSubmit}>
        <Flex col ySpace>
          <AllClientSelect value={client} onChange={handleClientChange} />
          <Flex right>
            <Button type="submit" icon="save">
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </>
  );
};

LicenseAccount.propTypes = {
  id: PropTypes.number.isRequired,
  client: PropTypes.shape({
    id: PropTypes.number
  })
};

const License = ({ match }) => {
  const { data, isLoading, isSuccess } = useQuery(
    `owner/licenses/${match.params.id}`
  );
  const history = useHistory();

  const handleHide = () => {
    history.push('/owner/licenses');
  };

  return (
    <PanelSlider width="400px" header="Manage License" handleHide={handleHide}>
      {isLoading && <Placeholder />}
      {isSuccess && (
        <>
          <LicenseSummary {...data.data} />
          <Divider fullWidth />
          <LicenseAccount {...data.data} />
          <Divider fullWidth />
          <Heading2>History</Heading2>
          {data.data.periods.map((period, key) => (
            <LicensePeriod
              key={key}
              index={key}
              period={period}
              periods={data.data.periods}
            />
          ))}
        </>
      )}
    </PanelSlider>
  );
};

export default License;
