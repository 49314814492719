import React, { useEffect, useState, useMemo } from 'react';
import { Prompt } from 'react-router-dom';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  Label,
  LeftHalfLayout,
  FormGroup,
  RightHalfLayout,
  Paragraph,
  Button,
  Flex,
  Panel,
  Placeholder,
  useNotification,
  useTheme
} from '@ubisend/pulse-components';
import { GroupSelect } from '@ubisend/pulse-groups';
import { PermissionFilter } from '@ubisend/pulse-auth';
import Icon from '@ubisend/pulse-icons';

import AssigneeSelect from './AssigneeSelect';
import TopSection from './TopSection';
import { updateTicket } from '../api/index';
import { getTicket } from '../utilities/index';

const Unsaved = props => {
  const theme = useTheme();

  return (
    <Flex center xSpaceSm {...props}>
      <Icon
        stroke={theme.grey}
        width="1.5rem"
        height="1.5rem"
        size="1.5rem"
        outline
        type="exclamation"
      />
      <Label mb={false} secondary>
        Unsaved changes
      </Label>
    </Flex>
  );
};

const TicketDetails = ({ ticket }) => {
  const { showSuccess } = useNotification();
  const [editedTicket, setEditedTicket] = useState(null);
  const queryClient = useQueryClient();

  const isLoading = !editedTicket || !ticket;

  useEffect(() => {
    if (!ticket) {
      // If the ticket is null, we are loading a new ticket, so we need to reset state
      return setEditedTicket(null);
    }
    if (editedTicket) {
      // Only set ticket if it doesnt already exist and query has completed.
      return;
    }

    setEditedTicket({
      assignee_id: ticket.assignee?.id,
      groups: ticket.groups.map(group => group.id)
    });
  }, [editedTicket, ticket]);

  const updateStateTicket = object => {
    setEditedTicket(ticket => ({ ...ticket, ...object }));
  };

  const { mutate } = useMutation(updateTicket, {
    onSuccess: () => {
      showSuccess('Successfully updated ticket');
      queryClient.invalidateQueries(`tickets/ubidesk/${ticket.id}`);
    }
  });

  const handleGroupsChange = (groups = null) => {
    updateStateTicket({
      groups: groups ? groups.map(group => group.value) : []
    });
  };

  const handleAssigneeChange = option => {
    updateStateTicket({ assignee_id: option ? option.value : null });
  };

  const handleUpdate = () => {
    mutate({
      ...getTicket(ticket),
      assignee_id: editedTicket.assignee_id,
      groups: editedTicket.groups
    });
  };

  const ticketHasChanged = useMemo(() => {
    if (!ticket || !editedTicket) {
      return false;
    }

    return (
      // Allows for null and undefined to be considered equal
      ticket.assignee?.id !== editedTicket.assignee_id ||
      JSON.stringify(ticket.groups.map(group => group.id)) !==
        JSON.stringify(editedTicket.groups)
    );
  }, [editedTicket, ticket]);

  return (
    <Panel>
      <Prompt
        when={ticketHasChanged}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      {isLoading && <Placeholder items={1} subitems={2} mb />}
      {!isLoading && (
        <Flex col mb>
          <TopSection between top>
            <h2>{ticket.name}</h2>
            {ticketHasChanged && <Unsaved />}
          </TopSection>
          <Label secondary>
            {dayjs(ticket.created_at).from(dayjs())} by{' '}
            {ticket.subscriber ? `Subscriber #${ticket.subscriber.id}` : 'N/A'}
          </Label>
        </Flex>
      )}
      <Flex mb>
        <LeftHalfLayout>
          <Label htmlFor="description">Description</Label>
          {isLoading ? (
            <Placeholder items={1} subitems={3} />
          ) : (
            <Paragraph>{ticket.description}</Paragraph>
          )}
        </LeftHalfLayout>
        <RightHalfLayout>
          <FormGroup>
            <Label htmlFor="tags">Tags</Label>
            <GroupSelect
              isLoading={isLoading}
              id="tags"
              value={editedTicket?.groups}
              onChange={handleGroupsChange}
              for="tickets"
            />
          </FormGroup>
          <PermissionFilter can="view agents">
            <FormGroup>
              <Label htmlFor="assignees">Assigned To</Label>
              <AssigneeSelect
                isLoading={isLoading}
                id="assignees"
                value={editedTicket?.assignee_id}
                onChange={handleAssigneeChange}
              />
            </FormGroup>
          </PermissionFilter>
        </RightHalfLayout>
      </Flex>
      <Flex right xSpace center>
        <Button
          disabled={!ticketHasChanged || isLoading}
          onClick={handleUpdate}
          icon="save">
          Save
        </Button>
      </Flex>
    </Panel>
  );
};

TicketDetails.propTypes = {
  ticket: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    bot_could_answer: PropTypes.bool.isRequired,
    subscriber: PropTypes.object,
    assignee: PropTypes.object,
    groups: PropTypes.array.isRequired,
    created_at: PropTypes.string
  })
};

export default TicketDetails;
