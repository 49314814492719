import { useCallback, useState } from 'react';
import dayjs from 'dayjs';

import {
  usePaginationReducer,
  useOrderableTableReducer
} from '@ubisend/pulse-components';
import { useLanguages } from '@ubisend/pulse-hooks';

const defaultStart = new Date(dayjs().subtract(1, 'weeks'));
const defaultEnd = new Date(dayjs().add('1', 'hours'));

const useFilters = () => {
  const { defaultLanguage } = useLanguages();

  const getDefaultFilters = useCallback(() => {
    return {
      start: defaultStart,
      end: defaultEnd,
      type: null,
      threshold: null,
      locations: [],
      language_id: defaultLanguage.id,
      order: 'sent_at',
      direction: 'descending'
    };
  }, [defaultLanguage]);

  const [filters, setFilters] = useState(getDefaultFilters());

  const order = useOrderableTableReducer();
  const pagination = usePaginationReducer();

  const handleFilterChange = callback => {
    pagination.resetPage();
    setFilters(filters => ({ ...filters, ...callback(filters) }));
  };

  const handleReset = () => {
    setFilters(getDefaultFilters());
  };

  const props = {
    filters,
    handleFilterChange,
    handleReset
  };

  return {
    filters,
    setFilters,
    order,
    pagination,
    getDefaultFilters,
    handleFilterChange,
    handleReset,
    props
  };
};

export default useFilters;
