import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { useQuery, useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import {
  AnimatePresence,
  AnimateSharedLayout,
  motion
} from '@ubisend/framer-motion';
import {
  Placeholder,
  Panel,
  UserIcon,
  Timeline,
  Profile,
  Flex,
  TextInput,
  Label,
  Paragraph
} from '@ubisend/pulse-components';
import { useAuth } from '@ubisend/pulse-auth';

import { createComment } from '../api/index';

const getInitals = agent => {
  return `${agent.first_name[0]}${agent.last_name[0]}`;
};

const CommentContainer = styled(motion.div)`
  ${tw`flex`}
  transform-origin: left center !important;
`;

const Comments = ({ query }) => {
  const { user } = useAuth();

  const [comment, setComment] = useState('');

  const queryClient = useQueryClient();
  const { data, isLoading, isSuccess } = useQuery(query);
  const { mutate } = useMutation(createComment, {
    onSuccess: () => queryClient.invalidateQueries(query)
  });

  const handleSubmit = event => {
    event.preventDefault();
    mutate({ url: query, comment });
    setComment('');
  };

  const handleCommentChange = event => setComment(event.target.value);

  return (
    <AnimateSharedLayout initial={false}>
      <Panel
        layout={!isLoading}
        transition={{ layoutY: { ease: [0.16, 1, 0.3, 1], duration: 0.6 } }}>
        {isLoading && <Placeholder />}
        {isSuccess && (
          <>
            <Flex>
              <Flex mrSm col center>
                <UserIcon layout>{getInitals(user)}</UserIcon>
                {data.data.length !== 0 && <Timeline />}
              </Flex>
              <Flex col fat mb={data.data.length > 0}>
                <Profile col middle>
                  <motion.form layout onSubmit={handleSubmit}>
                    <TextInput
                      aria-label="new-comment"
                      value={comment}
                      onChange={handleCommentChange}
                      placeholder="Add a comment"
                    />
                  </motion.form>
                </Profile>
              </Flex>
            </Flex>
            <AnimatePresence initial={false}>
              {data.data.map((comment, key) => (
                <CommentContainer
                  layout
                  key={comment.id}
                  initial={{ opacity: 0, scale: 0.75 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.75 }}
                  transition={{ ease: [0.16, 1, 0.3, 1], duration: 0.6 }}>
                  <Flex mrSm col center>
                    <UserIcon>{getInitals(comment.user)}</UserIcon>
                    {key !== data.data.length - 1 && <Timeline />}
                  </Flex>
                  <Flex col>
                    <Profile col middle>
                      <Label mb={false}>{comment.user.name}</Label>
                      <Label mb={false} secondary>
                        {dayjs(comment.created_at).from(dayjs())}
                      </Label>
                    </Profile>
                    <Flex mb={key !== data.data.length - 1}>
                      <Paragraph secondary>{comment.comment}</Paragraph>
                    </Flex>
                  </Flex>
                </CommentContainer>
              ))}
            </AnimatePresence>
          </>
        )}
      </Panel>
    </AnimateSharedLayout>
  );
};

Comments.propTypes = {
  query: PropTypes.string.isRequired
};

export default Comments;
export { getInitals, CommentContainer };
