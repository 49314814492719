import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { useQueryClient, useQuery, useMutation } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  PageWrapper,
  StretchPanel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadCell,
  TableCell,
  PanelSlider,
  TableActions,
  useModal,
  useNotification,
  Tag,
  Flex,
  Button,
  Pagination,
  usePaginationReducer,
  OrderableTableHeadCell,
  useOrderableTableReducer
} from '@ubisend/pulse-components';

import { Invites, EditUser, PermissionFilter } from '../Components/index';
import { deleteUser as deleteUserApi } from '../api/index';
import { useAuth } from '../hooks/index';

const TagTableCell = styled(TableCell)`
  max-width: 15vw;
  white-space: nowrap;
  overflow-y: auto;
`;

const Users = () => {
  const pagination = usePaginationReducer();
  const order = useOrderableTableReducer();

  const [showInvites, setShowInvites] = useState(false);
  const [editUser, setEditUser] = useState(null);

  const { user: loggedInUser } = useAuth();
  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();

  const queryClient = useQueryClient();
  const query = useQuery(['users', { ...pagination.params, ...order.params }]);
  const { mutateAsync: deleteUser } = useMutation(deleteUserApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      showSuccess(`User was successfully removed from the account.`);
    }
  });

  const toggleShowInvites = () => setShowInvites(show => !show);

  const handleDelete = ({ id, first_name, last_name, email }) => {
    showModal({
      header: 'Remove User',
      body: `Are you sure you want to remove ${first_name} ${last_name} (${email}) from the account? They can be invited again if required.`,
      handleConfirm: async () => {
        try {
          await deleteUser(id);
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <PageWrapper
      header="Users"
      subheader="Manage users and permissions"
      actions={
        <Flex xSpace>
          <PermissionFilter can="view roles">
            <Button as={Link} variant="secondary" to="/roles" icon="airplane">
              Roles
            </Button>
          </PermissionFilter>
          <PermissionFilter can="view invites">
            <Button
              variant="secondary"
              icon="airplane"
              onClick={toggleShowInvites}>
              Invites
            </Button>
          </PermissionFilter>
        </Flex>
      }>
      <StretchPanel clip>
        <Table loading={query.isLoading} loadingColumns={5}>
          <TableHead>
            <TableRow>
              <TableHeadCell>Name</TableHeadCell>
              <OrderableTableHeadCell
                row={{ label: 'Email', sort: 'email' }}
                {...order.props}
              />
              <OrderableTableHeadCell
                row={{ label: 'Joined', sort: 'created_at' }}
                {...order.props}
              />
              <PermissionFilter can="view roles">
                <TableHeadCell>Roles</TableHeadCell>
              </PermissionFilter>
              <TableHeadCell />
            </TableRow>
          </TableHead>
          {query.isSuccess && (
            <TableBody>
              {query.data.data.map((user, key) => (
                <TableRow key={key}>
                  <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{dayjs(user.created_at).from(dayjs())}</TableCell>
                  <PermissionFilter can="view roles">
                    <TagTableCell>
                      <Flex xSpaceSm xScroll>
                        {user.roles.length > 0
                          ? user.roles.map(role => (
                              <Tag key={role.id}>{role.name}</Tag>
                            ))
                          : 'N/A'}
                      </Flex>
                    </TagTableCell>
                  </PermissionFilter>
                  <TableActions>
                    <PermissionFilter can="edit users">
                      <Button
                        variant="secondary"
                        icon="pencilAlt"
                        onClick={() => setEditUser(user)}>
                        Edit
                      </Button>
                    </PermissionFilter>
                    <PermissionFilter can="delete users">
                      <Button
                        colour="danger"
                        variant="secondary"
                        icon="trash"
                        disabled={user.id === loggedInUser.id}
                        onClick={() => handleDelete(user)}>
                        Delete
                      </Button>
                    </PermissionFilter>
                  </TableActions>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {query.showPagination && (
          <Pagination pagination={query.data.meta} {...pagination.props} />
        )}
      </StretchPanel>
      <AnimatePresence>
        {showInvites && (
          <PanelSlider header="User Invites" handleHide={toggleShowInvites}>
            <Invites />
          </PanelSlider>
        )}
        {editUser && (
          <PanelSlider
            width={'640px'}
            header={`Editing ${editUser.name}`}
            handleHide={() => setEditUser(null)}>
            <EditUser user={editUser} />
          </PanelSlider>
        )}
      </AnimatePresence>
    </PageWrapper>
  );
};

export default Users;
