import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

import { useVariable } from '../../hooks/index';

const format = scope => {
  return {
    value: scope.name,
    label: scope.name
  };
};

const VariableScopeSelect = ({ value, ...props }) => {
  const { scopes } = useVariable();

  return (
    <Select
      options={scopes.map(scope => ({
        ...format(scope),
        isDisabled: false
      }))}
      value={value && format(scopes.find(scope => scope.name === value))}
      {...props}
    />
  );
};

VariableScopeSelect.propTypes = {
  value: PropTypes.any
};

export default VariableScopeSelect;
