import React from 'react';
import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

const Pulse = styled(motion.div)`
  ${tw`w-2 h-2 absolute rounded-full`}
  background : ${props => (props.inverse ? props.gradient.from : 'white')};
  margin-top: -1.55rem;
  left: 0.675rem;
`;

const PulsingNotification = props => (
  <Pulse
    transition={{
      duration: 1,
      repeat: Infinity,
      repeatType: 'loop',
      repeatDelay: 1,
      delay: 0.5
    }}
    initial={{ opacity: 0 }}
    animate={{ scale: [1, 2.5], opacity: [0.75, 0] }}
    {...props}
  />
);

export default PulsingNotification;
