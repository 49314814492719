import { useReducer, useMemo } from 'react';

const reducer = (state, { type, drivers, ...params }) => {
  const getDefaultSettings = type => {
    return drivers[type].settings.inital;
  };

  switch (type) {
    // Updating import
    case 'UPDATE_NAME':
      return { ...state, name: params.name };
    case 'UPDATE_TYPE':
      return {
        ...state,
        type: params.new_type,
        settings: getDefaultSettings(params.new_type),
        show_polling_interval: drivers[params.new_type].show_polling_interval
      };
    case 'UPDATE_POLLING_INTERVAL':
      return { ...state, polling_interval: params.polling_interval };

    // Updating driver specific settings.
    case 'UPDATE_SETTINGS':
      return { ...state, settings: { ...state.settings, ...params.settings } };

    // Navigation
    case 'GO_BACK':
      return { ...state, step: state.step - 1 };
    case 'GO_NEXT':
      return { ...state, step: state.step + 1 };
    default:
      throw new Error(`No event defined in useGandalfReducer for ${type}`);
  }
};

const defaultImport = {
  step: 0,
  name: '',
  type: 'Manual Import',
  polling_interval: 'never',
  settings: {},
  show_polling_interval: false
};

const useGandalfReducer = ({ firstStep, drivers }) => {
  const [state, originalDispatch] = useReducer(reducer, defaultImport);

  const dispatch = (params = {}) => {
    originalDispatch({ ...params, firstStep, drivers });
  };

  const form = {
    name: state.name,
    type: state.type,
    polling_interval: state.polling_interval,
    settings: state.settings
  };

  const steps = useMemo(() => {
    if (!state.type) {
      return [firstStep];
    }

    return [firstStep, ...drivers[state.type].wizard.steps];
  }, [state.type, firstStep, drivers]);

  const { title, Component: WizardStep } = useMemo(() => {
    return steps[state.step];
  }, [state.step, steps]);

  const valid = useMemo(() => {
    return (
      state.name &&
      state.type &&
      state.polling_interval &&
      drivers[state.type].settings.valid(state.settings)
    );
  }, [state, drivers]);

  const onFirstStep = state.step === 0;

  const onLastStep = Boolean(state.type && state.step === steps.length - 1);

  const canProgress = Boolean(state.type);

  return {
    ...state,
    valid,
    dispatch,
    form,
    title,
    WizardStep,
    onFirstStep,
    onLastStep,
    canProgress
  };
};

export default useGandalfReducer;
