import React, { useMemo } from 'react';
import { Switch, matchPath, useLocation } from 'react-router-dom';

import { Flex, AppContainer } from '@ubisend/pulse-components';
import { SupportBot } from '@ubisend/pulse-support';
import { LanguagesProvider } from '@ubisend/pulse-hooks';
import { useAuth, Route, Auth } from '@ubisend/pulse-auth';

import routes from '../routes';
import RenderedRoute from './RenderedRoute';
import Sidebar from './Sidebar';

const defaultCanAccess = () => true;

const isAuthRoute = route => {
  return Boolean(route.auth);
};

const isCmsRoute = route => {
  return !route.public;
};

const Wrapper = ({ children }) => {
  const { authenticated, client } = useAuth();

  const location = useLocation();

  const route = useMemo(() => {
    return routes.find(route => {
      return matchPath(location.pathname, {
        path: route.path,
        exact: route.exact ?? true
      });
    });
  }, [location.pathname]);

  if (!route) {
    return children;
  }

  if (isAuthRoute(route)) {
    return <Auth>{children}</Auth>;
  }

  if (authenticated && isCmsRoute(route)) {
    return (
      <LanguagesProvider client={client}>
        <Flex>
          <Sidebar />
          <AppContainer>
            {children}
            <SupportBot />
          </AppContainer>
        </Flex>
      </LanguagesProvider>
    );
  }

  return children;
};

const Routes = () => {
  return (
    <Wrapper>
      <Switch>
        {routes.map((route, key) => {
          return (
            <Route
              public={route.public}
              exact={route.exact ?? true}
              key={key}
              path={route.path}
              canAccess={route.canAccess || defaultCanAccess}
              render={props => <RenderedRoute {...props} route={route} />}
            />
          );
        })}
      </Switch>
    </Wrapper>
  );
};

export default Routes;
