import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import {
  TextInput,
  Panel,
  Label,
  Flex,
  FormHeading,
  Explainer,
  FormGroup,
  Divider,
  Tooltip,
  Button,
  useNotification,
  PageWrapper,
  OneHalfLayout
} from '@ubisend/pulse-components';
import {
  useLocations,
  useLanguages,
  LocationsConsumer,
  useMutation
} from '@ubisend/pulse-hooks';
import { GroupSelect } from '@ubisend/pulse-groups';

import {
  Utterance,
  LanguageSelect,
  LocationSelect,
  FaqSelect,
  ToggleContainer
} from '../Components/index';
import { createFaq, syncGroupsForFaq } from '../api/index';

const initialFaq = {
  name: '',
  randomResponse: false,
  groups: [],
  location: null,
  faqToShare: null
};

const CreateFaq = () => {
  const { languages, defaultLanguage } = useLanguages();
  const { hasLocations } = useLocations();
  const { showSuccess } = useNotification();

  const [faq, setFaq] = useState({
    ...initialFaq,
    utterances: languages.reduce((utterances, language) => {
      return utterances.concat([
        {
          id: utterances.length + 1,
          language_id: language.id,
          utterance: '',
          placeholder: 'Who can I speak to about annual leave?'
        },
        {
          id: utterances.length + 2,
          language_id: language.id,
          utterance: '',
          placeholder: 'Who is in charge of annual leave?'
        },
        {
          id: utterances.length + 3,
          language_id: language.id,
          utterance: '',
          placeholder: 'Who is responsible for booking annual leave?'
        }
      ]);
    }, []),
    language: defaultLanguage.id
  });

  const handleNameChange = event =>
    setFaq({ ...faq, name: event.target.value });

  const handleUtteranceChange = (id, newUtterance) => {
    setFaq({
      ...faq,
      utterances: faq.utterances.map(utterance => {
        if (id === utterance.id) {
          return { ...utterance, utterance: newUtterance };
        }

        return utterance;
      })
    });
  };

  const handleLocationSelect = location => {
    setFaq({
      ...faq,
      location: location ? { id: location.value, name: location.label } : null
    });
  };

  const handleLanguageChange = ({ value }) =>
    setFaq({ ...faq, language: value });

  const handleGroupSelect = groups =>
    setFaq({ ...faq, groups: groups ? groups.map(group => group.value) : [] });

  const handleFaqToShare = option => {
    setFaq({ ...faq, faqToShare: option ? option.value : null });
  };

  const { mutate: syncGroups } = useMutation(syncGroupsForFaq);

  const { mutate: create } = useMutation(createFaq, {
    onSuccess: ({ data }) => {
      // Add any FAQ groups.
      if (faq.groups.length !== 0) {
        syncGroups({ faqId: data.data.id, groupIds: faq.groups });
      }

      setFaq({ ...faq, created: data.data.id });

      showSuccess(`Successfully created "${data.data.name}"`);
    }
  });

  const handleSubmit = async e => {
    e.preventDefault();

    await create({
      name: faq.name,
      randomResponse: faq.randomResponse,
      sharedFaqId: faq.shared_faqs,
      locationId: faq.location ? faq.location?.id : null,
      utterances: faq.utterances
        ? faq.utterances.map(({ utterance, language_id }) => ({
            utterance,
            language_id
          }))
        : null
    });
  };

  const validLocation = !hasLocations || faq.location;
  const checkValid = ({ utterance }) => utterance;
  const validUtterances = faq.faqToShare || faq.utterances.every(checkValid);
  const validated = faq.name && validUtterances && validLocation;

  return (
    <>
      {faq.created && (
        <Redirect
          to={{
            pathname: `/faqs/${faq.created}/responses`,
            state: { creatingFaq: true }
          }}
        />
      )}
      <PageWrapper header="FAQ" subheader="Create a new FAQ">
        <OneHalfLayout>
          <Panel>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Label htmlFor="name">Name</Label>
                <TextInput
                  placeholder="Book Annual Leave"
                  name="name"
                  value={faq.name}
                  onChange={handleNameChange}
                  id="name"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="tags">Tags</Label>
                <GroupSelect
                  id="tags"
                  value={faq.groups}
                  onChange={handleGroupSelect}
                  for="faqs"
                />
              </FormGroup>
              <LocationsConsumer>
                <FormGroup>
                  <Label htmlFor="location">Location</Label>
                  <LocationSelect
                    value={faq.location ? faq.location?.id : null}
                    onChange={handleLocationSelect}
                  />
                </FormGroup>
                <Flex col mb>
                  <ToggleContainer
                    onOpenChange={() => handleFaqToShare(null)}
                    initialOpen={faq.faqToShare}
                    text="Does this FAQ exist at another location?"
                    tooltip={
                      <Tooltip.Content>
                        Sharing one set of questions across multple locations
                        improves the chatbots accuracy.
                      </Tooltip.Content>
                    }>
                    <Label>Shared FAQ</Label>
                    <FaqSelect
                      value={faq.faqToShare}
                      onChange={handleFaqToShare}
                    />
                  </ToggleContainer>
                </Flex>
              </LocationsConsumer>
              {!faq.faqToShare && (
                <>
                  <Divider />
                  <Flex between>
                    <FormHeading>Questions</FormHeading>
                    <Flex center mb>
                      <Tooltip
                        tooltip={
                          <Tooltip.Content>
                            Make sure to include questions for each language
                          </Tooltip.Content>
                        }>
                        <Tooltip.Icon>!</Tooltip.Icon>
                      </Tooltip>
                      <LanguageSelect
                        value={faq.language}
                        onChange={handleLanguageChange}
                      />
                    </Flex>
                  </Flex>
                  <Explainer mb>
                    Give the main ways of asking this FAQ. More questions can be
                    added later on.
                  </Explainer>
                  <Flex col ySpace mb>
                    {faq.utterances
                      .filter(
                        utterance => utterance.language_id === faq.language
                      )
                      .map((utterance, key) => (
                        <Utterance
                          {...utterance}
                          key={key}
                          handleUtteranceChange={handleUtteranceChange}
                        />
                      ))}
                  </Flex>
                </>
              )}
              <Flex xSpace center>
                <Button disabled={!validated} type="submit" icon="save">
                  Save
                </Button>
              </Flex>
            </form>
          </Panel>
        </OneHalfLayout>
      </PageWrapper>
    </>
  );
};

CreateFaq.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

export default CreateFaq;
