import React, { useMemo } from 'react';

import {
  QuickReply,
  Standard,
  Action,
  General,
  Carousel,
  Payment,
  Loading,
  Progress,
  Image,
  Video,
  Event,
  ViewMessage
} from './ResponseTypes/index';
import { message } from '../../../types/index';

const types = {
  standard: Standard,
  action: Action,
  'quick-reply': QuickReply,
  general: General,
  carousel: Carousel,
  image: Image,
  video: Video,
  content: ViewMessage,
  embed: ViewMessage,
  payment: Payment,
  loading: Loading,
  progress: Progress,
  event: Event
};

const defaultFilter = ({ message, handleButtonClick }) => {
  return {
    type: message.type,
    composer: message.composer,
    content: message.content,
    handleButtonClick,
    direction:
      message.direction === 'toClient' ? 'sentMessage' : 'recievedMessage'
  };
};

const filterCarousel = ({ index, ...props }) => {
  return {
    ...defaultFilter(props),
    index
  };
};

const filters = {
  carousel: filterCarousel
};

const filterProps = props => {
  const filter = filters[props.message.type] || defaultFilter;

  return filter(props);
};

const Message = props => {
  const Component = useMemo(() => types[props.message.type] || Standard, [
    props.message.type
  ]);

  return <Component {...filterProps(props)} />;
};

Message.propTypes = message;

export default Message;
