import styled from 'styled-components';

import { spacing, text } from '../styles';

const Heading3 = styled.h3`
  ${tw`m-0 font-semibold`}
  font-size:1.125rem;
  ${spacing}
  ${text}
`;

export default Heading3;
