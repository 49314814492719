const date = {
  id: 'date',
  name: 'Date',
  Input: null,
  content: {},
  valid: () => true,
  snippet: `{
    "type": "date",
    "content": {} 
}`
};

export default date;
