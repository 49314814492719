import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useLocation, Redirect } from 'react-router-dom';
import chroma from 'chroma-js';

import { useQuery, useQuerySearch } from '@ubisend/pulse-hooks';
import {
  Flex,
  NoResults,
  Grid,
  Pagination,
  Placeholder,
  TextInput,
  TabButton,
  InnerPanel,
  StretchPanel,
  Button,
  ActionMenu,
  Select,
  Label
} from '@ubisend/pulse-components';
import { GroupSelect } from '@ubisend/pulse-groups';
import { AnimateSharedLayout } from '@ubisend/framer-motion';
import { PermissionFilter } from '@ubisend/pulse-auth';

import {
  FilePreview,
  CreateFile,
  SourceSelect,
  SourceSelectMenuList
} from '../../Components/index';
import types from '../../Components/Types/index';
import { useFileSource } from '../../hooks/index';
import { FileRouter } from './Routers/index';
import { FileSourceContext } from '../../Contexts/index';
import { formatFileSource } from '../FileSources/utils/index';

const FileContainer = styled(StretchPanel)`
  ${tw`w-full`}

  aspect-ratio: 1 / 1;

  ${props => {
    if (props.isActive) {
      return `
      border: 1px solid ${chroma(props.theme.primary).alpha(0.5)};
      box-shadow: 0 0 0 0.2rem ${chroma(props.theme.primary).alpha(0.25)};
      `;
    }
  }}

  & a {
    color: inherit;
    text-decoration: inherit;
  }

  &:hover {
    opacity: 0.75;
  }
  &:active {
    opacity: 0.5;
  }
`;

const uploadedBy = [
  {
    value: 'user',
    label: 'Platform user'
  },
  {
    value: 'subscriber',
    label: 'Chatbot user'
  },
  {
    value: 'anyone',
    label: 'Anyone'
  }
];

const FileSourceProvider = ({ children, sourceId }) => {
  const query = useQuery(`files/sources/${sourceId}`);

  return (
    <FileSourceContext.Provider
      value={query.isSuccess ? formatFileSource(query.data.data) : null}>
      {children}
    </FileSourceContext.Provider>
  );
};

FileSourceProvider.propTypes = {
  sourceId: PropTypes.number.isRequired
};

const File = ({ children, file }) => {
  const location = useLocation();

  return (
    <FileContainer isActive={location.pathname === `/files/${file.id}`}>
      {children}
    </FileContainer>
  );
};

File.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

const FilesPage = ({ match, location }) => {
  const source = useFileSource();
  const subscriberId = useQuerySearch(location, 'subscriber_id');

  const [search, setSearch] = useState('');
  const [sourceId, setSourceId] = useState(parseInt(match.params.sourceId));
  const [filters, setFilters] = useState({
    page: 1,
    type: types.image.type,
    search: '',
    group_ids: [],
    uploaded_by: 'user'
  });

  const query = useQuery([
    'files',
    {
      ...filters,
      subscriber_id: subscriberId ? subscriberId : null,
      source_id: sourceId
    }
  ]);

  const updateFilters = useCallback(newFilters => {
    setFilters(oldFilters => ({ ...oldFilters, ...newFilters }));
  }, []);

  const handleSourceChange = source => {
    setSourceId(source.value);
  };

  const handleTypeChange = type => {
    updateFilters({ type, page: 1 });
  };

  const handleSearchChange = event => {
    const search = event.target.value;

    setSearch(search);
  };

  const handleGroupIdsChange = options => {
    updateFilters({
      group_ids: options ? options.map(option => option.value) : [],
      page: 1
    });
  };

  const paginateFiles = page => updateFilters({ page });

  const handleuploadedByChange = ({ value }) => {
    updateFilters({ uploaded_by: value });
  };

  useEffect(() => {
    const timeout = setTimeout(() => updateFilters({ search }), 800);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [search, updateFilters]);

  const isLoading = query.isLoading || query.isIdle || !source;

  if (parseInt(match.params.sourceId) !== sourceId) {
    return <Redirect to={`/files/${sourceId}/view`} />;
  }

  const buildFileLink = fileId => {
    const baseLink = `/files/${sourceId}/view/${fileId}`;

    if (subscriberId) {
      return baseLink + `?subscriber_id=${subscriberId}`;
    }

    return baseLink;
  };

  return (
    <Flex col fat>
      <Flex fat background="white" pad borderBottom xSpace>
        <Flex fat xSpace center>
          <ActionMenu
            position={ActionMenu.POSITIONS.RIGHT}
            buttonProps={{
              'aria-label': 'Toggle filter menu',
              variant: 'secondary',
              icon: 'filter',
              children: 'Filter'
            }}>
            <Flex pl pr pt col fat style={{ width: '20rem' }}>
              <Flex col fat mb>
                <Label htmlFor="search">Name or description</Label>
                <TextInput
                  id="search"
                  placeholder="Search"
                  disabled={source ? !source.driver.features.search : true}
                  value={search}
                  onChange={handleSearchChange}
                />
              </Flex>
              <Flex col fat mb>
                <Label htmlFor="tags">Tags</Label>
                <GroupSelect
                  id="tags"
                  aria-label="Tags"
                  value={filters.group_ids}
                  onChange={handleGroupIdsChange}
                  isDisabled={source ? !source.driver.features.groups : true}
                  for="files"
                />
              </Flex>
              {!subscriberId && (
                <Flex col fat mb>
                  <Label htmlFor="uploaded_by">Uploaded by</Label>
                  <Select
                    id="uploaded_by"
                    placeholder="Uploaded by"
                    options={uploadedBy}
                    value={uploadedBy.filter(
                      ({ value }) => filters.uploaded_by === value
                    )}
                    onChange={handleuploadedByChange}
                  />
                </Flex>
              )}
            </Flex>
          </ActionMenu>
          <Flex fat middle>
            <AnimateSharedLayout>
              {Object.values(types).map((mappedType, key) => (
                <TabButton
                  style={{ whiteSpace: 'nowrap' }}
                  key={key}
                  active={mappedType.type === filters.type}
                  onClick={() => handleTypeChange(mappedType.type)}>
                  {mappedType.name}
                </TabButton>
              ))}
            </AnimateSharedLayout>
          </Flex>
        </Flex>
        <Flex right xSpace>
          {!subscriberId && (
            <>
              <Flex style={{ width: '13rem' }}>
                <SourceSelect
                  components={{ MenuList: SourceSelectMenuList }}
                  aria-label="Storage"
                  queryKey={'files/sources'}
                  value={sourceId}
                  onChange={handleSourceChange}
                />
              </Flex>
              <Flex right>
                <CreateFile
                  type={filters.type}
                  sourceId={sourceId}
                  queryKey={'files'}
                  disabled={!source}
                />
              </Flex>
            </>
          )}
          {subscriberId && (
            <>
              <PermissionFilter can="view messages" fallback="N/A">
                <Flex start>
                  <Button
                    as={Link}
                    variant="secondary"
                    to={`/conversations/${subscriberId}`}
                    icon="user">
                    Subscriber {subscriberId}
                  </Button>
                </Flex>
              </PermissionFilter>
              <Flex start>
                <Button
                  colour="danger"
                  as={Link}
                  variant="secondary"
                  to={`/files/${sourceId}/view`}
                  icon="x">
                  Clear subscriber
                </Button>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
      <Flex fat>
        <Flex fat yScroll style={{ height: 'calc(100vh - 4.5rem)' }}>
          {isLoading && (
            <Flex col pl pr pt fat>
              <Grid columns={4}>
                {[0, 1, 2, 3, 4, 5].map(i => (
                  <InnerPanel key={i}>
                    <Flex fat>
                      <Placeholder items={1} />
                    </Flex>
                  </InnerPanel>
                ))}
              </Grid>
            </Flex>
          )}
          {!isLoading && query.isSuccess && query.data.meta.total === 0 && (
            <Flex middle center fat>
              <NoResults text="No files found" />
            </Flex>
          )}
          {!isLoading && query.isSuccess && query.data.meta.total > 0 && (
            <Flex col pl pr pt fat>
              <Grid columns={4}>
                {query.data.data.map((file, key) => (
                  <File key={key} file={file}>
                    <Link to={buildFileLink(file.id)}>
                      <FilePreview
                        type={filters.type}
                        sourceId={sourceId}
                        file={file}
                      />
                    </Link>
                  </File>
                ))}
              </Grid>
              <Flex bottom>
                <Pagination
                  pagination={query.data.meta}
                  handlePageChange={paginateFiles}
                />
              </Flex>
            </Flex>
          )}
        </Flex>
        <Flex
          borderLeft
          style={{ width: '360px', height: 'calc(100vh - 4.5rem)' }}
          background="white"
          noShrink
          yScroll>
          <FileRouter sourceId={sourceId} />
        </Flex>
      </Flex>
    </Flex>
  );
};

const Files = props => {
  return (
    <FileSourceProvider sourceId={parseInt(props.match.params.sourceId)}>
      <FilesPage {...props} />
    </FileSourceProvider>
  );
};

export default Files;
