import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Label, Flex, Select } from '@ubisend/pulse-components';

const Filter = ({ filters, handleFilterChange }) => {
  const [names, setNames] = useState([]);

  const { isLoading } = useQuery(['smalltalk/all'], {
    onSuccess: data => {
      setNames(data.data);
    }
  });

  const handleNameSelect = selected => {
    if (selected === null) {
      return handleFilterChange(filters => ({
        ...filters,
        name: undefined
      }));
    }

    handleFilterChange(filters => ({
      ...filters,
      name: selected.value
    }));
  };

  return (
    <Flex col fat pad style={{ width: '20rem' }}>
      <Label htmlFor="smalltalk-names">Name</Label>
      <Select
        id="smalltalk-names"
        closeMenuOnSelect
        isClearable
        isLoading={isLoading}
        options={names.map(({ name }) => ({
          value: name,
          label: name
        }))}
        onChange={handleNameSelect}
        value={
          filters.name ? { value: filters.name, label: filters.name } : null
        }
      />
    </Flex>
  );
};

Filter.propTypes = {
  filters: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.func.isRequired
};

export default Filter;
