import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Explainer,
  Button,
  useNotification,
  useModal
} from '@ubisend/pulse-components';

import { syncStepActions } from '../../../../api/index';
import { useBuilder } from '../../../../hooks/index';
import { useActionReducer } from '../../../../reducers/index';
import Modal from '../../../Modal';
import ActionSelect from './ActionSelect';
import ActionContent from './ActionContent';

const EditActionBlock = ({ handleClose, node, block }) => {
  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();
  const { dispatch: builderDispatch } = useBuilder();
  const { actions, valid, dispatch: actionDispatch } = useActionReducer({
    initialActions: [{ name: block.base.name, content: block.base.content }]
  });

  const { mutate, mutateAsync, isLoading } = useMutation(syncStepActions, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully updated actions');
      builderDispatch({
        type: 'SYNC_STEP_ACTIONS',
        stepId: node.id,
        newActions: data.data
      });
      handleClose();
    }
  });

  const handleNameChange = index => ({ value }) => {
    actionDispatch({ type: 'UPDATE_NAME', index, name: value });
  };

  const handleContentChange = index => content => {
    actionDispatch({ type: 'UPDATE_CONTENT', index, content });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (!valid || isLoading) {
      return;
    }

    mutate({
      stepId: node.id,
      actions: node.base.actions.map(action => {
        if (action.id === block.base.id) {
          return actions[0];
        }

        return action;
      })
    });
  };

  const handleDelete = () => {
    showModal({
      header: 'Remove action',
      body: `Are you sure you want to remove this action?`,
      handleConfirm: async () => {
        try {
          await mutateAsync({
            stepId: node.id,
            actions: node.base.actions.filter(
              action => action.id !== block.base.id
            )
          });
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <Modal
      header="Edit action"
      handleClose={handleClose}
      actions={
        <Button
          variant="secondary"
          colour="danger"
          icon="trash"
          onClick={handleDelete}
          loading={isLoading}>
          Delete
        </Button>
      }>
      <Flex pad col>
        <form onSubmit={handleSubmit}>
          <Flex ySpace col>
            <Explainer>
              Actions will be sent when a user reaches the step in the
              conversation. An action is a built in piece of functionality that
              carries out a specific task.
            </Explainer>
            {actions.map((action, index) => (
              <Flex xSpace center fat key={index}>
                <Flex fat xSpace>
                  <Flex style={{ width: '20rem' }}>
                    <ActionSelect
                      aria-label={`action-${index}`}
                      value={action.name}
                      onChange={handleNameChange(index)}
                    />
                  </Flex>
                  {action.name && (
                    <ActionContent
                      name={action.name}
                      content={action.content}
                      onChange={handleContentChange(index)}
                    />
                  )}
                </Flex>
              </Flex>
            ))}
            <Flex right xSpace>
              <Button icon="save" disabled={!valid || isLoading} type="submit">
                Save
              </Button>
            </Flex>
          </Flex>
        </form>
      </Flex>
    </Modal>
  );
};

EditActionBlock.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    base: PropTypes.shape({
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired
        }).isRequired
      ).isRequired
    }).isRequired
  }).isRequired,
  block: PropTypes.shape({
    base: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      content: PropTypes.object.isRequired
    }).isRequired
  }).isRequired
};

export default EditActionBlock;
