import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  TextInput,
  Button,
  ToggleButton,
  Span
} from '@ubisend/pulse-components';
import {
  Condition,
  useConditional,
  useConditionalTypes,
  ConditionalSelect,
  SubjectSelect,
  CaseSensitiveToggle
} from '@ubisend/pulse-conditionals';

import { IntegrationSelect, EndpointSelect } from '../../../Integrations/index';
import IntegrationSubjectSelect from './IntegrationSubjectSelect';

const STATUS_TYPES = ['integration-event', 'integration-number-check'];

const IntegrationConditional = ({
  index,
  labelPrefix,
  type,
  condition,
  target,
  subject,
  case_sensitive,
  flipped
}) => {
  const types = useConditionalTypes();
  const {
    dispatch,
    conditionals,
    match,
    handleConditionalChange,
    handleConditionChange,
    handleSubjectChange,
    handleTargetChange,
    handleConditionalDelete,
    handleCaseToggle,
    handleFlippedToggle,
    getSubjectValue
  } = useConditional();

  const label = (string, index) => `${labelPrefix}-${string}-${index}`;

  const conditionalType = useMemo(() => {
    const conditional = types.find(({ name }) => name === type);

    if (!conditional) {
      return null;
    }

    return conditional.type;
  }, [types, type]);

  const handleIntegrationChange = option => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: {
        ...subject,
        integration_id: option ? option.value : null,
        endpoint_id: null
      },
      index
    });
  };

  const handleEndpointChange = ({ value }) => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: { ...subject, endpoint_id: value },
      index
    });
  };

  const handleIntegrationSubjectChange = ({ value }) => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: { ...subject, integration_subject: value },
      index
    });
  };

  return (
    <Flex xSpace center fat>
      <Span uppercase style={{ minWidth: '4rem', textAlign: 'right' }}>
        {index === 0 && 'if'}
        {index !== 0 && match === 'any' && 'Or'}
        {index !== 0 && match === 'all' && 'And'}
      </Span>
      <ToggleButton
        on={flipped}
        onChange={handleFlippedToggle(index)}
        aria-label={label('flipped', index)}>
        not
      </ToggleButton>
      <Flex col fat ySpace border pad>
        <Flex xSpace center fat>
          <SubjectSelect
            aria-label={label('subject', index)}
            value={getSubjectValue({ conditionalType, type, subject })}
            onChange={handleSubjectChange(index)}
          />
          <span>to</span>
          <IntegrationSelect
            aria-label={label('integration', index)}
            value={subject.integration_id}
            onChange={handleIntegrationChange}
          />
          <span>at</span>
          <EndpointSelect
            aria-label={label('endpoint', index)}
            integration={subject.integration_id}
            value={subject.endpoint_id}
            onChange={handleEndpointChange}
          />
        </Flex>
        {subject.endpoint_id && (
          <Flex xSpace center>
            <Flex fat xSpace growChildren center>
              <Flex fat xSpace center>
                <IntegrationSubjectSelect
                  aria-label={label('integration-subject', index)}
                  value={subject.integration_subject}
                  onChange={handleIntegrationSubjectChange}
                />
                {subject.integration_subject === 'body' && <span>at</span>}
              </Flex>
              {subject.integration_subject === 'body' && (
                <TextInput
                  aria-label={label('target', index)}
                  value={target}
                  onChange={handleTargetChange(index)}
                  placeholder="users.first_name"
                />
              )}
              <CaseSensitiveToggle
                aria-label={label('case-sensitive', index)}
                index={index}
                value={case_sensitive}
                onChange={handleCaseToggle(index)}
              />
              {subject.integration_subject === 'body' && (
                <ConditionalSelect
                  aria-label={label('conditional', index)}
                  value={type}
                  onChange={handleConditionalChange(index)}
                />
              )}
              {subject.integration_subject === 'status' && (
                <ConditionalSelect
                  types={STATUS_TYPES}
                  aria-label={label('conditional', index)}
                  value={type}
                  onChange={handleConditionalChange(index)}
                />
              )}
              <Condition
                aria-label={label('condition', index)}
                name={conditionalType}
                value={condition}
                onChange={handleConditionChange(index)}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex grow right>
        <Button
          variant="secondary"
          colour="danger"
          icon="trash"
          disabled={conditionals.length <= 1}
          onClick={handleConditionalDelete(index)}>
          Delete
        </Button>
      </Flex>
    </Flex>
  );
};

IntegrationConditional.propTypes = {
  index: PropTypes.number.isRequired,
  labelPrefix: PropTypes.string.isRequired,
  subject: PropTypes.shape({
    endpoint_id: PropTypes.number,
    integration_id: PropTypes.number,
    integration_subject: PropTypes.oneOf(['body', 'status'])
  }).isRequired,
  type: PropTypes.string,
  condition: PropTypes.any,
  target: PropTypes.string,
  case_sensitive: PropTypes.bool,
  flipped: PropTypes.bool
};

export default IntegrationConditional;
