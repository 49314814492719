import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';

const format = action => ({ value: action, label: action });

const actions = [
  'Create ticket',
  'Create live chat ticket',
  'Set subscriber location',
  'Set subscriber language',
  'Leave feedback',
  'Export subscriber',
  'Export transcript',
  'Delete subscriber',
  'Export all knowledge bases',
  'Export single knowledge base',
  'Export knowledge base section'
];

const ActionSelect = ({ value, ...props }) => {
  return (
    <Select
      options={actions && actions.map(format)}
      value={value && format(actions.find(action => action === value))}
      {...props}
    />
  );
};

ActionSelect.propTypes = {
  value: PropTypes.string
};

export default ActionSelect;
