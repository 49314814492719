import React from 'react';
import PropTypes from 'prop-types';

import { Divider, Button, Label, Flex } from '@ubisend/pulse-components';
import { LocationsConsumer } from '@ubisend/pulse-hooks';
import { GroupSelect } from '@ubisend/pulse-groups';

import { LocationSelect } from '../Locations/index';
import FaqSelect from './FaqSelect';

const FaqFilter = ({ handleFilterChange, filters, handleReset }) => {
  const { groups, faq_id, locations } = filters;

  const handleGroupSelect = items => {
    handleFilterChange(filters => ({
      ...filters,
      groups: items ? items.map(group => group.value) : []
    }));
  };

  const handleFaqSelect = item => {
    handleFilterChange(filters => ({
      ...filters,
      faq_id: item ? item.value : null
    }));
  };

  const handleLocationSelect = items => {
    handleFilterChange(filters => ({
      ...filters,
      locations: items ? items.map(item => item.value) : []
    }));
  };

  return (
    <Flex col style={{ width: '20rem' }}>
      <Flex pt pr pl col>
        <Flex fat col mb>
          <Label id="categories">FAQ</Label>
          <FaqSelect
            value={faq_id}
            onChange={handleFaqSelect}
            selectedGroups={groups}
            selectedLocations={locations}
          />
        </Flex>
        <LocationsConsumer>
          <Flex fat col>
            <Label id="locations">Locations</Label>
            <LocationSelect
              value={locations}
              onChange={handleLocationSelect}
              isMulti
            />
          </Flex>
        </LocationsConsumer>
        <Flex fat col>
          <Label htmlFor="tags">Tags</Label>
          <GroupSelect
            id="tags"
            value={groups}
            onChange={handleGroupSelect}
            for="faqs"
          />
        </Flex>
      </Flex>
      <Divider />
      <Flex right pl pb pr>
        <Button
          variant="secondary"
          colour="danger"
          icon="x"
          onClick={handleReset}>
          Reset
        </Button>
      </Flex>
    </Flex>
  );
};

FaqFilter.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    groups: PropTypes.array.isRequired,
    faq_id: PropTypes.number.isRequired,
    locations: PropTypes.array.isRequired
  }).isRequired
};

export default FaqFilter;
