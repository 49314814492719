import { useCallback } from 'react';

import useUpdateTicketCache from './useUpdateTicketCache';

const useUpdateTicketResponsesCache = () => {
  const updateTicketCache = useUpdateTicketCache();

  const updateTicketResponsesCache = useCallback(
    ({ ticketId, responses }) => {
      updateTicketCache({
        ticketId,
        callback: oldTicket => {
          return {
            ...oldTicket,
            subscriber: {
              ...oldTicket.subscriber,
              messages: oldTicket.subscriber.messages.concat(responses)
            }
          };
        }
      });
    },
    [updateTicketCache]
  );

  return updateTicketResponsesCache;
};

export default useUpdateTicketResponsesCache;
