import { useCallback } from 'react';

import { useQueryClient } from '@ubisend/pulse-hooks';

const useUpdateTicketCache = () => {
  const queryClient = useQueryClient();

  const updateTicketQueryCache = useCallback(
    ({ ticketId, callback }) => {
      const query = queryClient
        .getQueryCache()
        .find(`tickets/ubidesk/${ticketId}`);

      if (!query) {
        return null;
      }

      queryClient.setQueryData(query.queryKey, oldData => {
        return {
          data: {
            ...oldData.data,
            ...callback(oldData.data)
          }
        };
      });
    },
    [queryClient]
  );

  const updateAllTicketsQueryCache = useCallback(
    ({ ticketId, callback }) => {
      const query = queryClient
        .getQueryCache()
        .find('tickets/ubidesk', { exact: false });

      if (!query) {
        return null;
      }

      queryClient.setQueryData(query.queryKey, oldData => {
        return {
          ...oldData,
          data: oldData.data.map(oldTicket => {
            if (oldTicket.id !== ticketId) {
              return oldTicket;
            }

            return {
              ...oldTicket,
              ...callback(oldTicket)
            };
          })
        };
      });
    },
    [queryClient]
  );

  const updateTicketCache = useCallback(
    params => {
      updateTicketQueryCache(params);
      updateAllTicketsQueryCache(params);
    },
    [updateTicketQueryCache, updateAllTicketsQueryCache]
  );

  return updateTicketCache;
};

export default useUpdateTicketCache;
