import { useReducer } from 'react';

const defaultCredentials = {
  'API Key': { key: '', value: '', add_to_header: true },
  'Basic Auth': { username: '', password: '' },
  'Bearer Token': { token: '' },
  'No Auth': {}
};

const reducer = (state, { type, newType, newCredentials }) => {
  switch (type) {
    case 'CHANGE_TYPE':
      return {
        type: newType,
        credentials: defaultCredentials[newType]
      };
    case 'CHANGE_CREDENTIALS':
      return { ...state, credentials: newCredentials };
    default:
      throw new Error();
  }
};

const types = ['API Key', 'Basic Auth', 'Bearer Token', 'No Auth'];

const defaultAuth = {
  type: 'Bearer Token',
  credentials: { token: '' }
};

const useAuth = (initalAuth = defaultAuth) => {
  const [state, dispatch] = useReducer(reducer, initalAuth);

  const handleChangeType = option => {
    dispatch({ type: 'CHANGE_TYPE', newType: option ? option.value : null });
  };

  const handleCredentialsChange = credentials => {
    dispatch({ type: 'CHANGE_CREDENTIALS', newCredentials: credentials });
  };

  const validWithCreds =
    state.type &&
    state.credentials &&
    Object.values(state.credentials).every(Boolean);
  const validWithoutCreds = Boolean(state.type);

  return {
    ...state,
    form: state,
    dispatch,
    valid: validWithCreds || validWithoutCreds,
    handleChangeType,
    handleCredentialsChange
  };
};

export default useAuth;
export { defaultAuth, types, reducer };
