import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';

import {
  Flex,
  Span,
  SecondarySidebarRoot,
  Button
} from '@ubisend/pulse-components';

import SortableSidebarItemWrapper from './SortableSidebarItemWrapper';
import { useImports } from '../hooks/index';

const LinkContainer = styled.div`
  ${tw`flex p-2 justify-between items-center`}
  & svg,
   & i {
    width: 20px;
    height: 20px;
  }
`;

const DragableRootLink = styled(SecondarySidebarRoot)`
  ${tw`border-none w-full`}
  & svg,
  & i {
    ${tw`w-5 h-5 mr-1 justify-center items-center`}
  }
`;

const SortableRootSidebarItem = ({ open, handleOpen, importItem }) => {
  const { dispatch } = useImports();

  const handleReorder = ({ from, to }) => {
    dispatch({ type: 'REORDER_IMPORTS', from, to });
  };

  const hasSections = importItem?.sections && importItem.sections.length > 0;

  const containerRef = useRef(null);
  const linkRef = useRef(null);

  const [, drop] = useDrop({
    accept: 'SIDEBAR_ROOT_ITEM',
    drop(droppedImport) {
      if (droppedImport.index === importItem.index) {
        return;
      }

      handleReorder({ from: droppedImport, to: importItem });
    }
  });
  const [, drag] = useDrag({
    type: 'SIDEBAR_ROOT_ITEM',
    item: () => importItem
  });

  drag(linkRef);
  drop(containerRef);

  return (
    <>
      <LinkContainer
        ref={containerRef}
        onDrop={event => event.preventDefault()}>
        <DragableRootLink
          ref={linkRef}
          active
          to={`/knowledge-bases/${importItem.id}`}
          onClick={handleOpen}>
          <Flex xSpaceSm center>
            <Span>&#9776;</Span>
            <Span>{importItem.name}</Span>
          </Flex>
        </DragableRootLink>
        <Button
          aria-label={
            open ? `Close ${importItem.name}` : `Open ${importItem.name}`
          }
          variant="inline"
          onClick={handleOpen}
          icon={open ? 'cheveronUp' : 'cheveronDown'}
        />
      </LinkContainer>
      <SortableSidebarItemWrapper
        open={open}
        importItem={importItem}
        hasSections={hasSections}
      />
    </>
  );
};

SortableRootSidebarItem.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  importItem: PropTypes.any.isRequired
};

export default SortableRootSidebarItem;
