import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  useQuery,
  useQueryClient,
  useMutation,
  useQuerySearch
} from '@ubisend/pulse-hooks';
import { PermissionFilter } from '@ubisend/pulse-auth';
import {
  Flex,
  Heading2,
  Divider,
  Placeholder,
  Button,
  Label,
  InnerPanel,
  Span,
  useModal,
  useNotification,
  Tag,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalPortal
} from '@ubisend/pulse-components';

import {
  updateFile as updateFileApi,
  deleteFile as deleteFileApi
} from '../../../api/index';
import { FileProvider } from '../../../Providers/index';
import { FileDetails } from '../../../Components/index';
import { useFileSource } from '../../../hooks/index';

const File = ({ match, location }) => {
  const [edit, setEdit] = useState(false);

  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();
  const source = useFileSource();

  const history = useHistory();

  const subscriberId = useQuerySearch(location, 'subscriber_id');

  const queryClient = useQueryClient();
  const query = useQuery([
    `files/${match.params.fileId}`,
    { source_id: match.params.sourceId }
  ]);
  const updateFile = useMutation(updateFileApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('files');
      await queryClient.invalidateQueries(`files/${match.params.fileId}`);
      showSuccess('Successfully updated file');
    }
  });
  const deleteFile = useMutation(deleteFileApi, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('files');
      history.push(`/files/${match.params.sourceId}/view`);
      await queryClient.invalidateQueries(`files/${match.params.fileId}`);
      showSuccess('Successfully deleted file');
    }
  });

  const buildFilesLink = () => {
    const baseLink = `/files/${match.params.sourceId}/view`;

    if (subscriberId) {
      return baseLink + `?subscriber_id=${subscriberId}`;
    }

    return baseLink;
  };

  const handleClose = () => {
    history.push(buildFilesLink());
  };

  const handleEdit = async form => {
    await updateFile.mutateAsync({
      id: match.params.fileId,
      source_id: match.params.sourceId,
      ...form
    });
    setEdit(false);
  };

  const handleDelete = () => {
    showModal({
      header: 'Delete file',
      body: `Are you sure you want to delete this file?`,
      handleConfirm: async () => {
        await deleteFile.mutateAsync({
          id: match.params.fileId,
          source_id: match.params.sourceId
        });
        hideModal();
      }
    });
  };

  return (
    <>
      {edit && (
        <ModalPortal>
          <ModalContainer>
            <ModalContent>
              <ModalHeader>
                <Label mb={false}>Edit file</Label>
                <ModalClose onClick={() => setEdit(false)} />
              </ModalHeader>
              <FileProvider
                initialFile={{
                  file: true,
                  name: query.data.data.name || '',
                  description: query.data.data.description || '',
                  groupIds: query.data.data.groups.map(group => group.id)
                }}>
                <FileDetails
                  showUpload={false}
                  onSubmit={handleEdit}
                  loading={updateFile.isLoading}
                />
              </FileProvider>
            </ModalContent>
          </ModalContainer>
        </ModalPortal>
      )}
      <Flex col fat>
        <Flex pl pr pt between center>
          <Heading2>File details</Heading2>
          <ModalClose onClick={handleClose} />
        </Flex>
        <Divider />
        <Flex pb pl pr>
          {query.isLoading && <Placeholder />}
          {query.isSuccess && (
            <Flex col ySpace fat>
              {['image', 'qr-code'].includes(query.data.data.file_type) && (
                <Flex col fat>
                  <Label>Preview</Label>
                  <img
                    alt={`${query.data.data.name} preview`}
                    src={query.data.data.link}
                  />
                </Flex>
              )}
              <Flex xSpaceSm>
                <Button
                  variant="secondary"
                  icon="pencilAlt"
                  disabled={source ? !source.driver.features.edit : true}
                  onClick={() => setEdit(true)}>
                  Edit
                </Button>
                <Button
                  variant="secondary"
                  icon="download"
                  as="a"
                  href={query.data.data.link}
                  download
                  target="_blank"
                  rel="noopener noreferrer">
                  Download
                </Button>
                <Button
                  variant="secondary"
                  colour="danger"
                  icon="trash"
                  onClick={handleDelete}>
                  Delete
                </Button>
              </Flex>
              <Flex col mb>
                <Label>Information</Label>
                <InnerPanel>
                  <Flex col ySpace>
                    <Flex col>
                      <Label>Name</Label>
                      <Span tinyText>{query.data.data.name || 'N/A'}</Span>
                    </Flex>
                    {source && source.driver.features.description && (
                      <Flex col>
                        <Label>Description</Label>
                        <Span tinyText>
                          {query.data.data.description || 'N/A'}
                        </Span>
                      </Flex>
                    )}
                    {source && source.driver.features.groups && (
                      <Flex col>
                        <Label>Tags</Label>
                        {query.data.data.groups.length === 0 && (
                          <Span tinyText>No tags</Span>
                        )}
                        {query.data.data.groups.length > 0 && (
                          <Flex xSpaceSm xScroll>
                            {query.data.data.groups.map(group => (
                              <Tag key={group.id}>{group.name}</Tag>
                            ))}
                          </Flex>
                        )}
                      </Flex>
                    )}
                    {query.data.data.created_at && (
                      <Flex col>
                        <Label>Uploaded</Label>
                        <Span tinyText>
                          {dayjs(query.data.data.created_at).format(
                            'DD/MM/YYYY'
                          )}{' '}
                          ({dayjs(query.data.data.created_at).from()})
                        </Span>
                      </Flex>
                    )}
                  </Flex>
                </InnerPanel>
              </Flex>
              {query.data.data.subscriber && (
                <Flex col>
                  <Label>Subscriber</Label>
                  <InnerPanel>
                    <>
                      <Flex col>
                        <Flex col mb>
                          <Label>ID</Label>
                          <Span tinyText>{query.data.data.subscriber.id}</Span>
                        </Flex>
                        <Flex col mb>
                          <Label>Sent first message</Label>
                          <Span tinyText>
                            {dayjs(
                              query.data.data.subscriber.created_at
                            ).format('DD/MM/YYYY')}{' '}
                            (
                            {dayjs(
                              query.data.data.subscriber.created_at
                            ).from()}
                            )
                          </Span>
                        </Flex>
                        <Label>Actions</Label>
                        <Flex xSpaceSm>
                          <PermissionFilter can="view messages">
                            <Flex>
                              <Button
                                aria-label={'view-conversation'}
                                as={Link}
                                variant="secondary"
                                to={`/conversations/${query.data.data.subscriber.id}`}
                                icon="user">
                                Conversation
                              </Button>
                            </Flex>
                          </PermissionFilter>
                          <Button
                            aria-label={`view-all-${query.data.data.subscriber.id}-files`}
                            as={Link}
                            variant="secondary"
                            to={`/files?subscriber_id=${query.data.data.subscriber.id}`}
                            icon="document">
                            All files
                          </Button>
                        </Flex>
                      </Flex>
                    </>
                  </InnerPanel>
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default File;
