import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Button,
  formatSelectOption,
  Label,
  Select,
  ActionMenu,
  Divider
} from '@ubisend/pulse-components';
import { useQuery, useMutation } from '@ubisend/pulse-hooks';
import {
  ConverseBaseComposer,
  useComposer,
  useDelayedBot,
  isValidMessage,
  formatClientMessage,
  ConverseSendButton
} from '@ubisend/pulse-volt';
import { FileSelect } from '@ubisend/pulse-files';
import { ComposerSelect } from '@ubisend/pulse-composer';

import { sendMessage as sendMessageApi } from '../../../../../api/index';
import { useUpdateTicketResponsesCache } from '../../../../../hooks/index';
import ToggleActionsButton from './ToggleActionsButton';

const MAX_MESSAGE_LENGTH = 1000;
const MIN_MESSAGE_LIMIT = 1;

const isValid = message => {
  return isValidMessage(message, {
    min: MIN_MESSAGE_LIMIT,
    max: MAX_MESSAGE_LENGTH
  });
};

const findLiveChatComposer = composer => {
  return !composer.is_custom && composer.type === 'live-chat';
};

const AgentComposer = ({ subscriberId, ticketId }) => {
  const [showFileSelect, setShowFileSelect] = useState(false);
  const [composer, setComposer] = useState(null);

  const { reply, setReply } = useComposer();
  const { setMessages } = useDelayedBot();
  const updateTicketResponsesCache = useUpdateTicketResponsesCache();
  const query = useQuery('composers', {
    onSuccess: ({ data }) => {
      setComposer(data.find(findLiveChatComposer).id);
    }
  });

  const mutation = useMutation(sendMessageApi, {
    onSuccess: ({ data }) => {
      updateTicketResponsesCache({ ticketId, responses: data });
    }
  });

  const handleChange = event => {
    const value = event.target.value;

    if (value.length >= MAX_MESSAGE_LENGTH) {
      return;
    }

    setReply(value);
  };

  const sendMessage = response => {
    setMessages(messages => {
      return messages.concat(formatClientMessage(response));
    });

    mutation.mutate({
      subscriberId,
      responses: [
        {
          ...response,
          composer_id: composer
        }
      ]
    });

    setComposer(query.data.data.find(findLiveChatComposer).id);
  };

  const handleSubmit = () => {
    const validatedMessage = isValid(reply);

    if (!validatedMessage) {
      return;
    }

    sendMessage({
      type: 'standard',
      content: { text: reply }
    });
    setReply('');
  };

  const handleShowFileSelect = () => {
    setShowFileSelect(true);
  };

  const handleHideFileSelect = () => {
    setShowFileSelect(false);
  };

  const handleFileSelect = file => {
    sendMessage({
      type: 'file',
      content: {
        url: file.link,
        text: file.name
      }
    });

    setShowFileSelect(false);
  };

  const handleKeyDown = event => {
    // Submit on enter.
    if (event.keyCode !== 13) {
      return;
    }

    handleSubmit();
  };

  const handleComposerChange = option => {
    setComposer(option.value);
  };

  const disabled = mutation.isLoading || !composer;

  return (
    <>
      {showFileSelect && (
        <FileSelect
          handleFileSelect={handleFileSelect}
          handleCancel={handleHideFileSelect}
        />
      )}
      <ConverseBaseComposer.Container>
        <Flex middle fat center pl pr>
          <Flex ml mr fat>
            <ConverseBaseComposer.Input
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              value={reply}
              placeholder="Send a message"
              disabled={disabled}
            />
          </Flex>
          <Flex mr>
            <ActionMenu
              position={ActionMenu.POSITIONS.TOP}
              Button={ToggleActionsButton}
              buttonProps={{
                'aria-label': 'Toggle composer actions'
              }}>
              <Flex col pad>
                <Flex col>
                  <Label htmlFor="composer">Composer</Label>
                  <Select
                    menuPlacement="top"
                    id="composer"
                    options={
                      query.isSuccess
                        ? ComposerSelect.groupComposerSelectOptions(
                            query.data.data,
                            formatSelectOption
                          )
                        : []
                    }
                    isLoading={!composer}
                    isDisabled={disabled}
                    value={
                      composer && query.isSuccess
                        ? formatSelectOption(
                            query.data.data.find(({ id }) => id === composer)
                          )
                        : null
                    }
                    onChange={handleComposerChange}
                  />
                </Flex>
                <Divider fullWidth />
                <Button
                  fat
                  middle
                  variant="secondary"
                  icon="paperClip"
                  onClick={handleShowFileSelect}
                  disabled={disabled}>
                  Send file
                </Button>
              </Flex>
            </ActionMenu>
          </Flex>
          <Flex mr>
            <ConverseSendButton
              aria-label="Send message"
              disabled={disabled}
              onClick={handleSubmit}
            />
          </Flex>
        </Flex>
      </ConverseBaseComposer.Container>
    </>
  );
};

AgentComposer.propTypes = {
  ticketId: PropTypes.number.isRequired,
  subscriberId: PropTypes.number.isRequired
};

export default AgentComposer;
