import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Flex } from '@ubisend/pulse-components';

import BotSelect from '../../BotSelect';
import BurgerButton from '../BurgerButton';
import SendButton from '../SendButton';
import { useComposer, useDelivery, useBot } from '../../../../../hooks/index';

const Container = styled.div`
  ${tw`relative bg-white text-gray-dark flex items-center flex-no-shrink h-16`}
`;

const FuzzyComposer = ({ content }) => {
  const { reply, setReply } = useComposer();
  const { sendMessage } = useDelivery();
  const { showBurgerIcon, setShowBurgerMenu } = useBot();

  const { t } = useTranslation('bots');

  const handleBurgerIconClick = () => {
    setShowBurgerMenu(open => !open);
  };

  const handleSelectChange = selection => {
    if (!selection) {
      return setReply('');
    }

    if (content.multi) {
      return setReply(selection.map(option => option.value).join(','));
    }

    setReply(selection.value);
  };

  const handleSubmit = () => {
    if (reply) {
      const postback = reply;
      const message = postback
        .split(',')
        .map(item => content.items.find(option => option.value === item).label)
        .join(', ');

      sendMessage(message, postback);
      setReply('');
    }
  };

  /**
   * We want React Select to manage the Input value of the select, until
   * we need to clear it.
   */
  const value = useMemo(() => {
    // Let React Select manage it's own state of the Input.
    if (reply) {
      return undefined;
    }

    // Control the Input so we can clear the value.
    return '';
  }, [reply]);

  const handleOnKeyDown = event => {
    if (content.disabled) {
      return;
    }

    if (event.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <Container>
      <Flex xSpaceSm>
        {showBurgerIcon && (
          <BurgerButton
            aria-label={t('burger_button_label')}
            onClick={handleBurgerIconClick}
          />
        )}
        <BotSelect
          aria-label={content.placeholder}
          placeholder={content.placeholder}
          disabled={content.disabled}
          options={content.items.map(({ label, value }) => ({
            value,
            label,
            isDisabled: content.disabled
          }))}
          onChange={handleSelectChange}
          handleSubmit={handleSubmit}
          onKeyDown={handleOnKeyDown}
          isMulti={content.multi}
          value={value}
        />
        <SendButton
          aria-label={t('send_button_label')}
          disabled={content.disabled}
          onClick={handleSubmit}
        />
      </Flex>
    </Container>
  );
};

FuzzyComposer.propTypes = {
  content: PropTypes.shape({
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    multi: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ).isRequired
  })
};

export default FuzzyComposer;
