import React from 'react';
import PropTypes from 'prop-types';

import types from './Types/index';

const VariableSubject = ({ variable }) => {
  const { Preview } = types.all.find(type => type.key === variable.type.name);

  return <Preview value={variable.default_value} />;
};

VariableSubject.propTypes = {
  variable: PropTypes.shape({
    type: PropTypes.shape({
      name: PropTypes.oneOf(types.all.map(type => type.key)).isRequired
    }).isRequired,
    default_value: PropTypes.any
  }).isRequired
};
export default VariableSubject;
