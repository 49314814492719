import React, { useState } from 'react';
import dayjs from 'dayjs';

import { useQuery, useQueryClient } from '@ubisend/pulse-hooks';
import { AnimatePresence } from '@ubisend/framer-motion';
import {
  PageWrapper,
  useModal,
  useNotification,
  StretchPanel as Panel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Pagination,
  Button,
  PanelSlider,
  NoResults,
  LeftHalfLayout,
  RightHalfLayout,
  TableActions,
  Flex,
  usePaginationReducer,
  OrderableTableRow,
  ActionMenu,
  useOrderableTableReducer
} from '@ubisend/pulse-components';
import { useAuth, PermissionFilter } from '@ubisend/pulse-auth';
import { SubscriberDetails } from '@ubisend/pulse-tickets';

import { DateFilter, Messages } from '../Components/index';
import { deleteSubscriber, exportSubscriber } from '../api/index';

const defaultFilters = {
  type: DateFilter.TYPES.REGULAR,
  start: new Date(dayjs().subtract(1, 'weeks')),
  end: new Date(dayjs().add('1', 'hours'))
};

const Subscribers = ({ match }) => {
  const pagination = usePaginationReducer();
  const order = useOrderableTableReducer();

  const [filters, setFilters] = useState(defaultFilters);
  const [chosenSubscriber, setChosenSubscriber] = useState();

  const queryClient = useQueryClient();
  const query = useQuery([
    filters.type === DateFilter.TYPES.REGULAR
      ? 'subscribers'
      : 'subscribers/demo',
    { ...filters, ...pagination.params, ...order.params }
  ]);
  useQuery({
    queryKey: `subscribers/${match.params.id}`,
    onSuccess: ({ data: subscriber }) => {
      setChosenSubscriber(subscriber);
    },
    enabled: Boolean(match.params.id)
  });

  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();
  const { user: loggedInUser } = useAuth();

  const handleFilterChange = state => {
    pagination.resetPage();
    setFilters(state);
  };

  const resetFilters = () => {
    handleFilterChange({ ...defaultFilters });
  };

  const handleSubscriberSelect = id => {
    setChosenSubscriber(
      query.data.data.find(subscriber => subscriber.id === id)
    );
  };

  const handleSubscriberDelete = index => {
    showModal({
      header: 'Delete Subscriber',
      body:
        'Are you sure you want to delete the subscriber? This will remove all data associated with them, including all of their messages.',
      handleConfirm: async () => {
        try {
          // Delete from DB.
          await deleteSubscriber(query.data.data[index].id);
          await queryClient.invalidateQueries(
            filters.type === DateFilter.TYPES.REGULAR
              ? 'subscribers'
              : 'subscribers/demo'
          );

          showSuccess('Successfully deleted subscriber');
        } finally {
          hideModal();
          setChosenSubscriber();
        }
      }
    });
  };

  const handleExportData = key => {
    showModal({
      header: 'Export Subscriber Data',
      body: `Are you sure you want to export this subscribers data? You will receive an email at "${loggedInUser.email}" with a link to download all data stored on them.`,
      handleConfirm: async () => {
        try {
          await exportSubscriber(query.data.data[key].id);
          showSuccess(
            'You will receive an email shortly with details on accessing the subscribers data'
          );
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <PageWrapper
      header="Conversations"
      subheader="View your chatbot's conversations"
      actions={
        <ActionMenu
          position={ActionMenu.POSITIONS.LEFT}
          buttonProps={{
            'aria-label': 'Toggle filter menu',
            variant: 'secondary',
            icon: 'filter',
            children: 'Filter',
            loading: query.isLoading
          }}>
          <DateFilter
            filters={filters}
            handleFilterChange={handleFilterChange}
            handleReset={resetFilters}
          />
        </ActionMenu>
      }>
      <Flex col ySpace>
        <Panel>
          {query.showNoResultsMessage && <NoResults />}
          {query.showTable && (
            <>
              <Table loading={query.isLoading} loadingColumns={5}>
                <TableHead>
                  <OrderableTableRow
                    rows={[
                      { label: 'Last message sent at', sort: 'last_active_at' },
                      { label: 'Conversation Started on', sort: 'created_at' },
                      { label: 'Channel', sort: null },
                      { label: 'Messages sent', sort: 'messages_count' },
                      null
                    ]}
                    {...order.props}
                  />
                </TableHead>

                {query.isSuccess && (
                  <TableBody>
                    {query.data.data.map(
                      (
                        {
                          id,
                          created_at,
                          last_active_at,
                          message_count,
                          channel
                        },
                        key
                      ) => (
                        <TableRow key={key} active={key === chosenSubscriber}>
                          <TableCell>
                            {last_active_at
                              ? `${new Date(
                                  last_active_at
                                ).toDateString()} (${dayjs(last_active_at).from(
                                  dayjs()
                                )})`
                              : 'N/A'}
                          </TableCell>
                          <TableCell>
                            {new Date(created_at).toDateString()}
                          </TableCell>
                          <TableCell>
                            {channel?.driver?.name || 'N/A'}
                          </TableCell>
                          <TableCell>{message_count}</TableCell>
                          <TableActions>
                            <PermissionFilter can="view messages">
                              <Button
                                variant="secondary"
                                onClick={() => handleSubscriberSelect(id)}
                                icon="eye">
                                View
                              </Button>
                            </PermissionFilter>
                            <PermissionFilter can="export subscribers">
                              <Button
                                variant="secondary"
                                onClick={() => handleExportData(key)}
                                icon="download">
                                Export
                              </Button>
                            </PermissionFilter>
                            <PermissionFilter can="delete subscribers">
                              <Button
                                colour="danger"
                                variant="secondary"
                                icon="trash"
                                onClick={() => handleSubscriberDelete(key)}>
                                Delete
                              </Button>
                            </PermissionFilter>
                          </TableActions>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                )}
              </Table>
              {query.showPagination && (
                <Pagination
                  pagination={query.data.meta}
                  {...pagination.props}
                />
              )}
            </>
          )}
        </Panel>
        <AnimatePresence>
          {chosenSubscriber && (
            <PanelSlider
              header={`View Conversation`}
              handleHide={() => setChosenSubscriber(null)}
              width="60vw">
              <Flex>
                <LeftHalfLayout>
                  <SubscriberDetails subscriber={chosenSubscriber} />
                </LeftHalfLayout>
                <RightHalfLayout>
                  <Messages subscriber={chosenSubscriber} />
                </RightHalfLayout>
              </Flex>
            </PanelSlider>
          )}
        </AnimatePresence>
      </Flex>
    </PageWrapper>
  );
};

export default Subscribers;
