import React from 'react';
import PropTypes from 'prop-types';

import { ComposerContext } from '../Contexts/index';
import { useComposerReducer } from '../reducers/index';

const ComposerProvider = ({
  initalResponses,
  subjects,
  handleSubmit,
  children
}) => {
  const context = useComposerReducer({
    responses: initalResponses,
    subjects,
    multi: false
  });

  return (
    <ComposerContext.Provider value={{ ...context, handleSubmit }}>
      {children}
    </ComposerContext.Provider>
  );
};

ComposerProvider.propTypes = {
  subjects: PropTypes.arrayOf(
    PropTypes.shape({
      subject: PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string
      }),
      name: PropTypes.string,
      Composer: PropTypes.elementType.isRequired,
      toState: PropTypes.func.isRequired,
      toApi: PropTypes.func.isRequired
    })
  ),
  initalResponses: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([
        'standard',
        'quick-reply',
        'action',
        'general',
        'carousel',
        'image'
      ]),
      composer: PropTypes.shape({
        type: PropTypes.string,
        content: PropTypes.object
      }),
      content: PropTypes.object,
      resolvers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          endpoint_id: PropTypes.number
        })
      )
    })
  ),
  handleSubmit: PropTypes.func
};

export default ComposerProvider;
