import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select, formatSelectOption } from '@ubisend/pulse-components';

const FaqSelect = ({
  value,
  selectedGroups = [],
  selectedLocations = [],
  exclude = false,
  ...props
}) => {
  const { data, isLoading, isSuccess } = useQuery([
    'faqs/all',
    { groupIds: selectedGroups, locations: selectedLocations }
  ]);

  return (
    <Select
      aria-label="faq-names"
      closeMenuOnSelect
      isClearable
      isLoading={isLoading}
      options={
        isSuccess
          ? data.data
              .filter(item => item.id !== exclude)
              .map(({ id, name }) => ({
                value: id,
                label: name
              }))
          : []
      }
      value={
        isSuccess &&
        value &&
        formatSelectOption(
          data.data.find(faq => {
            if (Number.isInteger(value)) {
              return faq.id === value;
            }
            return faq.id.toString() === value;
          })
        )
      }
      {...props}
    />
  );
};

FaqSelect.propTypes = {
  value: PropTypes.number,
  exclude: PropTypes.number,
  selectedGroups: PropTypes.arrayOf(PropTypes.number.isRequired),
  selectedLocations: PropTypes.any
};

export default FaqSelect;
