import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';
import { pseudoStyles } from '@ubisend/pulse-components';

import {
  useComposer,
  useQuery,
  useBelowScreenSize
} from '../../../hooks/index';
import {
  MessageComposer,
  BannerComposer,
  LoadingComposer,
  FeedbackComposer,
  DateComposer,
  DateTimeComposer,
  TimeComposer,
  AuthComposer,
  DeactivatedComposer,
  HiddenComposer,
  FuzzyComposer,
  NumericComposer
} from './Composers/index';

const composers = {
  message: MessageComposer,
  banner: BannerComposer,
  feedback: FeedbackComposer,
  date: DateComposer,
  'date-time': DateTimeComposer,
  time: TimeComposer,
  loading: LoadingComposer,
  hidden: HiddenComposer,
  deactivated: DeactivatedComposer,
  auth: AuthComposer,
  fuzzy: FuzzyComposer,
  numeric: NumericComposer,
  'live-chat': MessageComposer
};

const Container = styled(motion.div)`
  ${tw`md:mt-16 z-10`}
  & input {
    ${tw`rounded`}
    ${pseudoStyles}
  }
  & button {
    ${pseudoStyles}
  }
`;

const ComposerPortal = ({ children }) => {
  if (process.env.NODE_ENV === 'test') {
    return children;
  }

  const container = document.getElementById('composer-container');

  // No container element exists, create it ready for the next render
  if (!container) {
    const newContainer = document.createElement('div');
    newContainer.setAttribute('id', 'composer-container');
    newContainer.setAttribute('style', 'position: relative; z-index: 10;');
    document.body.append(newContainer);
    return children;
  }

  return ReactDOM.createPortal(children, container);
};

const DynamicComposer = ({ id }) => {
  const query = useQuery(`composers/${id}/dynamic`, {
    cacheTime: 0
  });

  if (!query.isSuccess) {
    return null;
  }

  const { type, ...composerProps } = query.data;
  const ComposerComponent = composers[type];

  return <ComposerComponent {...composerProps} />;
};

DynamicComposer.propTypes = {
  id: PropTypes.number.isRequired
};

const allComposers = {
  ...composers,
  dynamic: DynamicComposer
};

const Composer = ({ composer: passedComposer, ...props }) => {
  const { setReply } = useComposer();
  const mobile = useBelowScreenSize();

  const { type, ...composerProps } = passedComposer || {
    type: 'message',
    content: null
  };
  const ComposerComponent = allComposers[type];

  // Clear the input on every Component change.
  useEffect(() => {
    setReply('');
  }, [ComposerComponent, setReply]);

  const Wrapper = mobile ? ComposerPortal : Container;

  return (
    <Wrapper
      data-testid="composer"
      layout="position"
      initial={{ opacity: 0, y: 50 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: { delay: 0, ease: 'circOut' }
      }}
      exit={{ opacity: 0, y: 0, transition: { duration: 0.2 } }}>
      <ComposerComponent {...props} {...composerProps} />
    </Wrapper>
  );
};

Composer.propTypes = {
  composer: PropTypes.shape({
    type: PropTypes.oneOf(Object.keys(composers)),
    content: PropTypes.object
  })
};

export default Composer;
export { composers };
