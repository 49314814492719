import date from './Date/index';
import escape from './Escape/index';
import explodeToJsonArray from './ExplodeToJsonArray/index';
import extract from './Extract/index';
import numberFormat from './NumberFormat/index';
import queryParameter from './QueryParameter/index';
import regex from './Regex/index';

const defaultFilters = [
  date,
  escape,
  explodeToJsonArray,
  extract,
  numberFormat,
  queryParameter,
  regex
];

const filters = {
  date,
  escape,
  explodeToJsonArray,
  extract,
  numberFormat,
  queryParameter,
  regex,
  default: defaultFilters
};

export default filters;
