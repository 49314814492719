import React from 'react';
import PropTypes from 'prop-types';

import {
  Divider,
  DateTimePicker,
  Button,
  Label,
  Flex,
  Select
} from '@ubisend/pulse-components';

const TYPES = {
  REGULAR: 'Regular',
  DEMO: 'Demo'
};

const DateFilter = ({ filters, handleFilterChange, handleReset }) => {
  const handleTypeChange = option => {
    handleFilterChange(filters => ({
      ...filters,
      type: option.value
    }));
  };

  const handleNewStart = ([newStart]) => {
    handleFilterChange(filters => ({
      ...filters,
      start: newStart
    }));
  };

  const handleNewEnd = ([newEnd]) => {
    handleFilterChange(filters => ({
      ...filters,
      end: newEnd
    }));
  };

  return (
    <Flex col style={{ width: '20rem' }}>
      <Flex col pl pr pt>
        <Flex col fat mb>
          <Label htmlFor="type">User type</Label>
          <Select
            id="type"
            value={{ label: filters.type, value: filters.type }}
            options={Object.values(TYPES).map(key => {
              return { label: key, value: key };
            })}
            onChange={handleTypeChange}
          />
        </Flex>
        <Flex col fat mb>
          <Label htmlFor="start">Start</Label>
          <DateTimePicker
            id="start"
            date={filters.start}
            onChange={handleNewStart}
          />
        </Flex>
        <Flex col fat>
          <Label htmlFor="end">End</Label>
          <DateTimePicker id="end" date={filters.end} onChange={handleNewEnd} />
        </Flex>
      </Flex>
      <Divider />
      <Flex xSpace right pr pb pl>
        <Button
          variant="secondary"
          colour="danger"
          icon="x"
          onClick={handleReset}>
          Reset
        </Button>
      </Flex>
    </Flex>
  );
};

DateFilter.propTypes = {
  filters: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(TYPES)),
    start: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    end: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ])
  }).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired
};
DateFilter.TYPES = TYPES;

export default DateFilter;
