import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Flex, Button, ToggleButton, Span } from '@ubisend/pulse-components';
import {
  Condition,
  useConditional,
  useConditionalTypes,
  ConditionalSelect,
  SubjectSelect,
  CaseSensitiveToggle
} from '@ubisend/pulse-conditionals';

import SectionSelect from '../../../SectionSelect';
import ImportSelect from '../../../ImportSelect';

const SectionConditional = ({
  index,
  labelPrefix,
  type,
  condition,
  subject,
  case_sensitive,
  flipped
}) => {
  const types = useConditionalTypes();
  const {
    dispatch,
    conditionals,
    match,
    handleConditionalChange,
    handleConditionChange,
    handleSubjectChange,
    handleConditionalDelete,
    handleCaseToggle,
    handleFlippedToggle,
    getSubjectValue
  } = useConditional();

  const label = (string, index) => `${labelPrefix}-${string}-${index}`;

  const conditionalType = useMemo(() => {
    const conditional = types.find(({ name }) => name === type);

    if (!conditional) {
      return null;
    }

    return conditional.type;
  }, [types, type]);

  const handleImportChange = option => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: {
        ...subject,
        import_id: option ? option.value : null,
        section_id: null
      },
      index
    });
  };

  const handleSectionChange = ({ value }) => {
    dispatch({
      type: 'CHANGE_SUBJECT',
      newSubject: { ...subject, section_id: value },
      index
    });
  };

  return (
    <Flex xSpace center fat>
      <Span uppercase style={{ minWidth: '4rem', textAlign: 'right' }}>
        {index === 0 && 'if'}
        {index !== 0 && match === 'any' && 'Or'}
        {index !== 0 && match === 'all' && 'And'}
      </Span>
      <ToggleButton
        on={flipped}
        onChange={handleFlippedToggle(index)}
        aria-label={label('flipped', index)}>
        not
      </ToggleButton>
      <Flex col fat ySpace border pad>
        <Flex xSpace center fat>
          <SubjectSelect
            aria-label={label('subject', index)}
            value={getSubjectValue({ conditionalType, type, subject })}
            onChange={handleSubjectChange(index)}
          />
          <span>from</span>
          <ImportSelect
            aria-label={label('import', index)}
            value={subject.import_id}
            onChange={handleImportChange}
          />
          <span>at</span>
          <SectionSelect
            aria-label={label('section', index)}
            import={subject.import_id}
            value={subject.section_id}
            onChange={handleSectionChange}
          />
        </Flex>
        {subject.section_id && (
          <Flex xSpace center>
            <Flex fat xSpace growChildren center>
              <CaseSensitiveToggle
                aria-label={label('case-sensitive', index)}
                index={index}
                value={case_sensitive}
                onChange={handleCaseToggle(index)}
              />
              <ConditionalSelect
                aria-label={label('conditional', index)}
                value={type}
                onChange={handleConditionalChange(index)}
              />
              <Condition
                aria-label={label('condition', index)}
                name={conditionalType}
                value={condition}
                onChange={handleConditionChange(index)}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex grow right>
        <Button
          variant="secondary"
          colour="danger"
          icon="trash"
          disabled={conditionals.length <= 1}
          onClick={handleConditionalDelete(index)}>
          Delete
        </Button>
      </Flex>
    </Flex>
  );
};

SectionConditional.propTypes = {
  index: PropTypes.number.isRequired,
  labelPrefix: PropTypes.string.isRequired,
  subject: PropTypes.shape({
    section_id: PropTypes.number,
    import_id: PropTypes.number
  }).isRequired,
  type: PropTypes.string,
  condition: PropTypes.any,
  target: PropTypes.string,
  case_sensitive: PropTypes.bool,
  flipped: PropTypes.bool
};

export default SectionConditional;
