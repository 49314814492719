import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';

import { AnimatePresence, motion } from '@ubisend/framer-motion';
import {
  InfiniteScroll,
  Flex,
  Span,
  Heading3,
  UserIcon,
  Timeline,
  Profile,
  Label
} from '@ubisend/pulse-components';

import { getTrainingHistory } from '../api/index';

const TrainContainer = styled(motion.div)`
  ${tw`flex`}
  transform-origin: left center !important;
`;

const getInitals = user => {
  return `${user.first_name[0]}${user.last_name[0]}`;
};

const TrainingHistory = () => {
  const [trains, setTrains] = useState();
  const [pagination, setPagination] = useState();

  const fetchTrains = async () => {
    const {
      data: { data, meta }
    } = await getTrainingHistory(1);
    setTrains(data);
    setPagination(meta);
  };

  const handleBottomReached = useCallback(async () => {
    // If there are no more results to load, bail.
    if (pagination.current_page === pagination.last_page) {
      return;
    }

    const {
      data: { data, meta }
    } = await getTrainingHistory(pagination.current_page + 1);
    setTrains(trains => trains.concat(data));
    setPagination(meta);
  }, [pagination]);

  useEffect(() => {
    fetchTrains();
  }, []);

  return (
    <InfiniteScroll targetParent handleBottomReached={handleBottomReached}>
      {trains && pagination && (
        <Flex col>
          <Flex mb>
            <Heading3>Training History</Heading3>
          </Flex>
          {trains.length === 0 ||
          (trains.length === 1 && trains[0].is_training) ? (
            <Span light tinyText>
              Check back later to see training history
            </Span>
          ) : (
            <AnimatePresence>
              {trains
                .filter(({ finished_training_at }) => finished_training_at)
                .map((train, key) => (
                  <TrainContainer
                    layout
                    key={train.id}
                    initial={{ opacity: 0, scale: 0.75 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.75 }}
                    transition={{ ease: [0.16, 1, 0.3, 1], duration: 0.6 }}>
                    <Flex mrSm col center>
                      <UserIcon>
                        {train.trained_by ? getInitals(train.trained_by) : 'AT'}
                      </UserIcon>
                      {key !== trains.length - 1 && <Timeline />}
                    </Flex>
                    <Flex col>
                      <Profile col middle>
                        <Label mb={false}>
                          {train.trained_by
                            ? train.trained_by.name
                            : 'Automatically trained'}
                        </Label>
                        <Label mb={false} secondary>
                          {dayjs(train.finished_training_at).from(dayjs())}
                        </Label>
                      </Profile>
                      <Flex mb={key !== trains.length - 1}></Flex>
                    </Flex>
                  </TrainContainer>
                ))}
            </AnimatePresence>
          )}
        </Flex>
      )}
    </InfiniteScroll>
  );
};

export default TrainingHistory;
