import axios from 'axios';

const deleteSubscriber = subscriberId => {
  return axios.delete(`subscribers/${subscriberId}`);
};

const exportSubscriber = subscriberId => {
  return axios.get(`subscribers/${subscriberId}/export`);
};

export { deleteSubscriber, exportSubscriber };
