import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import Icon from '@ubisend/pulse-icons';
import {
  Flex,
  InnerPanel,
  Label,
  TextInput,
  Heading3,
  Button,
  Indicator,
  Divider,
  NumberFormatter
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';

const TicketStatus = ({ status }) => {
  const statuses = {
    submitted: 'danger',
    in_progress: 'warning',
    resolved: 'positive'
  };
  return <Indicator colour={statuses[status]} />;
};

const Container = styled(Flex)`
  ${tw`p-4`}
  height: calc(100% - 4rem);
  margin: -1rem;
  overflow-y: auto;
`;

const SubscriberDetails = ({ subscriber, ticket }) => (
  <Container col ySpace fat>
    <Flex mb center>
      <Heading3>Stored Information</Heading3>
    </Flex>
    <InnerPanel>
      <Flex col ySpace>
        <Flex col>
          <Label htmlFor="id">id</Label>
          <TextInput id="id" disabled readOnly value={subscriber.id} />
        </Flex>
        {subscriber.channel && (
          <Flex col>
            <Label htmlFor="channel_driver">Channel</Label>
            <TextInput
              id="channel_driver"
              disabled
              readOnly
              value={subscriber.channel.driver.name}
            />
          </Flex>
        )}

        {subscriber.created_at && (
          <Flex col>
            <Label htmlFor="created_at">created at</Label>
            <TextInput
              id="created_at"
              disabled
              readOnly
              value={dayjs(subscriber.created_at).format('HH:mm DD/MM/YYYY')}
            />
          </Flex>
        )}
        {subscriber.last_active_at && (
          <Flex col>
            <Label htmlFor="last_active_at">last active at</Label>
            <TextInput
              id="last_active_at"
              disabled
              readOnly
              value={dayjs(subscriber.last_active_at).format(
                'HH:mm DD/MM/YYYY'
              )}
            />
          </Flex>
        )}
      </Flex>
    </InnerPanel>
    {Object.entries(subscriber.variables).length > 0 && (
      <>
        <Flex mb>
          <Heading3>Variables</Heading3>
        </Flex>
        <InnerPanel>
          <Flex col ySpace>
            {Object.entries(subscriber.variables).map(([key, value]) => (
              <Flex col key={key}>
                <Label htmlFor={key}>{key.split('_').join(' ')}</Label>
                <TextInput id={key} disabled readOnly value={value} />
              </Flex>
            ))}
          </Flex>
        </InnerPanel>
      </>
    )}
    {subscriber.tickets.length > 0 && (
      <>
        <Flex mb>
          <Heading3>Tickets</Heading3>
        </Flex>
        <InnerPanel>
          {subscriber.tickets.map(({ id, name, status }, i) => (
            <React.Fragment key={id}>
              {i !== 0 && <Divider shiftLeft shiftRight pr pl />}
              <Flex between>
                <Flex xSpaceSm center>
                  <TicketStatus status={status} />
                  <span>{name}</span>
                </Flex>
                <PermissionFilter can="view tickets">
                  <Button
                    as={Link}
                    variant="secondary"
                    to={id === ticket?.id ? '#' : `/tickets/${id}`}
                    disabled={id === ticket?.id}
                    icon="eye">
                    {id === ticket?.id ? (
                      <span>Current ticket</span>
                    ) : (
                      <span>View ticket</span>
                    )}
                  </Button>
                </PermissionFilter>
              </Flex>
            </React.Fragment>
          ))}
        </InnerPanel>
      </>
    )}
    {subscriber.files_count > 0 && (
      <>
        <Flex mb>
          <Heading3>Files</Heading3>
        </Flex>
        <InnerPanel>
          <Flex between>
            <Flex center xSpaceSm>
              <Icon
                size="1.5rem"
                height="1.5rem"
                width="1.5rem"
                outline
                type="document"
              />
              <Label mb={false}>
                <NumberFormatter>{subscriber?.files_count}</NumberFormatter>{' '}
                File
                {subscriber?.files_count !== 1 && 's'}
              </Label>
            </Flex>
            <PermissionFilter can="view messages">
              <Button
                as={Link}
                variant="secondary"
                to={`/files?subscriber_id=${subscriber.id}`}
                icon="eye">
                View
              </Button>
            </PermissionFilter>
          </Flex>
        </InnerPanel>
      </>
    )}
    <Flex mb>
      <Heading3>Conversation</Heading3>
    </Flex>
    <InnerPanel>
      <Flex between>
        <Flex center xSpaceSm>
          <Icon
            size="1.5rem"
            height="1.5rem"
            width="1.5rem"
            outline
            type="chatAlt"
          />
          <Label mb={false}>
            <NumberFormatter>{subscriber.message_count}</NumberFormatter>{' '}
            Message
            {subscriber.message_count !== 1 && 's'}
          </Label>
        </Flex>
        <PermissionFilter can="view messages">
          <Button
            as={Link}
            variant="secondary"
            to={`/conversations/${subscriber.id}`}
            icon="eye">
            View
          </Button>
        </PermissionFilter>
      </Flex>
    </InnerPanel>
  </Container>
);

SubscriberDetails.propTypes = {
  subscriber: PropTypes.object,
  ticket: PropTypes.object
};

TicketStatus.propTypes = {
  status: PropTypes.string
};

export default SubscriberDetails;
