import axios from 'axios';

const getLatestTrain = () => axios.get('trains/latest');

const getTrainingHistory = page =>
  axios.get('trains', {
    params: { page }
  });

const train = () => axios.post('trains');

export { getLatestTrain, train, getTrainingHistory };
