import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Divider,
  DateTimePicker,
  Button,
  Label
} from '@ubisend/pulse-components';
import { LocationsConsumer } from '@ubisend/pulse-hooks';
import { LocationSelect } from '@ubisend/pulse-faqs';
import { PermissionFilter } from '@ubisend/pulse-auth';

import LanguageSelect from './LanguageSelect';
import ThresholdSelect from './ThresholdSelect';

const Filter = ({ filters, handleFilterChange, handleReset }) => {
  const handleNewStart = ([newStart]) => {
    handleFilterChange(() => ({ start: newStart }));
  };

  const handleNewEnd = ([newEnd]) => {
    handleFilterChange(() => ({ end: newEnd }));
  };

  const handleThresholdChange = ({ value }) => {
    handleFilterChange(() => ({ threshold: value }));
  };

  const handleLocationSelect = items => {
    handleFilterChange(() => ({
      locations: items ? items.map(item => item.value) : []
    }));
  };

  const handleLanguageSelect = ({ value }) => {
    handleFilterChange(() => ({ language_id: value }));
  };

  return (
    <Flex col fat style={{ width: '20rem' }}>
      <Flex pl pr pt col ySpace>
        <Flex col fat>
          <Label htmlFor="start">Start</Label>
          <DateTimePicker
            id="start"
            date={filters.start}
            onChange={handleNewStart}
          />
        </Flex>
        <Flex col fat>
          <Label htmlFor="end">End</Label>
          <DateTimePicker id="end" date={filters.end} onChange={handleNewEnd} />
        </Flex>
        <Flex col fat>
          <Label htmlFor="confidence">Chatbot confidence</Label>
          <ThresholdSelect
            id="confidence"
            onChange={handleThresholdChange}
            value={filters.threshold}
          />
        </Flex>
        <Flex col fat>
          <Label htmlFor="language">Language</Label>
          <LanguageSelect
            id="language"
            value={filters.language_id}
            onChange={handleLanguageSelect}
          />
        </Flex>
        <PermissionFilter can="view locations">
          <LocationsConsumer>
            <Flex col fat>
              <Label htmlFor="locations">Location</Label>
              <LocationSelect
                id="locations"
                value={filters.locations}
                onChange={handleLocationSelect}
                isMulti
              />
            </Flex>
          </LocationsConsumer>
        </PermissionFilter>
      </Flex>
      <Divider />
      <Flex right pl pr pb>
        <Button
          variant="secondary"
          colour="danger"
          icon="x"
          onClick={handleReset}>
          Reset
        </Button>
      </Flex>
    </Flex>
  );
};

Filter.propTypes = {
  filters: PropTypes.shape({
    start: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    end: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.instanceOf(Date)
    ]),
    threshold: PropTypes.string,
    type: PropTypes.string,
    locations: PropTypes.array.isRequired,
    language_id: PropTypes.number.isRequired
  }).isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired
};

export default Filter;
