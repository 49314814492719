import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Span } from '@ubisend/pulse-components';

import ActionButton from './ActionButton/index';
import Bubble from './Bubble';
import { ImageContainer } from './Image';

const General = ({
  content,
  handleButtonClick,
  direction,
  dataTestid = 'general-image',
  ...props
}) => (
  <Bubble
    direction={direction}
    fat
    style={{
      padding: '0',
      width: '75%',
      flexShrink: '0'
    }}
    {...props}>
    <Flex col tall>
      <a
        href={content.url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={content.title}>
        <ImageContainer
          data-testid={dataTestid}
          style={{
            backgroundImage: `url('${content.image}')`
          }}
          aria-label={content.image_description}
        />
      </a>
      <Flex col ySpaceSm padSm grow>
        <Span bold>{content.title}</Span>
        <Span>{content.subtitle}</Span>
      </Flex>
      <Flex col ySpaceSm padSm>
        {content.responses.map((response, key) => (
          <ActionButton
            key={key}
            direction={direction}
            response={response}
            handleButtonClick={handleButtonClick}
          />
        ))}
      </Flex>
    </Flex>
  </Bubble>
);

General.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    image: PropTypes.string.isRequired,
    image_description: PropTypes.string,
    responses: PropTypes.array.isRequired,
    url: PropTypes.string
  }).isRequired,
  direction: Bubble.propTypes.direction,
  handleButtonClick: PropTypes.func.isRequired,
  dataTestid: PropTypes.string
};

export default General;
