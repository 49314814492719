import React from 'react';

import { ComposerContext } from '../Contexts/index';
import { useComposerReducer } from '../reducers/index';
import ComposerProvider from './ComposerProvider';

const MultiComposerProvider = ({
  initalResponses,
  subjects,
  handleSubmit,
  children
}) => {
  const context = useComposerReducer({
    responses: initalResponses,
    subjects,
    multi: true
  });

  return (
    <ComposerContext.Provider value={{ ...context, handleSubmit }}>
      {children}
    </ComposerContext.Provider>
  );
};

MultiComposerProvider.propTypes = ComposerProvider.propTypes;

export default MultiComposerProvider;
