const dateTime = {
  id: 'date-time',
  name: 'Date time',
  Input: null,
  content: {},
  valid: () => true,
  snippet: `{
    "type": "date-time",
    "content": {} 
}`
};

export default dateTime;
