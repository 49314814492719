import React from 'react';
import PropTypes from 'prop-types';

import { Flex, Tooltip, Select } from '@ubisend/pulse-components';
import { TemplatingTextInput } from '@ubisend/pulse-templating';

import { useButtons } from '../../../hooks/index';

const types = [
  {
    label: 'Mailto',
    value: 'mailto'
  },
  {
    label: 'Callto',
    value: 'callto'
  }
];

const placeholders = {
  callto: {
    action: '01522 568209',
    label: 'Call us now',
    linkback: 'The user clicked the call button'
  },
  mailto: {
    action: 'name@example.com,second@example.com',
    label: 'Send Email',
    linkback: 'The email was sent'
  }
};

const ProtocolButton = ({
  label,
  action,
  linkback,
  index,
  type = 'mailto'
}) => {
  const { handleButtonChange } = useButtons();

  const handleTypeChange = ({ value }) => {
    handleButtonChange(index, 'type', value);
  };

  return (
    <Flex col ySpace fat>
      <Select
        aria-label={`type-select`}
        value={types.find(({ value }) => type === value)}
        onChange={handleTypeChange}
        options={types}
      />
      <TemplatingTextInput
        aria-label={`${type}-label-${index + 1}`}
        value={label}
        placeholder={placeholders[type].label}
        onChange={value => {
          handleButtonChange(index, 'label', value);
        }}
      />
      <Flex col>
        <TemplatingTextInput
          aria-label={`${type}-action-${index + 1}`}
          value={action}
          placeholder={placeholders[type].action}
          onChange={value => {
            handleButtonChange(index, 'action', value);
          }}
        />
      </Flex>
      <Flex center>
        <TemplatingTextInput
          aria-label={`${type}-linkback-${index + 1}`}
          value={linkback}
          placeholder={placeholders[type].linkback}
          onChange={value => {
            handleButtonChange(index, 'linkback', value);
          }}
        />
        <Tooltip
          position={Tooltip.POSITIONS.TOP}
          tooltip={
            <Tooltip.Content>
              This optional postback text can be used to trigger a new step when
              the user clicks the button to perform an action.
            </Tooltip.Content>
          }>
          <Tooltip.Icon>?</Tooltip.Icon>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

ProtocolButton.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  linkback: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string
};

export default ProtocolButton;
export { placeholders };
