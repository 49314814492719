import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const format = ({ id, name }) => ({ label: name, value: id });

const SheetSelect = ({ value, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('spreadsheets');

  return (
    <Select
      isLoading={isLoading}
      isClearable
      options={
        isSuccess
          ? data.data.filter(({ lookups }) => lookups.length > 0).map(format)
          : []
      }
      value={
        isSuccess &&
        value &&
        format(data.data.find(sheet => sheet.id === value))
      }
      {...props}
    />
  );
};

SheetSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default SheetSelect;
