import { useState } from 'react';

import {
  usePaginationReducer,
  useOrderableTableReducer
} from '@ubisend/pulse-components';

const defaultFilters = {
  assignee_ids: [],
  ticket_types: [],
  statuses: []
};

const useTicketFilters = (initalFilters = defaultFilters) => {
  const [filters, setFilters] = useState(initalFilters);

  const pagination = usePaginationReducer();
  const order = useOrderableTableReducer();

  const updateFilters = newFilters => {
    pagination.resetPage();
    setFilters({ ...filters, ...newFilters });
  };

  const handleStatusesChange = options => {
    updateFilters({
      statuses: options ? options.map(option => option.value) : []
    });
  };

  const handleTypesChange = options => {
    updateFilters({
      ticket_types: options ? options.map(option => option.value) : []
    });
  };

  const handleAssigneesChange = options => {
    updateFilters({
      assignee_ids: options ? options.map(option => option.value) : []
    });
  };

  return {
    filters,
    form: { ...filters, ...pagination.params, ...order.params },
    props: {
      filters,
      handleStatusesChange,
      handleTypesChange,
      handleAssigneesChange
    },
    pagination,
    order
  };
};

export default useTicketFilters;
